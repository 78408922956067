import { SonityLocalStore, contextsList } from "@/utils/sonityLocalStore";

const sonityLocalStorage = new SonityLocalStore()

let initialState = {
  name: "flow",
  layout: "TB",
  show_add_action: {
    id: "",
    show: false
  },
  show_add_condition: false,
  timeframe: {
    id: "", // edge id
    show_dialog: false,
    time: 0, // time e.g,
    unit: "hours", // for measurement

    // This is for possiting
    x: 0,
    y: 0,
    width: 0
  },
  edit_node_settings: {
    id: null,
    data: {}
  },
  nodes_add: {
    nodes: [],
    edges: [],
    replace_node_id: 0
  },
  node_update_data: {
    id: null,
    data: {}
  },
  edge_update_data: {
    id: null,
    data: {}
  },
  node_remove: {
    id: null
  }
};

let storedState =sonityLocalStorage.getContextState(contextsList.flow)

export default {
  ...initialState,
  ...storedState
}
