import { SonityLocalStore, contextsList } from "@/utils/sonityLocalStore";
import actionTypes from "./stepsTabActionTypes";

const sonityLocalStore = new SonityLocalStore();

const StepsTabReducer = (state, action) => {
  console.log("[StepsTabReducer]: ", {
    state,
    action
  });

  let newState = state;

  switch (action.type) {
    case actionTypes.set_state:
      newState = {
        ...state,
        ...action.payload
      };
      break
    case actionTypes.set_prev_steps:
      newState = {
        ...state,
        prevSteps: action.payload
      };
      break
    case actionTypes.update_step:
      let currentSteps = state.steps;
      let prevSteps = state.steps;

      let index = action.index;
      currentSteps[index] = action.payload;

      newState = {
        ...state,
        steps: currentSteps
      };
      break
    case actionTypes.set_commit_bar_status:
      //console.log("[StepsTabReducer] set_commit_bar_status: ", action.payload);
      newState = {
        ...state,
        showCommitBar: action.payload
      };
      break
    case actionTypes.change_commit_status:
      newState = {
        ...state,
        commitStatus: action.payload
      };
      break
    default:
     newState = state;
  }

  sonityLocalStore.storeContextState(contextsList.steps, newState)
  return newState;
};

export default StepsTabReducer;
