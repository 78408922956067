import React, { useEffect } from "react";
import AppRouter from "./AppRouter";

import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { lightTheme } from "./theme";

import {
  BrowserRouter as Router,
} from "react-router-dom";

import { ApolloProvider } from "@apollo/client";
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import { gqlClient } from "./attach-client";
import log from "loglevel";
import { MainLayoutProvider } from "./contexts/mainLayoutState/MainLayoutProvider";
import { AuthProvider } from "./contexts/AuthState/AuthContextProvider";
import TourProvider from "./contexts/TourState/TourProvider";
import {useHistory} from 'react-router-dom'

//polyfills
if (!window.atob) {
  var tableStr =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
  var table = tableStr.split("");

  window.atob = function(base64) {
    if (/(=[^=]+|={3,})$/.test(base64))
      throw new Error("String contains an invalid character");
    base64 = base64.replace(/=/g, "");
    var n = base64.length & 3;
    if (n === 1) throw new Error("String contains an invalid character");
    for (var i = 0, j = 0, len = base64.length / 4, bin = []; i < len; ++i) {
      var a = tableStr.indexOf(base64[j++] || "A"),
        b = tableStr.indexOf(base64[j++] || "A");
      var c = tableStr.indexOf(base64[j++] || "A"),
        d = tableStr.indexOf(base64[j++] || "A");
      if ((a | b | c | d) < 0)
        throw new Error("String contains an invalid character");
      bin[bin.length] = ((a << 2) | (b >> 4)) & 255;
      bin[bin.length] = ((b << 4) | (c >> 2)) & 255;
      bin[bin.length] = ((c << 6) | d) & 255;
    }
    return String.fromCharCode.apply(null, bin).substr(0, bin.length + n - 4);
  };

  window.btoa = function(bin) {
    for (var i = 0, j = 0, len = bin.length / 3, base64 = []; i < len; ++i) {
      var a = bin.charCodeAt(j++),
        b = bin.charCodeAt(j++),
        c = bin.charCodeAt(j++);
      if ((a | b | c) > 255)
        throw new Error("String contains an invalid character");
      base64[base64.length] =
        table[a >> 2] +
        table[((a << 4) & 63) | (b >> 4)] +
        (isNaN(b) ? "=" : table[((b << 2) & 63) | (c >> 6)]) +
        (isNaN(b + c) ? "=" : table[c & 63]);
    }
    return base64.join("");
  };
}

window.hexToBase64 = function(str) {
  return window.btoa(
    String.fromCharCode.apply(
      null,
      str
        .replace(/\r|\n/g, "")
        .replace(/([\da-fA-F]{2}) ?/g, "0x$1 ")
        .replace(/ +$/, "")
        .split(" ")
    )
  );
};

window.base64ToHex = function(str) {
  for (
    var i = 0, bin = window.atob(str.replace(/[ \r\n]+$/, "")), hex = [];
    i < bin.length;
    ++i
  ) {
    var tmp = bin.charCodeAt(i).toString(16);
    if (tmp.length === 1) tmp = "0" + tmp;
    hex[hex.length] = tmp;
  }
  return hex.join(" ");
};

// Add more informatice eror messages
if (process.env.NODE_ENV === "development") {  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

function App() {

  document.getElementById("root").style.height = "100%";

  //SET LOG LEVELS
  if (process.env.NODE_ENV === "production") {
    log.setLevel(log.levels.WARN);
  } else {
    log.setLevel(log.levels.INFO);
  }
  


  return (
    <Router basename="/portal">

      <AuthProvider>   
        <ApolloProvider client={gqlClient}>
        <MainLayoutProvider>

              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={lightTheme}>
                    <TourProvider>
                      <AppRouter />
                    </TourProvider>
                </ThemeProvider>
              </StyledEngineProvider>
              </MainLayoutProvider>

        </ApolloProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
