import gql from "graphql-tag";

export const CREATE_LIST = gql`
  mutation CreateList($lists_input: ListsInput! $sonity_account_id: ID!) {
    create_list(lists_input: $lists_input, sonity_account_id: $sonity_account_id) {
      id
      name
      type
      contacts {
        id
        l_id
        email
        phone
        prospect {
          id
          contact_l_id
          profile_name
          profile_location
          profile_description
        }
      }
    }
  }
`;

export const UPDATE_LIST = gql`
  mutation UpdateList($value: ListsInput! $sonity_account_id: ID!) {
    update_list(value: $value, sonity_account_id: $sonity_account_id) {
      id
      name
      type
      contacts {
        id
        l_id
        email
        phone
        prospect {
          id
          contact_l_id
          profile_name
          profile_location
          profile_description
        }
      }
    }
  }
`;

export const DELETE_LISTS = gql`
  mutation DeleteLists($values: [ID!]! $sonity_account_id: ID!) {
    delete_lists(values: $values, sonity_account_id: $sonity_account_id)
  }
`;

// List Items Mutations
export const CREATE_LIST_ITEM = gql`
  mutation CreateListItem($value: ListItemsInput! $sonity_account_id: ID!) {
    create_list_item(value: $value, sonity_account_id: $sonity_account_id) {
      id
      name
      type
      contacts {
        id
        l_id
        email
        phone
        company
        role
        websites
        contact_birthday
        prospect {
          id
          contact_l_id
          profile_name
          profile_location
          profile_description
          org_size
        }
      }
    }
  }
`;

export const CREATE_LIST_ITEMS = gql`
  mutation CreateListItems($values: [ListItemsInput!] $sonity_account_id: ID!) {
    create_list_items(values: $values, sonity_account_id: $sonity_account_id) {
      id
      name
      type
      contacts {
        id
        l_id
        email
        phone
        company
        role
        websites
        contact_birthday
        prospect {
          id
          contact_l_id
          profile_name
          profile_location
          profile_description
          org_size
        }
      }
    }
  }
`;

export const UPDATE_LIST_ITEM = gql`
  mutation UpdateListItem($value: ListItemsInput! $sonity_account_id: ID!) {
    update_list_item(value: $value, sonity_account_id: $sonity_account_id) {
      list_id
      contact_id
      list {
        id
        name
        type
      }
      contact {
        id
        l_id
        email
        phone
        prospect {
          id
          contact_l_id
          profile_name
          profile_location
          profile_description
        }
      }
    }
  }
`;

export const UPDATE_LIST_ITEMS = gql`
  mutation UpdateListItem($values: [ListItemsInput!] $sonity_account_id: ID!) {
    update_list_items(values: $values, sonity_account_id: $sonity_account_id) {
      list_id
      contact_id
      list {
        id
        name
        type
      }
      contact {
        id
        l_id
        email
        phone
        prospect {
          id
          contact_l_id
          profile_name
          profile_location
          profile_description
        }
      }
    }
  }
`;

export const DELETE_LIST_ITEMS = gql`
  mutation DeleteListItems($list_id: ID!, $values: [ID!]! $sonity_account_id: ID!) {
    delete_list_items(list_id: $list_id, values: $values, sonity_account_id: $sonity_account_id)
  }
`;

export const CREATE_LIST_ITEMS_FROM_CAMPAIGN = gql`
  mutation createListItemsFromCampaign(
    $list_id: ID!
    $campaign_definition_id: ID!
    $sonity_profile_id: ID!
    $sonity_account_id: ID!
  ) {
    create_list_items_from_campaign(
      list_id: $list_id,
      campaign_definition_id: $campaign_definition_id,
      sonity_account_id: $sonity_account_id
    ) {
      id
      name
      type
      contacts {
        id
        l_id
        email
        phone
        company
        role
        websites
        contact_birthday
        prospect {
          id
          contact_l_id
          profile_name
          profile_location
          profile_description
          org_size
        }
      }
    }
  }
`;

export const CREATE_LIST_ITEMS_FROM_SEARCH = gql`
  mutation createListItemsFromSearch(
    $list_id: ID!
    $values: [BulkSearchResult!]!
    $sonity_account_id: ID!
  ) {
    create_list_items_from_search(list_id: $list_id, values: $values, sonity_account_id: $sonity_account_id) {
      id
      name
      type
      contacts {
        id
        l_id
        email
        phone
        company
        role
        websites
        contact_birthday
        prospect {
          id
          contact_l_id
          profile_name
          profile_location
          profile_description
          org_size
        }
      }
    }
  }
`;
