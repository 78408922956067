import React from "react";
import clsx from "clsx";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import { amber, green } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import WarningIcon from "@mui/icons-material/Warning";
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from "prop-types";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const useStyles = makeStyles(theme => ({
  root: {
    flexWrap: "nowrap"
  },
  success: {
    backgroundColor: green[500]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center"
  },
  contentRoot: {
    flexWrap: "nowrap"
  }
}));

const CustomSnackbar = props => {
  const classes = useStyles();
  const {
    className,
    message,
    onClose,
    variant,
    open,
    autoHideDuration,
    showCloseIcon,
    ...other
  } = props;
  const Icon = variantIcon[variant || "info"];

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
    >
      <SnackbarContent
        className={clsx(classes[variant], className)}
        classes={{
          root: classes.contentRoot
        }}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={onClose}
            size="large">
            <CloseIcon className={classes.icon} />
          </IconButton>
        }
        {...other}
      />
    </Snackbar>
  );
};

CustomSnackbar.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  autoHideDuration: PropTypes.number,
  variant: PropTypes.oneOf(["error", "info", "success", "warning"])
};

CustomSnackbar.defaultProps = {
  variant: "info"
};

export default CustomSnackbar;
