import actionTypes from "./ActionTypes";

/**
 * @name tourStateFunctions
 * @desc Provides functions for manipulating tour state
 * @param {Object} state - tour state
 * @param {Function} dispatch - a function that triggers state change
 * @param {Object} extras  - An object with extra data or functions
 * @returns An object of functions
 */
const tourStateFunctions = (state, dispatch, extras) => {

  const {
    updateUser
  } = extras

  const setState = newState => {
    dispatch({
      type: actionTypes.set_state,
      payload: newState
    });
  };

  const setTourSteps = steps => {
    dispatch({
      type: actionTypes.set_steps,
      payload: steps
    });
  };


  const startTour = (tour_name) => {
    dispatch({
      type: actionTypes.set_run,
      payload: {
        run: true,
        tour_name: tour_name
      }
    });
  };

  const endTour = () => {
    dispatch({
      type: actionTypes.set_state,
      payload: {
        steps: [],
        run: false,
        tour_name: ""
      }
    });
  };

  const tourCompleted = (tour_name, user) => {
    if (user?.extensions){

      // console.log("User Extensions: ", user.extensions)
      let tours_completed = user.extensions.tours_completed 

      if (tours_completed){
        return tours_completed[tour_name] ? tours_completed[tour_name] : false;
      }

      return false
      
    }
  }

  const completeTour = (tour_name, user) =>{

    console.log("Tour Name: ", tour_name)
    if (user?.extensions){

      // Check type, make sure its an object
      let user_extensions = typeof user.extensions == "object" ? user.extensions :
                            typeof user.extensions == "string" ? JSON.parse(user.extensions) : {}

      let tours_completed = user_extensions.tours_completed 

      if (!tours_completed){
        tours_completed = {}
      }

      tours_completed[tour_name] = true

      updateUser({
        variables:{
          input: { 
            id: user.id,
            extensions: {
              ...user_extensions,
              tours_completed
            },
            sonity_account_id: user.sonity_account_id
          }
        }
      }).catch(err => console.log(err))
      
    }
  
  }


  return {
    setState,
    setTourSteps,
    startTour,
    endTour,
    tourCompleted,
    completeTour
  };
};

export default tourStateFunctions;
