import React, {
  useEffect,
  createContext,
  useReducer,
  useContext
} from "react";
import makeStyles from '@mui/styles/makeStyles';

import { useMainLayoutContext } from "../mainLayoutState/MainLayoutProvider";
import useLists from "@/hooks/useLists";
import { useTasks } from "@/hooks/useTasks";

import InitialState  from "./InitialState";
import { stateFunctions } from "../ListManagerState/ListManagerStateFunctions";
import { reducer } from "./Reducer";
import { useAuthContext } from "../AuthState/AuthContextProvider";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "1rem"
  }
}));

let ListManagerContext = createContext();


/**
 * 
 * @name ListManagerProvider
 * @type component
 * @desc Provides  access to lists state, its state functions and hooks. Used mainly by the List Manager State
 * @prop {Component} children - Children components
 * @returns Provicer component
 */

export const ListManagerProvider = ({ children }) => {
  const mainLayoutCtx = useMainLayoutContext();
  const authCtx = useAuthContext()
  const { sonityProfiles } = mainLayoutCtx.state;

  const user = authCtx.user;

  const [state, dispatch] = useReducer(reducer, {
    ...InitialState,
    user: user,
    sonityProfiles: sonityProfiles,
    selectedSonityProfiles: sonityProfiles && sonityProfiles.length > 0 ? [sonityProfiles[0]] : []
  });

  const [
    message,
    { updateMessage, hideMessage }
  ] = mainLayoutCtx.useMessageHook;

  const useListsHook = useLists(null, null, updateMessage);

  const [
    {},
    {
      getListsResults,
      createListItemsResults,
      createListsItemsFromSearchResults,
      createListsItemsFromCampaignResults
    }
  ] = useListsHook;

  let useTasksHook = useTasks(null, updateMessage);

  const [
    { getTasksResult, deleteTaskData },
    { getTasks, deleteTask }
  ] = useTasksHook;

  const listManagerStateFunctions = stateFunctions(state, dispatch, {
    mainLayoutCtx: mainLayoutCtx,
    useListsHook: useListsHook,
    useTasksHook: useTasksHook,
  });

  const {
    getAllLists,
    processAndDisplayLists,
    getListTasks,
    createListItemsFromCampaignRes,
    createListItemsFromFCs,
    createListItemsFromSearch
  } = listManagerStateFunctions;

  useEffect(() => {
    console.log(
      "Create List Items from Search: ",
      createListsItemsFromSearchResults
    );

    if (
      !createListsItemsFromSearchResults.loading &&
      createListsItemsFromSearchResults.data
    ) {
      createListItemsFromSearch(createListsItemsFromSearchResults);
    }
  }, [createListsItemsFromSearchResults]);


  const value = {
    state,
    dispatch,
    stateFunctions: listManagerStateFunctions,
    useListsHook: useListsHook,
    useTasksHook: [
      { getTasksResult, deleteTaskData },
      { getTasks, deleteTask }
    ],
    useMessageHook: [message, { updateMessage, hideMessage }]
  };

  useEffect(() => {
    getAllLists();
  }, []);

  useEffect(() => {
    if (!getListsResults.loading && getListsResults.data) {
      let listsData = getListsResults.data.lists;
      processAndDisplayLists(listsData);
    }
  }, [getListsResults]);

  useEffect(() => {
    if (!getTasksResult.loading && getTasksResult.data) {
      getListTasks(getTasksResult);
    }
  }, [getTasksResult]);

  return (
    <ListManagerContext.Provider value={value}>
      {children}
    </ListManagerContext.Provider>
  );
};

/**
 * @name useListManagerContext
 * @desc Returns to the List Manager Context which comprises of state, hooks and state functions
 * @type component
 * @returns A context
 */
export const useListManagerContext = () => {
  return useContext(ListManagerContext);
};
