import React, { useCallback, useEffect, useState } from "react";

import {
  CREATE_CAMPAIGN_DEFINITION,
  UPDATE_CAMPAIGN_DEFINITION,
  DELETE_CAMPAIGN_DEFINITION,
  UPDATE_CAMPAIGN_DEFINITION_RETURN_TASKS
} from "../gql/mutations/CampaignDefinitions";

import { CAMPAIGN_SEED_DATA, GET_CAMPAIGN_DEFINITIONS_WITH_STATS } from "../gql/queries/CampaignDefinitions";
import {
  GET_CAMPAIGN_DEFINITIONS,
  GET_CAMPAIGN_DEFINITIONS_LIST
} from "../gql/queries/CampaignDefinitions";

import {
  useMutation,
  useLazyQuery,
  useQuery
} from "@apollo/client";



/**
 * @name useCampaignDefinitions
 * @type component
 * @desc Is a hook that allows you tro perform queries and changes on a user's campaign definitions
 * @prop {Object} ssp - Selected sonity profile
 * @prop {Function} updateMessage  - updateMessage function for showing snackbar
 * @prop {*} extraArgs - extra arguments 
 * @prop {Object} mainLayoutState - An object of  MainLayout current state
 * @prop {Object} mainLayoutStateFunctions  - An object of Main Layout state functions
 * @returns An object with campaign data and functions
 */

export const useCampaignDefinitions = (
  ssp,
  updateMessage,
  mainLayoutState,
  mainLayoutStateFunctions
) => {

  const {
    selectedSonityProfile,
    selectedSonityAccount,
    campaignDefinitions
  } = mainLayoutState;

  const { setCampaignDefinitions, setSelectedSonityProfile } = mainLayoutStateFunctions;

  const {
    data: campaignSeedData
    //loading: campaignSeedDataLoading
  } = useQuery(CAMPAIGN_SEED_DATA);

  const [
    getCampaignDefinitionsList,
    getCampaignDefinitionsListResult
  ] = useLazyQuery(GET_CAMPAIGN_DEFINITIONS_LIST, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      console.log("setting fresh cdslist  -- DEPRECATE THIS:");
      setCampaignDefinitions(data.campaign_definitions_in_list);
    }
  });

  const [getCampaignDefinitions, campaignDefinitionsData] = useLazyQuery(
    GET_CAMPAIGN_DEFINITIONS,
    {
      fetchPolicy: "cache-first",
      onCompleted: data => {
        console.log("GotCds: ", data);

        let campaign_definitions = data.campaign_definitions;

        let hasTypeNull = campaign_definitions.find(cd => cd.type === null) ? true : false 
        if (hasTypeNull) return

        if (selectedSonityProfile?.id) {
          console.log("Setting fresh cds:");

          //tags selectedSonityProfile with the fresh vals (used by navbar genericcampaigndropdown)
          //set fresh cds for sp here
          setCampaignDefinitions(campaign_definitions);
          setSelectedSonityProfile({
            ...selectedSonityProfile,
            campaign_definitions: campaign_definitions
          })
        }
      },
      onError: err => {
        console.log(err);
      },
      variables: {
        sonity_profile_id: selectedSonityProfile
          ? selectedSonityProfile.id
          : null
      }
    }
  );

  const [getCampaignDefinitionsWithStats, getCampaignDefinitionsWithStatsResult] = useLazyQuery(
    GET_CAMPAIGN_DEFINITIONS_WITH_STATS,
    {
      fetchPolicy: "cache-first",
      onCompleted: data => {
        let campaign_definitions = data.campaign_definitions;
        let hasTypeNull = campaign_definitions.find(cd => cd.type === null) ? true : false 
        if (hasTypeNull) return

        if (selectedSonityProfile?.id) {
          //tags selectedSonityProfile with the fresh vals (used by navbar genericcampaigndropdown)
          //set fresh cds for sp here
          setCampaignDefinitions(campaign_definitions);
          // setSelectedSonityProfile({
          //   ...selectedSonityProfile,
          //   campaign_definitions: campaign_definitions
          // })
        }
      },
      onError: err => {
        console.log(err);
      },
      variables: {
        sonity_profile_id: selectedSonityProfile
          ? selectedSonityProfile.id
          : null
      }
    }
  );

  const [
    createCampaignDefinition,
    createCampaignDefinitionResult
  ] = useMutation(CREATE_CAMPAIGN_DEFINITION, {
    onCompleted: data => {
      console.log(data);
      //createCampaignDefinition(data.create_campaign_definition);
      setCampaignDefinitions([
        ...campaignDefinitions,
        data.create_campaign_definition
      ]);
    },
    refetchQueries: ["getCampaignDefinitions"]
  });

  const [
    updateCampaignDefinitionMutation,
    updateCampaignDefinitionResults
  ] = useMutation(UPDATE_CAMPAIGN_DEFINITION, {
    //refetchQueries: ["GetTasksTable"],  //db populate tasks takes time
    onCompleted: data => {
      const updated = data.update_campaign_definition;

      const i = campaignDefinitions.findIndex(cd => cd.id === updated.id);
      setCampaignDefinitions([
        ...campaignDefinitions.slice(0, i),
        { ...campaignDefinitions[i], ...updated },
        ...campaignDefinitions.slice(i + 1)
      ]);
    }
  });

  //overrides input with sonity_account_id
   //overrides input with sonity_account_id
   const updateCampaignDefinition = (opt) => {
    const { variables } = opt;
    const { input } = variables;
    // Ensure selectedSonityAccount.id is included
    
    const mergedInput = {
      ...input,
      sonity_account_id: selectedSonityAccount.id
    };
    return updateCampaignDefinitionMutation({ variables: {
      input: mergedInput
    }});
    
  };

  const [
    updateCampaignDefinitionReturnTasksMutation,
    updateCampaignDefinitionReturnTasksResults
  ] = useMutation(UPDATE_CAMPAIGN_DEFINITION_RETURN_TASKS, {
    //todo: can filter the taskstable locally instead of refetching
    //refetchQueries: ["GetTasksTable"],  //db populate tasks takes time
    onCompleted: data => {
      const updated = data.update_campaign_definition;
      const i = campaignDefinitions.findIndex(cd => cd.id === updated.id);
      setCampaignDefinitions([
        ...campaignDefinitions.slice(0, i),
        { ...campaignDefinitions[i], ...updated },
        ...campaignDefinitions.slice(i + 1)
      ]);
    }
  });

    //overrides input with sonity_account_id
    const updateCampaignDefinitionReturnTasks = (opt) => {
      const { variables } = opt;
      const { input } = variables;
      // Ensure selectedSonityAccount.id is included
      console.log("updateCd", input)
      const mergedInput = {
        ...input,
        sonity_account_id: selectedSonityAccount.id
      };
      return updateCampaignDefinitionReturnTasksMutation({ variables: {
        input: mergedInput
      }});
      
    };

  const [
    deleteCampaignDefinition,
    deleteCampaignDefinitionResults
  ] = useMutation(DELETE_CAMPAIGN_DEFINITION, {
    onError: err => {
      console.log(err);
      updateMessage({
        isShown: true,
        variant: "error",
        content:
          "An error was encountered when deleting the campaign definition."
      });
    },
    onCompleted: data => {
      console.log("data", data);
      updateMessage({
        isShown: true,
        variant: "success",
        content: "The campaign definition has been deleted."
      });
    },
    refetchQueries: ["getCampaignDefinitions", "GetTasksTable"]
  });

  React.useEffect(() => {
    if (selectedSonityProfile) {
      getCampaignDefinitions({
        variables: {
          sonity_profile_id: selectedSonityProfile.id
        }
      });
    }
  }, [selectedSonityProfile?.id]);

  return [
    {
      campaignSeedData,
      createCampaignDefinitionResult,
      updateCampaignDefinitionResults,
      updateCampaignDefinitionReturnTasksResults,
      deleteCampaignDefinitionResults,
      getCampaignDefinitionsListResult,
      getCampaignDefinitionsWithStatsResult,
      campaignDefinitions,
      campaignDefinitionsData
    },
    {
      getCampaignDefinitions,
      getCampaignDefinitionsWithStats,
      setCampaignDefinitions,
      createCampaignDefinition,
      getCampaignDefinitionsList,
      updateCampaignDefinition,
      updateCampaignDefinitionReturnTasks,
      deleteCampaignDefinition
    }
  ];
};
