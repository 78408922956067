import gql from "graphql-tag";

export const GET_LIST = gql`
  query GetList($id: ID!) {
    list(id: $id) {
      id
      name
      type
      user_id
      contacts {
        id
        l_id
        email
        phone
        company
        role
        websites
        contact_birthday
        prospect {
          id
          contact_l_id
          profile_company
          profile_name
          profile_location
          profile_experience
          profile_education
          profile_description
        }
        created_at
        updated_at
      }
    }
  }
`;

export const GET_LISTS = gql`
  query GetLists($user_id: ID!) {
    lists(user_id: $user_id) {
      id
      name
      type
      contacts {
        id
        l_id
        email
        phone
        company
        role
        websites
        contact_birthday
        prospect {
          id
          contact_l_id
          profile_company
          profile_name
          profile_location
          profile_experience
          profile_education
          profile_description
        }
        created_at
        updated_at
      }
    }
  }
`;

export const GET_LISTS_PAGINATED = gql`
  query GetListsPaginated($user_id: ID!, $limit: Int!, $offset: Int!) {
    lists_paginated(user_id: $user_id, limit: $limit, offset: $offset) {
      count
      lists {
        id
        name
        type
        size
        contacts {
          id
          l_id
          email
          phone
          company
          role
          websites
          contact_birthday
          prospect {
            id
            contact_l_id
            profile_company
            profile_name
            profile_location
            profile_experience
            profile_education
            profile_description
          }
          #profile_img
          created_at
          updated_at
        }
      }
      limit
      offset
    }
  }
`;

// List items queries
export const GET_LISTS_ITEMS_PAGINATED = gql`
  query getListItemsPaginated($list_id: ID!) {
    list_items_paginated(list_id: $list_id) {
      list_id
      contact_id
      list {
        id
        name
        user_id
      }
      contact {
        id
        l_id
        email
        phone
        company
        role
        websites
        contact_birthday
        prospect {
          id
          contact_l_id
          profile_company
          profile_name
          profile_location
          profile_experience
          profile_education
          profile_description
        }
        created_at
        updated_at
      }
    }
  }
`;
