let actionTypes = {
  set_user: "SET_USER",
  set_sonity_account: "SET_SONITY_ACCOUNT",
  set_sonity_accounts: "SET_SONITY_ACCOUNTS",
  set_sonity_profile: "SET_SONITY_PROFILE",
  set_sonity_profiles: "SET_SONITY_PROFILES",
  set_campaign_definition: "SET_CAMPAIGN_DEFINITION",
  set_campaign_definitions: "SET_CAMPAIGN_DEFINITIONS",
  set_state: "SET_STATE",
  set_window_dimensions: "SET_WINDOW_DIMENSIONS",
  clear_state: "CLEAR_STATE",
  set_event_subs: "SET_EVENT_SUBS",
  toggle_account_settings_modal:"TOGGLE_ACCOUNT_SETTINGS_MODAL",
  toggle_faq_modal:"TOGGLE_FAQ_MODAL",

};

export default actionTypes;
