import deepCompare from "./deepCompare";

const connectorInit = [
  {
    title: "Connection Message",
    content: "",
    fallback_content: "",
    inmail_subject: "",
    complete_time: ""
  },
  {
    title: "Welcome Message",
    delay: 0,
    content: "",
    fallback_content: "",
    complete_time: ""
  }
];

const flowInit = {};

const inMailInit = [
  {
    title: "Connection Message",
    content: "",
    fallback_content: "",
    inmail_subject: "",
    complete_time: ""
  }
];

const messengerInit = [
  {
    title: "Message 1",
    step: 0,
    delay: 0,
    content: "",
    fallback_content: "",
    complete_time: ""
  }
];

const autoAcceptInit = [
  {
    title: "Message 1",
    step: 0,
    delay: 0,
    content: "",
    fallback_content: "",
    complete_time: ""
  }
];

const schedulePostsInit = [
  {
    title: "Post 1",
    step: 0,
    delay: 0,
    content: "",
    fallback_content: "",
    complete_time: ""
  }
];


export const getAppropriateObject = (
  selectedCampaignDefinition,
  campaignMessages = null
) => {
  if (campaignMessages && campaignMessages.length > 0)
    return campaignMessages.map((cm, i) =>
      mapCmToUi(selectedCampaignDefinition, cm, i)
    );
  if (selectedCampaignDefinition.campaign_messages?.length > 0)
    return selectedCampaignDefinition.campaign_messages.map((cm, i) =>
      mapCmToUi(selectedCampaignDefinition, cm, i)
    );
  if (selectedCampaignDefinition.type === "Connector") return connectorInit;
  if (selectedCampaignDefinition.type === "Messenger") return messengerInit;
  if (selectedCampaignDefinition.type === "InMail") return inMailInit;
  if (selectedCampaignDefinition.type === "AutoAccept") return autoAcceptInit;
  if (selectedCampaignDefinition.type === "Flow") return flowInit;
  if (selectedCampaignDefinition.type === "SchedulePosts") return schedulePostsInit;


  alert("Campaign Type not implemented! ", selectedCampaignDefinition.type);
  return [];
};

export function mapCmToUi(selectedCampaignDefinition, campaignMessage, i) {
  console.log("mapping message: ", campaignMessage);

  //is endorsement
  if (campaignMessage.type.includes("Endorsement"))
    return {
      id: campaignMessage.id
        ? campaignMessage.id
        : parseInt(campaignMessage.step),
      step: campaignMessage.step,
      title: `Endorsement ${campaignMessage.step - 1}`,
      delay: campaignMessage.delay,
      content: campaignMessage.text,
      skip_message: campaignMessage.skip_message,
      fallback_content: campaignMessage.fallback_text,
      inmail_subject: campaignMessage.inmail_subject,
      like_post: campaignMessage.like_post,
      complete_time: campaignMessage.complete_time
    };

  if (
    selectedCampaignDefinition.type === "Connector" &&
    campaignMessage.step === 0
  )
    return {
      id: campaignMessage.id ? campaignMessage.id : parseInt(i),
      step: 0,
      title: "Connection Message",
      delay: 0,
      content: campaignMessage.text,
      skip_message: campaignMessage.skip_message,
      fallback_content: campaignMessage.fallback_text,
      inmail_subject: campaignMessage.inmail_subject,
      like_post: campaignMessage.like_post,
      complete_time: campaignMessage.complete_time
    };
  if (
    selectedCampaignDefinition.type === "Connector" &&
    campaignMessage.step === 1
  )
    return {
      id: campaignMessage.id
        ? campaignMessage.id
        : parseInt(campaignMessage.step),
      step: 1,
      title: "Welcome Message",
      delay: campaignMessage.delay ? campaignMessage.delay : 60,
      content: campaignMessage.text,
      skip_message: campaignMessage.skip_message,
      complete_time: campaignMessage.complete_time,
      fallback_content: campaignMessage.fallback_text,
      inmail_subject: campaignMessage.inmail_subject,
      like_post: campaignMessage.like_post,
      complete_time: campaignMessage.complete_time
    };
  if (
    selectedCampaignDefinition.type === "Connector" &&
    campaignMessage.step > 1
  ) {
    return {
      id: campaignMessage.id
        ? campaignMessage.id
        : parseInt(campaignMessage.step),
      step: campaignMessage.step,
      title: `Message ${campaignMessage.step - 1}`,
      delay: campaignMessage.delay,
      content: campaignMessage.text,
      skip_message: campaignMessage.skip_message,
      fallback_content: campaignMessage.fallback_text,
      inmail_subject: campaignMessage.inmail_subject,
      like_post: campaignMessage.like_post,
      complete_time: campaignMessage.complete_time
    };
  }

  if( selectedCampaignDefinition.type === 'SchedulePosts') 
    return {
      id: campaignMessage.id
        ? campaignMessage.id
        : parseInt(campaignMessage.step),
      step: campaignMessage.step,
      title: `Post ${campaignMessage.step + 1}`,
      delay: campaignMessage.delay,
      content: campaignMessage.text,
      extensions: campaignMessage.extensions,
      skip_message: campaignMessage.skip_message,
      complete_time: campaignMessage.complete_time
    };

  //is messenger campaign
  return {
    id: campaignMessage.id
      ? campaignMessage.id
      : parseInt(campaignMessage.step),
    step: campaignMessage.step,
    title: `Message ${campaignMessage.step + 1}`,
    delay: campaignMessage.delay,
    content: campaignMessage.text,
    skip_message: campaignMessage.skip_message,
    fallback_content: campaignMessage.fallback_text,
    inmail_subject: campaignMessage.inmail_subject,
    like_post: campaignMessage.like_post,
    complete_time: campaignMessage.complete_time
  };
}

export const showCommitBar = (steps, prevSteps) => {
  console.log("showCommirBar? ", {
    prevSteps,
    steps
  });
  return !deepCompare(
    steps.map(s => {
      if (s.errors) delete s.errors;
      return { ...s };
    }),
    prevSteps
  );
};
