import csvParser from "papaparse";

/**
 * @name exportCsv
 * @type function
 * @desc Export data into a CSV file
 * @param {string} listName - The name of the list. Option
 * @param {[Object]} data - The data to export 
 * @param {*} fields - An array of fields
 */
export const exportCsv = (listName, data, fields = []) => {
  if (data) {
    console.log("GOT DATA TO EXPORT: ", data);

    const csv = csvParser.unparse({
      fields,
      data: JSON.stringify(data)
    });

    const exportedFilename = `${listName}.csv` || `export${+new Date()}.csv`;
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } else {
    throw new Error("No data provided!");
  }
};
