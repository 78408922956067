import { SonityLocalStore, contextsList } from "@/utils/sonityLocalStore";



const sonityLocalStore = new SonityLocalStore()


const StepsTabState = (
  selectedSonityProfile,
  selectedCampaignDefinition,
  campaign_seed_data
) => {
  console.log("[StepsTab]InitState");
  return {
    steps: [...selectedCampaignDefinition.campaign_messages],
    prevSteps: [...selectedCampaignDefinition.campaign_messages],
    selectedCampaignDefinition,
    selectedSonityProfile,
    campaign_seed_data,
    showCommitBar: false,
    commitStatus: "No Changes",
    textfieldArea: []
  };
};

const storedState = sonityLocalStore.getContextState(contextsList.steps);

export default {
  ...StepsTabState,
  ...storedState
}
