import gql from "graphql-tag";

export const EVENT_CREATED = gql`
  subscription eventsSubscribe($user_id: ID!) {
    eventCreated(user_id: $user_id) {
      type
      payload
    }
  }
`;
