import { SonityLocalStore, contextsList } from "../../utils/sonityLocalStore";



let sonityLocalStore = new SonityLocalStore() 


export const originalInitialState = {
  sonityAccounts: [],
  selectedSonityAccount: null,
  sonityProfiles: [],
  statuses: [],
  selectedSonityProfile: null,
  campaignDefinitions: [],
  selectedCampaignDefinition: null,
  drivers: [],
  activeProfile: {},
  sonityProfileToDelete: null,
  updateDialogOpen: false,
  faqModalOpen:false,
  accountSettingsModalOpen:false,
  mounted: false,
  pinVerification: false,
  pinVerificationPayload: null,
  animalCaptchaVerification: false,
  animalCaptchaVerificationPayload:null,
  eventSubs: null
};

export const storedInitialState = sonityLocalStore.getContextState(contextsList.mainLayout)

export const combinateInitialState = {
  ...originalInitialState,
  ...storedInitialState
}
