import React, { useReducer } from "react";
import InitialState from "./InitialState";
import HomeReducer from "./HomeReducer";
import HomeContext from "./HomeContext";
import ActionTypes from "./ActionTypes";

import { useMessage } from "../../hooks/useMessage";
import { useStatistics } from "../../hooks/useStatistics";
import { useTasks } from "../../hooks/useTasks";
import { useMainLayoutContext } from "../mainLayoutState/MainLayoutProvider";

/**
 * 
 * @name HomeProvider
 * @type component
 * @desc Provides  access to home state, its state functions and hooks. Used on the HomePage and its children components
 * @prop {Component} children - Children components
 * @returns Provicer component
 */

function HomeProvider({ children }){ 
  const mainLayoutCtx = useMainLayoutContext();
  const {
    user,
    selectedSonityProfile,
    selectedCampaignDefinition
  } = mainLayoutCtx;

  const [state, dispatch] = useReducer(HomeReducer, {
    ...InitialState,
    selectedSonityProfile,
    selectedCampaignDefinition
  });

  // 🪝 Declare all hooks
  const [message, { updateMessage, hideMessage }] = useMessage();
  const useStatisticsHook = useStatistics(selectedSonityProfile, updateMessage);
  const useDriversHook = mainLayoutCtx.useDriversHook;
  const useCampaignDefinitionsHook = mainLayoutCtx.useCampaignDefinitionsHook;

  const useTasksHook = useTasks(
    null,
    updateMessage,
    state.selectedCompletedTimesState
  );

  // ⚙️ Declare all the functions
  const onOpenConfirmDialog = (id, type) => {
    dispatch({
      type: ActionTypes.set_state,
      payload: {
        itemToDelete: id,
        confirmationDialogOpen: true,
        messageType: type
      }
    });
  };

  const openNotificationsPanel = value => {
    dispatch({
      type: ActionTypes.set_state,
      payload: {
        notificationsDialogOpen: value
      }
    });
  };

  const openConfirmationDialog = value => {
    dispatch({
      type: ActionTypes.set_state,
      payload: {
        confirmationDialogOpen: value
      }
    });
  };

  const openDriverDialog = value => {
    dispatch({
      type: ActionTypes.set_state,
      payload: {
        driverModalOpened: value
      }
    });
  };

  const toggleDrawer = () => {
    dispatch({
      type: ActionTypes.toggle_notifications_dialog,
      payload: null
    });
  };

  const toggleContactsExtractionDialog = () => {
    dispatch({
      type: ActionTypes.toggle_extract_contacts_dialog
    });
  };

  const value = {
    state,
    dispatch,
    user,

    // ⚙️ Functions
    onOpenConfirmDialog,
    openNotificationsPanel,
    openConfirmationDialog,
    openDriverDialog,
    toggleDrawer,
    toggleContactsExtractionDialog,

    // 🪝 Hooks
    useMessageHook: [message, { updateMessage, hideMessage }],
    useStatisticsHook,
    useDriversHook,
    useCampaignDefinitionsHook,
    useTasksHook
  };

  return <HomeContext.Provider value={value}>{children}</HomeContext.Provider>;
}

export default HomeProvider;
