import React, { createContext, useContext, useReducer } from "react";

import { useFiltersState } from "../../hooks/useFiltersState";
import { useTcps } from "../../hooks/useTcps";
import { useMainLayoutContext } from "../mainLayoutState/MainLayoutProvider";
import initialState from "./TcpsLayoutInitialState";
import tcpsReducer from "./TcpsReducer.js";
import stateFunctions from "./TcpsStateFunctions";

// Context
const TcpsContext = createContext();


/**
 * 
 * @name TcpsContextProvider
 * @type component
 * @desc Provides  access to prospect state, its state functions and hooks. Used by many pages and their children
 * @prop {Component} children - Children components
 * @returns Provicer component
 */

export function TcpsContextProvider({ children }) {
  const [state, dispatch] = useReducer(tcpsReducer, initialState);
  const stateFuncs = stateFunctions(state, dispatch);

  const mainLayoutCtx = useMainLayoutContext();

  const {
    selectedSonityProfile,
    selectedCampaignDefinition
  } = mainLayoutCtx.state;

  // Hooks
  const [message, { updateMessage }] = mainLayoutCtx.useMessageHook;
  const useFiltersHook = useFiltersState();

  const useTcpsHook = useTcps(
    selectedSonityProfile,
    selectedCampaignDefinition,
    updateMessage,
    stateFuncs.setSelectedPeople,
    stateFuncs.setLoading,
    useFiltersHook[0].textFilters,
    useFiltersHook[0].statusFilter,
    useFiltersHook[0].allFilters,
    useFiltersHook[0].campaignDefinitionFilter,
    mainLayoutCtx
  );

  // Create value and pass it to provider
  const value = {
    state,
    dispatch,
    stateFunctions: stateFuncs,
    useTcpsHook: [...useTcpsHook],
    useFiltersHook: [...useFiltersHook]
  };

  return <TcpsContext.Provider value={value}>{children}</TcpsContext.Provider>;
}

/**
 * @name useTcpsContext
 * @type component
 * @desc Returns the TcpsContext  context value which is an object
 * @returns A context
 */
export function useTcpsContext() {
  return useContext(TcpsContext);
}
