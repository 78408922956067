import React, { useContext, createContext, useReducer } from "react";
import { feathersClient } from "../../attach-client";
import cubejs from "@cubejs-client/core";

import { useHistory, useLocation } from 'react-router-dom'

import { SonityLocalStore } from "./../../utils/sonityLocalStore"
import { authReducer, actionTypes } from "./AuthContextReducer";
import initialState from "./AuthContextInitialState"


const AuthContext = createContext();


const sonityLocalStore = new SonityLocalStore()



export const AuthProvider = ({ children }) => {

  const history = useHistory();
  const location = useLocation();

  const [state, dispatch] = useReducer(authReducer, initialState);

  const [cubejsApi, setCubejsApi] = React.useState(null);

  const feathersEvents = () => {
    /* FeathersClient Events */
    //console.log("Registiring feathers listeners")
    feathersClient.on("logout", login => {
      dispatch({
        type: actionTypes.set_state,
        payload: {
          user: null,
          token: null,
          cubejsApi: null
        }
      })

      history.push('/login')
    });

    feathersClient.on("authenticated", async login => {
      //let mainLayoutCtx = useMainLayoutContext();

      console.log("[AuthProvider] User logged in: ", login)
      const { user } = login;
      if (!user) throw Error("NoUser");


      feathersClient.set("user", user);

      //CUBEJS SERVICE
      const cubejsApi = cubejs(login.accessToken, {
        apiUrl: `${process.env.REACT_APP_CUBEJS_ENDPOINT}/cubejs-api/v1`
      });
      feathersClient.set("cubejsApi", cubejsApi);

      dispatch({
        type: actionTypes.set_state,
        payload: {
          user: user,
          token: login.accessToken,
          cubejsApi: cubejsApi
        }
      })

      if (document.location.pathname.includes("login") && user) {
        history.push("/profiles");
      } else {

        // We want to reconstruct states


        history.push(document.location.pathname.replace("/portal", ""))
      }
    });
  }




  React.useEffect(() => {


    feathersEvents()

    let searchParams = new URLSearchParams(document.location.search)


    const paramToken = searchParams.get('access_token')




    const token = paramToken ? paramToken : window.localStorage.getItem('feathers-jwt');
    feathersClient.authenticate({
      strategy: "jwt",
      accessToken: token
    })
      .then((res) => {
        //check if on '/login', or '/signup'
        //redirect to 'profiles'
        if (location.pathname.includes('login') || location.pathname.includes('signup')) {
          history.push('/profiles')
        }


      })
      .catch((e) => {
        console.log(e);

        const token = window.localStorage.getItem('feathers-jwt');

        feathersClient.authenticate({
          strategy: "jwt",
          accessToken: token
        }).catch(e => {
          console.log(e);
          //login redirect exceptions if Reset page, Recover page etc..
          if (!/(login|reset|recover|register)/.test(window.location.pathname)) {
            history.push('/login');
          }

        })

      });



    return () => {

    };
  }, [])




  return (
    <AuthContext.Provider value={{
      ...state
    }}>
      {children}
    </AuthContext.Provider>
  );
}



export const useAuthContext = () => {
  return useContext(AuthContext)
}