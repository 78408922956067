import {
  generateCubeQueries,
  generatePivotConfigs
} from "../../hooks/useReports";
import { SonityLocalStore, contextsList } from "../../utils/sonityLocalStore";

const sonityLocalStore = new SonityLocalStore();

const EVENT_NAMES = [
  "TrackingCampaignProspects",
  "Events"
  // "RequestEvents",
  //"ConnectedEvents",
  //"RepliedToConnectionEvents",
  //"RepliedToOtherEvents",
  //"DisconnectEvents"
];

const initialState = (sps, cds) => {
  console.log("[ReportsIntialState: ", { sps, cds });
  let earliestDate = cds
    .sort((a, b) => a.created_at - b.created_at)
    .map((cd) => new Date(parseInt(cd.created_at)))[0];

  earliestDate = earliestDate == undefined ? new Date() : earliestDate;

  const storedState = sonityLocalStore.getContextState(contextsList.reports);

  return {
    selectedSonityProfiles: [],
    selectedCampaignDefinitions: [],
    sliderStartDate: earliestDate,
    sliderEndDate: new Date(),
    timeWindow: [earliestDate.getTime(), new Date().getTime()],
    cubeQueryObjects: generateCubeQueries(EVENT_NAMES, sps, cds),
    pivotConfigs: generatePivotConfigs(EVENT_NAMES),
    timeDimensions: [],
    loading: false,
    error: false,
    ...storedState
  };
};

export default initialState;
