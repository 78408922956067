import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import makeStyles from '@mui/styles/makeStyles';

import WebhooksPanel from "@/pages/Profiles/ProfileManager/Panels/WebhooksPanel";
import { useMainLayoutContext } from "@/contexts/mainLayoutState/MainLayoutProvider";


const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "770px"
  },
  content: {
    padding: "1rem",
    color: "black",
    backgroundColor: "#efefef"
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#2196f3",
    padding: "0.5rem 0.25rem",
    margin: "0",
    boxShadow: "0px -1.5px 10px 1px rgba(0, 0, 0, 0.2)"
  },
  avatar: {
    width: "12rem",
    height: "12rem"
  },
  photoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "1.5rem 0",
    backgroundColor: "#efefef",
    minHeight: "16rem"
  },
  title: {
    backgroundColor: theme.palette.grey[800],
    color: "white",
    padding: theme.spacing(3),
    boxShadow: "0px -1.5px 10px 1px rgba(0, 0, 0, 0.2)"
  },
  titleText: {
    color: theme.palette.grey[100]
  }
}));

const AccountSettingsDialog = ({
  handleClose,
  handleClick,
  open,
}) => {
  const [panels, setPanels] = useState({
    email: false,
    password: false,
    proxy: false,
    driver: false,
    webhooks: false,
    notifications: false,
    campaigns: false
  });

  let mainLayoutCtx = useMainLayoutContext();

  const { selectedSonityAccount } = mainLayoutCtx.state;

  function handleExpand(panel) {
    setPanels({
      email: false,
      password: false,
      proxy: false,
      driver: false,
      webhooks: false,
      notifications: false,
      campaigns: false,
      [panel]: !panels[panel]
    });
  }

  const classes = useStyles();
    return (
      <Dialog
        open={open}
        onClose={handleClick.bind(this, "close-dialog")}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.title}>
          {"Account Settings"}
        </DialogTitle>

        <DialogContent className={classes.content}>
         
          {selectedSonityAccount ? (
            selectedSonityAccount.extensions ? (
              selectedSonityAccount.extensions.webhooks_enabled ? (
                <WebhooksPanel
                  expanded={panels.webhooks}
                  handleExpand={handleExpand}
                />
              ) : null
            ) : null
          ) : null}
        
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
              }

export default AccountSettingsDialog;
