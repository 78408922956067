import { SonityLocalStore, contextsList } from "../../utils/sonityLocalStore";




let sonityLocalStore = new SonityLocalStore()

export const actionTypes = {
    set_user: "set_user",
    set_token: "set_token",
    set_cubejs_api: "set_cubejs_api",
    set_state: "set_state",
}


export const authReducer = (state, action) => {

    let newState = state;


    switch (action.type) {
        case actionTypes.set_user:
            newState = {
                ...state,
                user: action.payload
            };
            break
        case actionTypes.set_token:
            newState = {
                ...state,
                token: action.payload
            };
            break
        case actionTypes.set_cubejs_api:
            newState = {
                ...state,
                cubejsApi: action.payload
            };
            break
        case actionTypes.set_state:
            newState = {
                ...state,
                ...action.payload
            };
            break
        default:
            newState = state;
            break
    }

    sonityLocalStore.storeContextState(contextsList.auth, newState)
    return newState;
    


}