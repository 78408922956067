const actionTypes = {
  set_selected_sonity_profiles: "SET_SELECTED_SONITY_PROFILES",
  set_selected_campaign_definitions: "SET_SELECTED_CAMPAIGN_DEFINITIONS",
  set_time_window: "SET_TIME_WINDOW",
  set_cube_query_objects: "SET_CUBE_QUERY_OBJECTS",
  set_pivot_configs: "SET_PIVOT_CONFIGS",
  set_time_window: "SET_TIME_WINDOW",
  set_slider_start_date: "SET_SLIDER_START_DATE",
  set_time_dimensions: "SET_TIME_DIMENSIONS",
  set_loading: "SET_LOADING",
  set_error: "SET_ERROR"
};

export default actionTypes;
