import gql from "graphql-tag";

export const GET_SONITY_PROFILES = gql`
  query getSonityProfiles($sonity_account_id: ID!) {
    sonity_profiles(input: { sonity_account_id: $sonity_account_id }){


    statuses {
      id
      status_flag
      status_label
      status_color
      phase
      business_order
      extensions      
      createdAt
      updatedAt
    }
    
    profiles {
      id
      email
      password
      extensions
      status
      crm_credentials {
        id
        sonity_profile_id
        type
        username
        password
        api_key
        crm_id
        endpoint
      }
      preferences {
        id
        type
        time_zone
        work_on_weekends
        start_time
        end_time
        extract_mode
        thread_sync_mode
        revoke_mode
        pause_time
        scan_time
        auto_launch
        preferences
        extensions
      }
      profile_image {
        id
        thumb
        contact_l_id
      }
      sonity_account_id
      sonity_account {
        id
      }
      contact_id
      contact {
        id
        l_id
        email
        phone
        title
        first_name
        last_name
        prospect {
          id
          profile_name
          profile_description
          profile_location
          first_name
          last_name
        }
      }
      selected_proxy_id
      proxies {
        id
        type
        name
        description
        host
        port
        username
        password
        created_at
        updated_at
      }
      campaign_definitions {
        id
        name
        type
        start_date
        set_active
        set_inmail
        extensions {
        requeue_errors
        requeue_withdrawn
      }

        campaign_messages {
          id
          step
          delay
          type
          text
          fallback_text
          inmail_subject
          created_at
          updated_at
          campaign_definition_id
          skip_message
          complete_time
          like_post
        }
        search {
          id
          url
          name
          status
          keywords
          filters
          created_at
          updated_at
          campaign_definition_id
        }

        created_at
        updated_at
      }
      created_at
      updated_at
    }
  }
}
`;

export const GET_SONITY_PROFILE = gql`
  query getSonityProfile($id: ID!) {
    sonity_profile(id: $id) {
      id
      email
      password
      extensions
      status
      crm_credentials {
        id
        sonity_profile_id
        type
        username
        password
        crm_id
        endpoint
      }
      preferences {
        id
        type
        time_zone
        work_on_weekends
        start_time
        end_time
        extract_mode
        thread_sync_mode
        revoke_mode
        pause_time
        scan_time
        preferences
        extensions
      }
      profile_image {
        id
        mime
        img
        thumb
        contact_l_id
      }
      sonity_account_id
      sonity_account {
        id
      }
      contact_id
      contact {
        id
        l_id
        email
        phone
        title
        first_name
        last_name
        prospect {
          id
          profile_name
          profile_description
          profile_location
          first_name
          last_name
        }
      }
      selected_proxy_id
      proxies {
        id
        type
        name
        description
        host
        port
        username
        password
        created_at
        updated_at
      }
      campaign_definitions {
        id
        name
        type
        start_date
        set_active
        set_inmail
        extensions {
          requeue_errors
          requeue_withdrawn
        }
        campaign_messages {
          id
          step
          delay
          type
          text
          fallback_text
          inmail_subject
          created_at
          updated_at
          campaign_definition_id
          skip_message
          complete_time
          like_post
        }
        search {
          id
          url
          name
          status
          keywords
          filters
          created_at
          updated_at
          campaign_definition_id
        }
        created_at
        updated_at
      }
      created_at
      updated_at
    }
  }
`;
