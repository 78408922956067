import { useLazyQuery } from "@apollo/client";
import { GET_EVENTS } from "../gql/queries/Events";

/**
 * @name useEvents
 * @type component
 * @desc A hook for querying events
 * @returns An object of events data and functions
 */
export const useEvents = () => {
  const [getEvents, getEventsResults] = useLazyQuery(GET_EVENTS, {
    fetchPolicy: "network-and-cache",
    onCompleted: data => {
      console.log("getEventsResults: ", data);
    }
  });

  return [{ getEvents }, { getEventsResults }];
};
