import React from "react";
// import InitialState from "./InitialState";
// import HomeReducer from "./ChatReducer";
import ChatContext from "./ChatContext";
// import ActionTypes from "./ActionTypes";
import { useMainLayoutContext } from "@/contexts/mainLayoutState/MainLayoutProvider";
import { useTcpsContext } from "@/contexts/TcpsState/TcpsContextProvider";


/**
 * 
 * @name ChatProvider
 * @type component
 * @desc Provides  access to chat state, its state functions and hooks. Used mainly by the List Manager State
 * @prop {Component} children - Children components
 * @returns Provicer component
 */

function ChatProvider({ children }) {
  let mainLayoutCtx = useMainLayoutContext();
  let tcpsCtx = useTcpsContext();

  const { selectedSonityProfile, user } = mainLayoutCtx;

  // const [state, dispatch] = useReducer(HomeReducer, {
  //   ...InitialState,
  //   selectedSonityProfile,
  //   selectedCampaignDefinition
  // });

  // 🪝 Declare all hooks
  const [
    message,
    { updateMessage, hideMessage }
  ] = mainLayoutCtx.useMessageHook;
  
  console.log("[ChatProvider]tcpsCtx", tcpsCtx)
  const value = {
    //state,
    //dispatch,
    user,
    selectedSonityProfile,
    // ⚙️ Functions
    // onOpenConfirmDialog,
    // openNotificationsPanel,
    // openConfirmationDialog,
    // openDriverDialog,
    // toggleDrawer,
    // toggleContactsExtractionDialog,

    // // 🪝 Hooks
    useMessageHook: [message, { updateMessage, hideMessage }],
    useTcpsHook: tcpsCtx.useTcpsHook
  };

  console.log("[ChatProvider]Value", value);
  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
}

export default ChatProvider;
