import gql from "graphql-tag";

export const GET_EVENTS = gql`
  query getTCPEvents($tracking_campaign_prospects_id: ID!) {
    events(tracking_campaign_prospects_id: $tracking_campaign_prospects_id) {
      id
      type
      user_id
      user_email
      sonity_profile_id
      sonity_profile_email
      campaign_definition_name
      campaign_status_id
      prev_campaign_status_id
      step
      message
      created_at
    }
  }
`;
