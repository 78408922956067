import { SonityLocalStore, contextsList } from "@/utils/sonityLocalStore";


const sonityLocalStore = new SonityLocalStore();

const InitialState = {
  lists: [],
  currentList: {
    name: "",
    type: "list"
  },
  openListDetailModal: false,
  openNewListModal: false,
  openMoveToListModal: false,
  editList: false,
  user: null,
  newListSearchUrl: "",
  newListStep: 0,
  activeStep: 0,
  visitMax: 150,
  skipped: [],
  completed: [],
  importMethod: "first_connections",
  sonityProfiles: [],
  selectedSonityProfiles: [],
  selectedCampaignDefinitions: [],
  pickMultipleSonityProfiles: true,
  listTasks: [],
  moveContacts: [],
  stepperNext: false
};

const storedInitialState = sonityLocalStore.getContextState(contextsList.listManager);


export default {
  ...InitialState,
  ...storedInitialState
}
