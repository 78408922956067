import React, { useState } from "react";

import { exportCsv } from "../utils/exportCsv";

import { useLazyQuery, useMutation, } from "@apollo/client";

import {
  CREATE_LIST,
  UPDATE_LIST,
  DELETE_LISTS,
  CREATE_LIST_ITEM,
  CREATE_LIST_ITEMS,
  UPDATE_LIST_ITEM,
  UPDATE_LIST_ITEMS,
  DELETE_LIST_ITEMS,
  CREATE_LIST_ITEMS_FROM_SEARCH,
  CREATE_LIST_ITEMS_FROM_CAMPAIGN
} from "../gql/mutations/Lists";
import { GET_LIST, GET_LISTS, GET_LISTS_PAGINATED } from "../gql/queries/Lists";
import { useTcpsContext } from "../contexts/TcpsState/TcpsContextProvider";

import { usePlaceholders } from "./usePlaceholders";
import { useMainLayoutContext } from "../contexts/mainLayoutState/MainLayoutProvider";

const EXPORTABLE_FIELDS = [
  "l_id",
  "name",
  "email",
  "phone",
  "websites",
  "birthday",
  "company",
  "position",
  "location",
  "description",
  // "experience",
  // "education",
  "org_size"
  // "status",
  // "created_at",
  // "updated_at"
];


/**
 * @name useLists
 * @desc A custom hook that provides data and functions for querying, mutating and manipulating lists 
 * @param {Object} selectedSonityProfile 
 * @param {Object} selectedCampaignDefinition 
 * @param {Function} updateMessage 
 * @returns Data and functions
 */
const useLists = (ssp, scd, updateMessage) => {
  /* can now use useCallback to prevent extra f() assignments/reassignments */
  const [blackList, setBlackList] = React.useState([]);
  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const [importBlackListOpen, setImportBlackListOpen] = useState(false);

  const mainCtx = useMainLayoutContext();
  const tcpsCtx = useTcpsContext();

  const { selectedSonityProfile, selectedSonityAccount, selectedCampaignDefinition} = mainCtx.state;

  const [
    { bulkSearchTcpsCreateResult, createTcpsResults },
    { bulkSearchTcpsCreate, createTcps }
  ] = tcpsCtx.useTcpsHook;

  const [{}, {bulkCreatePlaceholders}] = usePlaceholders(null, updateMessage);

  //List Queries
  const [getList, getListResults] = useLazyQuery(GET_LIST);
  const [getLists, getListsResults] = useLazyQuery(GET_LISTS, {
    fetchPolicy: "network-only"
  });
  const [getListsPaginated, getListsPaginatedResults] = useLazyQuery(
    GET_LISTS_PAGINATED
  );

  // List Mutations
  const [createList, createListResults] = useMutation(CREATE_LIST, {
    onCompleted: data => {
      if (updateMessage)
        updateMessage({
          isShown: true,
          variant: "success",
          content: "List created."
        });

        console.log("Create List Results useList: ", data)
    }
  });
  const [updateList, updateListResults] = useMutation(UPDATE_LIST, {
    onCompleted: data => {
      if (updateMessage)
        updateMessage({
          isShown: true,
          variant: "success",
          content: "List updated successfully."
        });
    }
  });

  const [
    createListItemsFromSearch,
    createListsItemsFromSearchResults
  ] = useMutation(CREATE_LIST_ITEMS_FROM_SEARCH, {
    onCompleted: data => {
      if (updateMessage)
        updateMessage({
          isShown: true,
          variant: "success",
          content: "Contacts added successfully."
        });
    }
  });

  const [
    createListItemsFromCampaign,
    createListsItemsFromCampaignResults
  ] = useMutation(CREATE_LIST_ITEMS_FROM_CAMPAIGN, {
    onCompleted: data => {
      if (updateMessage)
        updateMessage({
          isShown: true,
          variant: "success",
          content: "Contacts added successfully."
        });
    }
  });

  const [deleteLists, deleteListsResults] = useMutation(DELETE_LISTS, {
    onCompleted: data => {
      if (updateMessage)
        updateMessage({
          isShown: true,
          variant: "success",
          content: "List(s) have been removed."
        });
    },
    onError: data => {
      if (updateMessage)
        updateMessage({
          isShown: true,
          variant: "error",
          content: "There was a problem removing the list(s)."
        });
    }
  });

  // LIst Item Queries
  const [createListItem, createListItemResults] = useMutation(CREATE_LIST_ITEM);
  const [createListItems, createListItemsResults] = useMutation(
    CREATE_LIST_ITEMS,
    {
      onCompleted: data => {
        if (updateMessage)
          updateMessage({
            isShown: true,
            variant: "success",
            content: "Added contacts to list."
          });
      }
    }
  );

  const [updateListItem, updateListItemResults] = useMutation(UPDATE_LIST_ITEM);
  const [updateListItems, updateListItemsResults] = useMutation(
    UPDATE_LIST_ITEMS,
    {
      onCompleted: data => {
        if (updateMessage)
          updateMessage({
            isShown: true,
            variant: "success",
            content: "List items updated successfully!"
          });
      }
    }
  );
  const [deleteListItems, deleteListItemsResults] = useMutation(
    DELETE_LIST_ITEMS,
    {
      onCompleted: data => {
        if (updateMessage)
          updateMessage({
            isShown: true,
            variant: "success",
            content: "Removed contact(s) from list."
          });
      }
    }
  );

  /* Graphql */



 /**
  * @name handleImportBlackList
  * @type function
  * @desc Handle blacklist import. THis blacklist import contains blacklisted prospects
  * @param {[Object]} trackingCampaignProspects - List of prospects
  * @param {[Object]} selectedProspects - List of selected prospects
  * @param {string} importType - Type of import
  * @returns null
  */
  const handleImportBlackList = (
    trackingCampaignProspects,
    selectedProspects,
    importType
  ) => {
    const tcpIds = trackingCampaignProspects
      .filter(tcp => selectedProspects.map(p => p.l_id).includes(tcp.l_id))
      .map(tcp => tcp.id);
    //_handleClick("delete-tracking-campaign-prospects", tcpIds);
    setImportBlackListOpen(false);

    console.log("Setting blacklist with l_ids", selectedProspects);
  };

  /* Maps the FC into a TCP row */
  const toTcpTable = fcs =>
    fcs.map(fc => {
      console.log("map fc to tcp", fc);
      return {
        id: fc.id,
        l_id: fc.acceptor_l_id,
        contact_id: fc.contact?.id,
        prospect_id: fc.contact?.prospect?.id,
        name: fc.contact?.prospect?.profile_name,
        company: fc.contact?.company,
        description: fc.contact?.prospect?.profile_description,
        location: fc.contact?.prospect?.profile_location,
        position: fc.contact?.prospect?.profile_current_position,
        thumb: fc.contact?.profile_image?.thumb,
        org_size: fc.contact?.prospect?.org_size,
        email: fc.contact?.email,
        phone: fc.contact?.phone,
        birthday: fc.contact?.contact_birthday,
        //campaign_status_id: 1,
        updated_at: fc.updated_at,
        created_at: fc.created_at
      };
    });

   /**
  * @name handleImportFile
  * @type function
  * @desc Executes when a file imported. It sanitizes the data and filter before importing it
  * @param {Object} importOptions - Options for importing the file. It contains listId, prospects, message, importType, campaignDefinition, sonityProfile
  * @returns 
  */
  const handleImportFile = async importOptions => {
    if (!importOptions) {
      console.log("NoOptionsSupplied");
      return;
    }
    const {
      listId,
      prospects,
      messages,
      importType,
      campaignDefinition,
      sonityProfile
    } = importOptions;

    //TODO turn into webworker, upload in batches
   
    let sanitizedProfiles = prospects.map(row => ({
      current_position: row.current_position
        ? row.current_position.replace("'", "''")
        : null,
      description: row.description ? row.description.replace("'", "''") : null,
      email: row.email ? row.email.replace("'", "''") : null,
      l_id: row.l_id ? row.l_id.replace("'", "''") : null,
      location: row.location ? row.location.replace("'", "''") : null,
      name: row.name ? row.name.replace("'", "''") : null,
      phone: row.phone ? row.phone.replace("'", "''") : null,
      websites: row.websites ? row.websites.replace("'", "''") : null
    }));

    console.log("[handleImportFile]", {
      importType,
      importOptions,
      sanitizedProfiles
    })


    try {
      switch (importType) {
        case "tracking_campaign_prospects":
          console.log("[handleImportFile] Bulk creating tcps: ", {
            mainCtx,
            campaignDefinition,
            selectedCampaignDefinition,
            prospects,
            messages
          });
          let result;
          if (selectedCampaignDefinition && prospects.length > 0) {
            result = await bulkSearchTcpsCreate({
              variables: {
                sonity_profile_id: sonityProfile.id,
                campaign_definition_id: selectedCampaignDefinition.id,
                values: [...sanitizedProfiles]
              }
            });
            console.log("[handleImportFile] tcp results", result);
            const tcps = result.data.bulk_search_result_create;

            //Are there placeholders (messages) to handle?
            //Can also handle uploading tcp_placeholders here later
            if(messages.length > 0 && prospects.length > 0) {

              
              const parsedPlaceholders = messages.filter(m=> tcps.find(p=> p.l_id === m.l_id)).map(m=>({
                tracking_campaign_prospect_id: tcps.find(p=>p.l_id === m.l_id).id,
                ...m,
              })).map(m=>{
                //l_id is not on placeholders object
                delete m.l_id;
                
                return m;
              }).filter(m=>{
                //filter out blanks
               let doesNotHaveConnMesgs = m.connection_message?.length === 0 && m.welcome_message?.length === 0
               let doesNotHaveMessMesgs = m.message_x?.length === 0

                if(doesNotHaveConnMesgs && doesNotHaveMessMesgs) return false
                return true;               
              }).map(m=>{
                //del blank keys, one or both of connection_message/welcome_message exists here
                if(!m.connection_message || m.connection_message.length === 0) delete m.connection_message;
                if(!m.welcome_message || m.welcome_message.length === 0) delete m.welcome_message;
                if(!m.message_x || m.message_x.length === 0) delete m.message_x;
                
                // Remove message_1 and messae
                Object.keys(m).filter(k => k.startsWith('message_') && k !== 'message_x' ).forEach(k => delete m[k])

                return m
              });

              console.log("[handleImportFile]MessagePlaceholders", {
                parsedPlaceholders
              });
              const res = await bulkCreatePlaceholders({
                variables:{
                  input:parsedPlaceholders
                }
              });
              const createdPhs = res.data.bulk_create_placeholders;
              console.log("[handleImportFile]", createdPhs);


            }
          }
          


          //create the listItems if specified...
          if (listId) {
            console.log("List Items to upload: ", prospects);
            const list_items = await createListItemsFromSearch({
              variables: {
                list_id: listId,
                values: prospects,
                sonity_account_id: selectedSonityAccount.id
              }
            });
            console.log("Created List Items!", list_items);
          }

          break;
        case "prospects":
          // await bulkSearchTcpsCreate({
          //   variables: {
          //     sonity_profile_id: selectedSonityProfile.id,
          //     campaign_definition_id: selectedCampaignDefinition.id,
          //   createListItems  values: [...prospects]
          //   }
          // });'

          console.log("Imported Prospects: ", sanitizedProfiles);

          prospects = sanitizedProfiles.map(p => {
            return {
              ...p,
              first_name: p.name.split(" ")[0],
              last_name: p.name.split(" ")[1]
            };
          });

          await createListItemsFromSearch({
            variables: {
              list_id: listId,
              values: prospects,
              sonity_account_id: selectedSonityAccount.id
            }
          });

          break;
        default:
          console.log("Unhandled importType: ", importType);
      }
    } catch (e) {
      console.log(e);
    }
    setImportDialogOpen(false);
  };


   /**
  * @name handleExportCsv
  * @type function
  * @desc Executes when we request list prospects/tcps to be exported into a CSV file
  * @param {string} listName - List name
  * @param {[Object]} selected - Selected prospects
  * @param {[Object]} tcps - A list of tcps
  * @returns null
  */
  const handleExportCsv = async (listName, selected, tcps) => {
    try {
      const data = tcps.filter(tcp => selected.includes(tcp.id));
      const withLIdField = data.map(tcp => {
        const tcpCopy = { ...tcp };

        /* need flat structure as csv is flat and nested objects cannot be parsed */
        tcpCopy.l_id = tcpCopy.contact?.l_id;
        tcpCopy.name = tcpCopy.prospect?.profile_name;
        tcpCopy.email = tcpCopy.contact?.email;
        tcpCopy.phone = tcpCopy.contact?.phone;
        tcpCopy.websites = tcpCopy.contact?.websites;
        tcpCopy.birthday = tcpCopy.contact?.contact_birthday;
        tcpCopy.company =
          tcpCopy.contact?.company || tcpCopy.prospect?.profile_company;
        tcpCopy.position = tcpCopy.prospect.profile_current_position;
        tcpCopy.description = tcpCopy.prospect?.profile_description;
        tcpCopy.experience = tcpCopy.prospect?.profile_experience;
        tcpCopy.education = tcpCopy.prospect?.profile_education;
        tcpCopy.org_size = tcpCopy.prospect?.org_size;

        return tcpCopy;
      });
      exportCsv(listName, withLIdField, EXPORTABLE_FIELDS);
    } catch (e) {
      console.log(e);
    }
  };

   /**
  * @name handleExportContactsToCSV
  * @type function
  * @desc Executes when we request contacts to be exported into a CSV file
  * @param {string} listName - List name
  * @param {[Object]} contacts - A list of contacts
  * @returns null
  */
  const handleExportContactsToCSV = async (listName, contacts) => {
    try {
      let processedData = contacts
        .filter(c => c.prospect != undefined)
        .map(c => {
          let prospect = c.prospect;
          delete c["prospect"];
          delete c["id"];

          console.log("[handleExportContacts] Prospect: ", {
            contact: c,
            prospect
          });

          return {
            ...c,
            name: prospect.profile_name,
            company: c.company || prospect.profile_company,
            position: prospect.profile_current_position,
            description: prospect.profile_description,
            birthday: c.contact_birthday,
            location: prospect.profile_location,
            education: prospect.profile_education,
            experience: prospect.profile_experience,
            org_size: prospect.org_size
          };
        });

      exportCsv(listName, processedData, EXPORTABLE_FIELDS);
    } catch (e) {
      console.log(e);
    }
  };

  return [
    {
      blackList,
      importDialogOpen,
      importBlackListOpen,
      getList,
      getLists,
      getListsPaginated,
      toTcpTable,
      createList,
      updateList,
      deleteLists,
      createListItem,
      createListItems,
      updateListItem,
      updateListItems,
      deleteListItems,
      createListItemsFromSearch,
      createListItemsFromCampaign
    },
    {
      handleExportCsv,
      exportCsv,
      handleImportBlackList,
      handleExportContactsToCSV,
      handleImportFile,
      setImportDialogOpen,
      setImportBlackListOpen,
      getListResults,
      getListsResults,
      getListsPaginatedResults,
      createListResults,
      updateListResults,
      deleteListsResults,
      createListItemResults,
      createListItemsResults,
      updateListItemResults,
      updateListItemsResults,
      deleteListItemsResults,
      createListsItemsFromSearchResults,
      createListsItemsFromCampaignResults
    }
  ];
};

export default useLists;
