/**
 * @page_name - AppRouter
 * @page_desc - Defines the app's routes
 */

import React, { useEffect, lazy, Suspense } from "react";
import {
  Route,
  Redirect,
  Switch
} from "react-router-dom";


/* Layout */

import MainLayout from "@/components/general/MainLayout";
import NavBar from "@/components/general/NavBar";
import LoadingFallback from "@/components/generic/LoadingFallback";
import LoadingCredentials from "@/components/LoadingCredentials";

/* Context Providers */
import { TcpsContextProvider } from "@/contexts/TcpsState/TcpsContextProvider";
import { ListManagerProvider } from "@/contexts/ListManagerState/ListManagerProvider";
import { ReportsContextProvider } from "@/contexts/ReportsState/ReportsContextProvider";
import { StepsTabProvider } from "@/contexts/stepsState/stepsTabProvider";
import { FlowProvider } from "@/contexts/FlowContext/flowProvider";
import { HomeProvider } from "@/contexts/HomeState";
import { ChatProvider } from "@/contexts/ChatsState";


/* Pages */
//Lazy loading all the components here, this section has the ones that work
const PageNotFound = lazy(() => import("@/pages/404"));
const Login = lazy(() => import("@/pages/Login"));
const Register = lazy(() => import("@/pages/Register"));
const Recover = lazy(() => import("@/pages/Recover"));
const Reset = lazy(() => import("@/pages/Reset"));
const Verified = lazy(() => import("@/pages/Verified"));
const CampaignsChatPage = lazy(() => import("@/pages/CampaignsChatPage"));
const ContactsPage = lazy(() => import("@/pages/ContactsPage"));
const Profiles = lazy(() => import("@/pages/Profiles"));
const ConnectorCampaignTabs = lazy(() =>
  import("@/pages/Campaigns/ConnectorCampaignTabs")
);
const MessengerCampaignTabs = lazy(() =>
  import("@/pages/Campaigns/MessengerCampaignTabs")
);
const AutoAcceptCampaignTabs = lazy(() =>
  import("@/pages/Campaigns/AutoAcceptCampaignTabs")
);
const InMailCampaign = lazy(() => import("@/pages/Campaigns/InMailCampaign"));
const SchedulePostsCampaign = lazy(() => import("@/pages/Campaigns/SchedulePosts"));
const FlowCampaign = lazy(() => import("@/pages/Campaigns/FlowCampaign"));

const ListManager = lazy(() => import("@/pages/ListManager"));
const Reports = lazy(() => import("@/pages/Reports"));
const HomePage = lazy(() => import("@/pages/Home/HomePage"));

const AccountSettings = lazy(() => import("@/pages/AccountSettings"));



function AppRouter() {

  return (
    <Suspense fallback={<LoadingFallback />}>
      {/*  <NavBar feathersClient={feathersClient}/> */}
      <Switch>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/recover" component={Recover} />
        <Route path="/reset" component={Reset} />
        <Route path="/verified" component={Verified} />
        <Route path="/loading-credentials" component={LoadingCredentials} />
        <Route
          path="/home"
        >

          <MainLayout>
            <HomeProvider>
              <HomePage />
            </HomeProvider>
          </MainLayout>

        </Route>

        <Route
          path="/list-manager"
        >

          <MainLayout>
            <TcpsContextProvider>
              <ListManagerProvider>
                <ListManager />
              </ListManagerProvider>
            </TcpsContextProvider>
          </MainLayout>


        </Route>

        <Route
          path="/profiles"
        >
          <MainLayout>
            <Profiles />
          </MainLayout>
        </Route>

        <Route
          path="/connector-campaign"
        >

          <MainLayout>
            <TcpsContextProvider>
              <ListManagerProvider>
                <ReportsContextProvider>
                  <StepsTabProvider>
                    <ChatProvider>
                      <ConnectorCampaignTabs />
                    </ChatProvider>
                  </StepsTabProvider>
                </ReportsContextProvider>
              </ListManagerProvider>
            </TcpsContextProvider>
          </MainLayout>
        </Route>
        <Route
          path="/messenger-campaign"
        >

          <MainLayout>
            <TcpsContextProvider>
              <ListManagerProvider>
                <ReportsContextProvider>
                  <StepsTabProvider>
                    <ChatProvider>
                      <MessengerCampaignTabs />
                    </ChatProvider>
                  </StepsTabProvider>
                </ReportsContextProvider>
              </ListManagerProvider>
            </TcpsContextProvider>
          </MainLayout>
        </Route>
        <Route
          path="/auto-accept-campaign"
        >
          <MainLayout>
            <TcpsContextProvider>
              <ListManagerProvider>
                <ReportsContextProvider>
                  <StepsTabProvider>
                    <ChatProvider>
                      <AutoAcceptCampaignTabs />
                    </ChatProvider>
                  </StepsTabProvider>
                </ReportsContextProvider>
              </ListManagerProvider>
            </TcpsContextProvider>
          </MainLayout>


        </Route>



        <Route
          path="/inmail-campaign"
        >
          <MainLayout>
            <TcpsContextProvider>
              <ListManagerProvider>
                <ReportsContextProvider>
                  <StepsTabProvider>
                    <ChatProvider>
                      <InMailCampaign />
                    </ChatProvider>
                  </StepsTabProvider>
                </ReportsContextProvider>
              </ListManagerProvider>
            </TcpsContextProvider>
          </MainLayout>

        </Route>



        <Route
          path="/flow-campaign"
        >
          <MainLayout>
            <FlowProvider>
              <TcpsContextProvider>
                <ListManagerProvider>
                  <ReportsContextProvider>
                    <StepsTabProvider>
                      <ChatProvider>
                        <FlowCampaign />
                      </ChatProvider>
                    </StepsTabProvider>
                  </ReportsContextProvider>
                </ListManagerProvider>
              </TcpsContextProvider>
            </FlowProvider>
          </MainLayout>


        </Route>
        <Route
          path="/schedule-posts-campaign"
        >
          <MainLayout>
            <ReportsContextProvider>
              <StepsTabProvider>

                <SchedulePostsCampaign />

              </StepsTabProvider>
            </ReportsContextProvider>

          </MainLayout>


        </Route>


        <Route
          path="/reports"
        >

          <MainLayout>
            <ReportsContextProvider>
              <Reports />
            </ReportsContextProvider>
          </MainLayout>


        </Route>


        <Route path="/contacts/:selectedContact?">
          <>
            <NavBar />
            <ContactsPage />
          </>
        </Route>

        <Route
          path="/campaigns-chats"
        >

          <MainLayout>
            <TcpsContextProvider>
              <ListManagerProvider>
                <ChatProvider>
                  <CampaignsChatPage />
                </ChatProvider>
              </ListManagerProvider>
            </TcpsContextProvider>
          </MainLayout>


        </Route>
        <Route
          path="/account-settings"
        >
          <AccountSettings />
        </Route>

        {/* <Route path="*" component={PageNotFound} /> */}
      </Switch>
      {/* what is this? 
        <PrivateRoute
          path="/drive-extraction"
          component={
            <MainLayout>
              <DriveProfileExtraction />
            </MainLayout>
          }
        /> */}
    </Suspense>
  );
}

export default AppRouter;
