import React, { useCallback, useEffect, useState } from "react";

import { feathersClient } from "../attach-client";

export default (user, updateMessage, state, mainLayoutStateFunctions) => {
  const [mounted, setMounted] = useState(false);

  const {
    setSelectedSonityAccount,
    setSonityAccounts,
    setUser
  } = mainLayoutStateFunctions;

  useEffect(() => {
    //IF WE DON'T HAVE A USER ON THE MAINLAYOUTCTX, WE WANT TO SET ONE
    // if (!user) {
    //   const currentUser = feathersClient.get("user");
    //   console.debug("Current User on useSonityAccounts", currentUser);
    //   setUser(currentUser);
    // }
    if (user && user.sonity_account_id) {
      feathersClient
        .service("sonity-accounts")
        .get(user.sonity_account_id)
        .then(sa => {
          setSelectedSonityAccount(sa);
          setSonityAccounts([sa]);
          feathersClient.set("selenium_endpoint", sa.selenium_endpoint);
        })
        .catch(console.error);
      setMounted(true);
    }
    //possibly v2
    if (user && !user.sonity_account_id && !mounted) {
      feathersClient
        .service("sonity-accounts")
        .find({
          query: {
            user_id: user.id
          }
        })
        .then(sa => {
          //setSelectedSonityAccount(sa);
          if (sa.data && sa.data.length > 0) {
            setSonityAccounts([...sa.data]);

            //TODO: we may wish to create a SonityAccount selector dialog here
            setSelectedSonityAccount(sa.data[0]);
          }
        })
        .catch(console.error);
      setMounted(true);
    }
  }, [user]);
  return {};
};
