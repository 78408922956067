import actionTypes from "./flowActionTypes";

const stateFunctions = (state, dispatch) => {
  const triggerAddActionMenu = id => {
    dispatch({
      type: actionTypes.show_add_action_menu,
      payload: id
    });
  };

  const triggerChangeDelayPopup = timeframe => {
    dispatch({
      type: actionTypes.show_change_delay_popup,
      payload: timeframe
    });
  };

  const closeOpenPopups = () => {
    dispatch({
      type: actionTypes.close_open_popups
    });
  };

  const addNewNodes = (id, nodes, edges) => {
    dispatch({
      type: actionTypes.set_state,
      payload: {
        nodes_add: {
          replace_node_id: id,
          nodes: nodes,
          edges: edges
        }
      }
    });
  };

  const updateNodeData = (id, data) => {
    dispatch({
      type: actionTypes.set_state,
      payload: {
        node_update_data: {
          id: id,
          data: data
        }
      }
    });
  };

  const updateEdgeData = (id, data) => {
    dispatch({
      type: actionTypes.set_state,
      payload: {
        edge_update_data: {
          id: id,
          data: data
        }
      }
    });
  };

  const removeNode = id => {
    dispatch({
      type: actionTypes.set_state,
      payload: {
        node_remove: {
          id: id
        }
      }
    });
  };

  const changeLayout = () => {
    dispatch({
      type: actionTypes.set_state,
      payload: {
        layout: state.layout == "TB" ? "LR" : "TB"
      }
    });
  };

  const editNodeSettings = (id, data) => {
    console.log("Edit task settings: ", {
      id,
      data
    });
    dispatch({
      type: actionTypes.set_state,
      payload: {
        edit_node_settings: {
          id: id,
          data: data
        }
      }
    });
  };

  return {
    triggerAddActionMenu,
    closeOpenPopups,
    triggerChangeDelayPopup,
    addNewNodes,
    updateNodeData,
    updateEdgeData,
    removeNode,
    changeLayout,
    editNodeSettings
  };
};

export default stateFunctions;
