import React, { useContext, useReducer } from "react";
import Joyride, { STATUS,  EVENTS, ACTIONS } from "react-joyride";


import InitialState from "./InitialState";
import TourReducer from "./TourReducer";
import TourContext from "./TourContext";
import ActionTypes from "./ActionTypes";
import TourStateFunctions from "./TourStateFunctions"
import { useMainLayoutContext } from "../mainLayoutState/MainLayoutProvider";
import { useUsers } from "../../hooks/useUsers";

import {  } from 'react-joyride';
import actionTypes from "./ActionTypes";


/**
 * 
 * @name TourProvider
 * @type component
 * @desc Provides  access to tour state, its state functions and hooks. Used on almost all page to display guides.
 * @prop {Component} children - Children components
 * @returns Provicer component
 */

function TourProvider({ children }){ 
  const mainLayoutCtx = useMainLayoutContext();
  const {
    user
  } = mainLayoutCtx;

  const [state, dispatch] = useReducer(TourReducer, {
    ...InitialState
  });

  
  const [
    {
      updateUserResults
    },
    {
      updateUser
    }
  ] = useUsers()


  let tourStateFunctions = TourStateFunctions(state, dispatch, {updateUser})

  /**
   * @name handleJoyrideCallback
   * @type function
   * @desc Called by the Joyride component if the status changes
   * @param {*} data - Data from Joyride component 
   */
  const handleJoyrideCallback =  data => {
    const { action, index, status, type } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      console.log("Current State: ", state)
      console.log("Current User: ", user)
      tourStateFunctions.completeTour(state.tour_name, user)
    }

    console.groupCollapsed(type);
    console.log(data); //eslint-disable-line no-console
    console.groupEnd();
  };





  const value = {
    state,
    dispatch,
    user,
    stateFunctions: tourStateFunctions
  };


  return <TourContext.Provider value={value}>
    { state.run && <Joyride
        debug
        continuous
        callback={handleJoyrideCallback}
        run={state.run}
        steps={state.steps}
        locale={{
          last: 'Close'
        }}
        hideCloseButton
        scrollToFirstStep
        showSkipButton
        // showProgress
        spotlightClicks
        styles={{
          options: {
            zIndex: 1300,
          }
        }}
      />}

    {children}
    
  </TourContext.Provider>;
}

/**
 * @name useTourContext
 * @type function
 * @desc Return tour context
 * @returns TourContext
 */
export function useTourContext(){
  return useContext(TourContext)
}

export default TourProvider;
