import React, { useMemo, useState } from "react";
import { getUuid } from "../utils/idsHelpers";


/**
 * @name useFiltersState
 * @type component
 * @desc Is a hook that allows to apply filters to prospects
 * @returns An object with filter data and functions
 */
export function useFiltersState() {
  const [textFilters, setTextFilters] = React.useState([]);
  const [dateRangeEnabled, setDateRangeEnabled] = useState(false);
  const [filterCampaignMatches, setFilterCampaignMatches] = useState(true);
  const [dateRangeFilter, _setDateRangeFilter] = useState(null);
  


  const [campaignDefinitionFilter, _setCampaignDefinitionFilter] = useState({
    type: "campaign_filter",
    value: "all"
  });

  const [statusFilter, _setStatusFilter] = useState(null);

  const [ageRangeFilter, _setAgeRangeFilter] = useState({
    type: "age",
    value: [-1, -1]
  });
  const [genderFilter, _setGenderFilter] = useState({
    type: "gender",
    value: "all"
  });
  const [genderProbabilityFilter, _setGenderProbabilityFilter] = useState({
    type: "gender_prob",
    value: 50
  });

  const setCampaignDefinitionFilter = value => {
    _setCampaignDefinitionFilter({
      type: "campaign_filter",
      value
    });
  };

  const setAgeRangeFilter = value => {
    _setAgeRangeFilter({
      type: "age",
      value
    });
  };
  const setGenderProbabilityFilter = value => {
    _setGenderProbabilityFilter({
      type: "gender_prob",
      value
    });
  };
  const setStatusFilter = status => {
    console.log("new status from the setStatusFilter function: ", status);
    _setStatusFilter({
      id: getUuid(),
      type: "campaign_status_id",
      value: status
    });
  };

  const setDateRangeFilter = (e, newValue, type = "connection_date") => {
    // may also be created_at
    console.log("new connection_date");
    console.log(newValue.map(v => `${v}`));
    _setDateRangeFilter({
      id: getUuid(),
      type,
      value: newValue.map(v => `${v}`)
    });
  };

  const setGenderFilter = gender => {
    _setGenderFilter({
      ...genderFilter,
      value: gender
    });
  };

  
  /**
 * @name allFilters
 * @type function
 * @desc Combines all filters into a list
 * @returns A list of combined filters
 */
  const allFilters = useMemo(() => {
    let combinedFilters = [
      genderFilter,
      genderProbabilityFilter,
      ageRangeFilter
    ];

    // Add text search filters
    const unwrappedTextFilters = Object.values(textFilters).flat();

    if (textFilters.length > 0) {
      let textSearches = {
        ...unwrappedTextFilters[0],
        label: "text_searches",
        type: "text_searches"
      };

      textSearches.value = textFilters.map(t => t.value);
      combinedFilters = [...combinedFilters, textSearches];
    }

    // add date range filters here to input filters
    if (dateRangeEnabled && dateRangeFilter) {
      combinedFilters = [...combinedFilters, dateRangeFilter];
    }

    if (statusFilter) {
      combinedFilters = [...combinedFilters, statusFilter];
    }

    if (campaignDefinitionFilter) {
      combinedFilters = [...combinedFilters, campaignDefinitionFilter];
    }
    console.log("combinedFilters: ", combinedFilters);
    return combinedFilters;
  }, [
    textFilters,
    filterCampaignMatches,
    dateRangeEnabled,
    dateRangeFilter,
    statusFilter,
    ageRangeFilter,
    genderFilter,
    genderProbabilityFilter,
    campaignDefinitionFilter
  ]);

  return [
    {
      allFilters,
      textFilters,
      dateRangeFilter,
      statusFilter,
      ageRangeFilter,
      genderFilter,
      genderProbabilityFilter,
      campaignDefinitionFilter,
      dateRangeEnabled,
      filterCampaignMatches
    },
    {
      setStatusFilter,
      setTextFilters,
      setDateRangeFilter,
      setAgeRangeFilter,
      setGenderFilter,
      setGenderProbabilityFilter,
      setCampaignDefinitionFilter,
      setDateRangeEnabled,
      setFilterCampaignMatches
    }
  ];
}
