import { useState } from "react";

import { EVENT_CREATED } from "../gql/subscriptions/Events";
import { useSubscription } from "@apollo/client";
import { handleBrowserNotifications } from "../utils/browserNotifications";
import { getGqlClient } from "../attach-client";


/**
 * @name useEventSubs
 * @type component
 * @desc Is a hook that allows you subscribe to sonity events and get data from those events when recieved
 * @prop {Object} user - Current user
 * @prop {Object} mainLayoutState - An object of  MainLayout current state
 * @prop {Object} mainLayoutStateFunctions  - An object of Main Layout state functions
 * @returns An object event data and functions
 */

const useEventSubs = (user, mainLayoutState, mainLayoutStateFunctions, setDrivers) => {
  const [sessionMessages, setSessionMessages] = useState([]);
  const { sonityProfiles } = mainLayoutState;

  // const [{ drivers }, { setDrivers }] = mainCtx.useDriversHook;
  const {
    triggerVerificationModals,
  } = mainLayoutStateFunctions;

  const { data: eventsSubData, loading: eventsSubLoading, error: eventsSubError, subscribeToMore } = useSubscription(EVENT_CREATED, {
    variables: {
      user_id: user ? user.id : null
    },
    client: getGqlClient(),
    // this will fire each time message is send via linkedin
    onSubscriptionData: ({ subscriptionData }) => {
      const { eventCreated } = subscriptionData.data;

      let eventType = eventCreated.type
      let eventPayload = JSON.parse(eventCreated.payload)

      console.log("[useEventSubs]", { eventType, eventPayload })

      mainLayoutStateFunctions.setEventSubs({
        type: eventType,
        payload: eventPayload
      })

      // Event handlers
      if (handleBrowserNotifications)
        handleBrowserNotifications(eventCreated, sonityProfiles);
      if (triggerVerificationModals) triggerVerificationModals(eventCreated);


      //Move to useDrivers
      if (eventType == "DRIVER_CREATED" && eventPayload) {
        setDrivers(ds => [...ds, eventPayload]);

      }

      if (eventType == "DRIVER_UPDATED" && eventPayload) {
        setDrivers(ds => {

          const updatedDrivers = [...ds.map(d => d.sonity_profile_id == eventPayload.sonity_profile_id ? { ...d, ...eventPayload } : { ...d })];
          return updatedDrivers;

        });
      }
      if (eventType == "DRIVER_KILLED" && eventPayload) {
        setDrivers(ds => [...ds.filter(d => d.sonity_profile_id !== eventPayload.sonity_profile_id)])
      }
    }
  });

  return {
    sessionMessages,
    eventsSubData,
    eventsSubLoading,
    eventsSubError
  };
};

export default useEventSubs;
