import React from "react";

import makeStyles from '@mui/styles/makeStyles';
import { Avatar, FormLabel } from "@mui/material";
//import HomeIcon from '@mui/icons-material/Home'
import LoadingBars from "../../general/LoadingBars";
import SonityProfileDropDownMenu from "./SonityProfileDropDownMenu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { GET_SONITY_PROFILES } from "../../../gql/queries/SonityProfiles";
import { useQuery } from "@apollo/client";
import { feathersClient } from "../../../attach-client";
import AvatarWithBorder from "../AvatarWithBorder";
import { useMainLayoutContext } from "../../../contexts/mainLayoutState/MainLayoutProvider";



const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main
  },
  profilesTab: {
    cursor: "pointer"
  },
  avatar: {
    margin: "0 0.5rem"
  },
  avatarRoot: {
    border: `0px solid pink`
  },
  avatarRootDriverOffline: {
    border: `1px dashed red`
  },
  avatarRootDriverBusy: {
    border: `1px solid green`
  },
  avatarRootDriverAvailable: {
    border: `1px solid green`
  },
  avatarRootDriverRefractory: {
    border: `1px solid yellow`
  },
  avatarRootDriverError: {
    border: `1px solid red`
  },
  row: {
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  text: {
    fontWeight: 600,
    color: theme.palette.background.default
  },
  grow: {
    flexGrow: 1
  }
}));

export default ({ }) => {
  const mainCtx = useMainLayoutContext();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { user } = mainCtx;
  const { selectedSonityProfile, sonityProfiles } = mainCtx.state;
  const [{ drivers }, { }] = mainCtx.useDriversHook;

  const driver = selectedSonityProfile
    ? drivers.find(
      d =>
        parseInt(d.sonity_profile_id) === parseInt(selectedSonityProfile.id)
    )
    : null;

  return (
    <div className={classes.root}>
      <span
        className={`${classes.row} ${classes.profilesTab}`}
        onClick={e => {
          setAnchorEl(document.getElementById("profile-dropdown"));
        }}
      >
        {!selectedSonityProfile && (
          <FormLabel className={classes.text} variant="error">
            No Sonity Profile selected
          </FormLabel>
        )}
        {selectedSonityProfile && (
          <span className={`${classes.row} ${classes.grow}`}>
            <AvatarWithBorder
              driver={driver}
              sonity_profile={selectedSonityProfile}
            />
            <FormLabel className={classes.text}>
              {selectedSonityProfile.email}
            </FormLabel>
          </span>
        )}
        {!anchorEl && <ArrowDropDownIcon />}
        {anchorEl && <ArrowDropUpIcon />}
        {/* {loading && <LoadingBars />} */}
      </span>
      <SonityProfileDropDownMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        selectedSonityProfile={selectedSonityProfile}
        user={user}
        drivers={drivers}
        sonityProfiles={sonityProfiles || []}
      />
    </div>
  );
};
