export default (
  user,
  setWebhookEvents,
  userWebhooks,
  setUserWebhooks,
  editWebhookIndex,
  editEndpointIndex,
  setEditWebhookIndex,
  setEditEndpointIndex,
  setEditMode,
  setEditWebhook,
  feathersClient
) => {
  const onDesiredEventsChange = newWebhookEvents => {
    setWebhookEvents([...newWebhookEvents]);
  };

  const deleteEndpoint = webhook => {
    for (let i = 0; i < userWebhooks.length; i++) {
      if (userWebhooks[i].path === webhook.path) {
        let newEndpoints = userWebhooks[i].endpoints.filter(
          endpoint => endpoint !== webhook.endpoint
        );

        if (newEndpoints.length > 0) {
          feathersClient
            .service("sonity-account-webhooks")
            .patch(userWebhooks[i].sonity_account_id, {
              endpoints: newEndpoints
            })
            .then(updatedWebhook => {
              console.log("Updated Webhook: ", updatedWebhook);
              let newUserWebhooks = userWebhooks.filter(
                userWebhook => userWebhook.id !== updatedWebhook.id
              );
              setUserWebhooks([...newUserWebhooks, updatedWebhook]);
            });
        } else {
          // Delete the webhook
          feathersClient
            .service("sonity-account-webhooks")
            .remove(userWebhooks[i].id)
            .then(deletedWebhook => {
              console.log("Deleted Webhook: ", deletedWebhook);
              let newUserWebhooks = userWebhooks.filter(
                userWebhook => userWebhook.id !== deletedWebhook.id
              );
              setUserWebhooks(newUserWebhooks);
            });
        }
        // TODO: Try to break out of the loop
      }
    }
  };

  const addNewWebhook = webhook => {
    // Check if webhook already exists

    let webhookPathExists = false;
    userWebhooks.forEach(userWebhook => {
      if (userWebhook.path === webhook.path) {
        webhookPathExists = true;
      }
    });

    if (!webhookPathExists) {
      feathersClient
        .service("sonity-account-webhooks")
        .create({
          path: webhook.path,
          endpoints: [webhook.endpoint],
          sonity_account_id: user.sonity_account_id
        })
        .then(createdWebhook => {
          console.log("Created Webhook: ", createdWebhook);
          setUserWebhooks([...userWebhooks, createdWebhook]);
        });
    } else {
      for (let i = 0; i < userWebhooks.length; i++) {
        if (userWebhooks[i].path === webhook.path) {
          feathersClient
            .service("sonity-account-webhooks")
            .patch(userWebhooks[i].id, {
              endpoints: [...userWebhooks[i].endpoints, webhook.endpoint]
            })
            .then(updatedWebhook => {
              console.log("Updated Webhook: ", updatedWebhook);
              let newUserWebhooks = userWebhooks.filter(
                userWebhook => userWebhook.id !== updatedWebhook.id
              );
              setUserWebhooks([...newUserWebhooks, updatedWebhook]);
              // Experiment: try to break out of the loop & see what happens
            });
        }
      }
    }
  };

  const editWebhookEndpoint = webhook => {
    let webhookIndex = 0;

    for (let i = 0; i < userWebhooks.length; i++) {
      if (userWebhooks[i].path === webhook.path) {
        webhookIndex = i;

        // Get endpoint index
        // endpointIndex = userWebhooks[i].endpoints.indexOf(webhook.endpoint);
      }
    }

    setEditWebhookIndex(webhookIndex);
    // setEditEndpointIndex(endpointIndex);
    setEditMode(true);
    setEditWebhook(webhook);
  };

  const updateWebhook = (webhook, index) => {
    feathersClient
      .service("sonity-account-webhooks")
      .patch(webhook.id, {
        path: webhook.path,
        endpoints: [...webhook.endpoints]
      })
      .then(updatedWebhook => {
        console.log("Updated Webhook: ", updatedWebhook);

        let newUserWebhooks = userWebhooks.filter(
          userWebhook => userWebhook.id !== updatedWebhook.id
        );
        setUserWebhooks([...newUserWebhooks, updatedWebhook]);
      });

    setEditMode(false);
  };

  const deleteWebhook = (webhook, index) => {
    feathersClient
      .service("sonity-account-webhooks")
      .remove(webhook.id)
      .then(deletedWebhook => {
        console.log("Deleted Webhook: ", deletedWebhook);

        let newUserWebhooks = userWebhooks.filter(
          userWebhook => userWebhook.id !== webhook.id
        );
        setUserWebhooks([...newUserWebhooks]);
      });

    setEditMode(false);
  };

  return {
    onDesiredEventsChange,
    deleteEndpoint,
    addNewWebhook,
    editWebhookEndpoint,
    updateWebhook,
    deleteWebhook
  };
};
