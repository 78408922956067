const ActionTypes = {
  set_state: "SET_STATE",
  toggle_confirmation_dialog: "TOGGLE_CONFIRMATION_DIALOG",
  toggle_notifications_dialog: "TOGGLE_NOTIFICATIONS_DIALOG",
  toggle_driver_dialog: "TOGGLE_DRIVER_DIALOG",
  toggle_extract_contacts_dialog: "TOGGLE_EXTRACT_CONTACTS_DIALOG",
  set_task_state: "SET_TASK_STATE",
  set_item_to_delete: "SET_ITEM_TO_DELETE",
  set_text_search: "SET_TEXT_SEARCH",
  set_selected_task_table: "SET_SELECTED_TASK_TASK_TABLE",
  set_completed_times_date: "SET_COMPLETED"
};

export default ActionTypes;
