import gql from "graphql-tag";

export const GET_CAMPAIGN_DEFINITIONS = gql`
  query getCampaignDefinitions($sonity_profile_id: ID, $sonity_account_id: ID) {
    campaign_definitions(
      input: {
        sonity_profile_id: $sonity_profile_id
        sonity_account_id: $sonity_account_id
      }
    ) {
      id
      name
      type
      start_date
      set_active
      set_inmail
      extensions {
        requeue_errors
        requeue_withdrawn
      }
      sonity_profile_id
      campaign_messages {
        id
        step
        delay
        type
        text
        fallback_text
        inmail_subject
        created_at
        updated_at
        campaign_definition_id
        like_post
        skip_message
        complete_time
        extensions
      }
      campaign_chart {
        nodes {
          id
          node_id
        }
        edges {
          id
          edge_id
        }
      }
      search {
        id
        campaign_definition_id
        url
        name
        status
        keywords
        filters
        created_at
        updated_at
      }
      created_at
      updated_at
    }
  }
`;

export const GET_CAMPAIGN_DEFINITIONS_LIST = gql`
  query getCampaignDefinitionsInList($sonity_profile_id: [ID!]!) {
    campaign_definitions_in_list(sonity_profile_id: $sonity_profile_id) {
      id
      name
      type
      start_date
      set_active
      set_inmail
      extensions {
        requeue_errors
        requeue_withdrawn
      }
      sonity_profile_id
      campaign_messages {
        id
        step
        delay
        type
        text
        fallback_text
        inmail_subject
        created_at
        updated_at
        campaign_definition_id
        like_post
        skip_message
        complete_time
        extensions
      }
      search {
        id
        campaign_definition_id
        url
        name
        status
        keywords
        filters
        created_at
        updated_at
      }
      created_at
      updated_at
    }
  }
`;

export const GET_CAMPAIGN_DEFINITIONS_WITH_STATS = gql`
  query getCampaignDefinitions($sonity_profile_id: ID, $sonity_account_id: ID) {
    campaign_definitions(
      input: {
        sonity_profile_id: $sonity_profile_id
        sonity_account_id: $sonity_account_id
      }
    ) {
      id
      name
      type
      start_date
      set_active
      set_inmail
      sonity_profile_id
      sonity_profile {
        id
        email
        status
        contact {
          id
          l_id
          prospect {
            id
            contact_l_id
            profile_name
            profile_description
            profile_location
          }
        }
      }
      stats2 {
        id
        contacts
        requests
        connections
        replied_to_connection_request
        replied_to_other_message
        error
      }
      campaign_messages {
        id
        step
        delay
        type
        text
        fallback_text
        inmail_subject
        created_at
        updated_at
        campaign_definition_id
        like_post
        skip_message
        complete_time
        extensions
      }
      search {
        id
        campaign_definition_id
        url
        name
        status
        keywords
        filters
        created_at
        updated_at
      }
      created_at
      updated_at
    }
  }
`;

export const CAMPAIGN_SEED_DATA = gql`
  {
    campaignSeedData @client {
      id
    }
  }
`;

export const GET_TCP_SUMMARY_TABLE = gql`
  query GetTcpSummaryTable(
    $sonity_profile_id: ID
    $campaign_definition_id: ID
    $campaign_definition_ids: [ID]
    $campaign_definition_names: [String]
    $timeRange: [String]
  ) {
    get_tcp_summary_table(
      sonity_profile_id: $sonity_profile_id
      campaign_definition_id: $campaign_definition_id
      campaign_definition_ids: $campaign_definition_ids
      campaign_definition_names: $campaign_definition_names
      timeRange: $timeRange
    )
  }
`;

export const GET_MEETING_METRICS = gql`
  query GetMeetingMetrics($campaign_definition_ids: [ID!]!) {
    get_meeting_metrics(campaign_definition_ids: $campaign_definition_ids) {
      campaign_definition_ids
      contacts
      requests
      connections
      replied_to_connection_request
      replied_to_other_message
      errors
    }
  }
`;
