import React from "react";

import makeStyles from '@mui/styles/makeStyles';
import {
  Button,
  Divider,
  ListSubheader,
  Menu,
  MenuItem,
} from "@mui/material";
import { useHistory } from "react-router-dom";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import { useMainLayoutContext } from "../../../contexts/mainLayoutState/MainLayoutProvider";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main
  },
  avatar: {
    margin: "0 0.5rem"
  },
  row: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  text: {
    fontWeight: 600,
    color: theme.palette.background.default
  },
  menu: {
    top: "4rem !important"
  }
}));

export default ({}) => {
  const mainLayoutCtx = useMainLayoutContext();
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);


  
  const { selectedSonityProfile, campaignDefinitions } = mainLayoutCtx.state;

  const uniqueCampaignDefinitions = campaignDefinitions
    ? campaignDefinitions.filter(
        (cd, index, self) => index === self.findIndex(t => t.name === cd.name)
      )
    : [];

  //always use uniqueCampaignDefinitions if its defined (is returned fresh from fetch call but delayed)
  //then use selectedSonityProfile.campaign_definitions (possibly stale)  
  //finally fallback on empty array
  let cdsList = uniqueCampaignDefinitions.length > 0 ? uniqueCampaignDefinitions : (selectedSonityProfile ? selectedSonityProfile.campaign_definitions : []);
  
  // Get unique campaign definitions
  const { setSelectedCampaignDefinition } = mainLayoutCtx.stateFunctions;

  const FlowCampaigns = cdsList?.filter(cd => cd.type === "Flow") || [];
  const ConnectorCampaigns = cdsList?.filter(cd => cd.type === "Connector") || [];
  const MessengerCampaigns = cdsList?.filter(cd => cd.type === "Messenger") || [];
  const InMailCampaigns = cdsList?.filter(cd => cd.type === "InMail") || [];
  const AutoAcceptCampaigns = cdsList?.filter(cd => cd.type === "AutoAccept") || [];
  const SchedulePostsCampaigns = cdsList?.filter(cd => cd.type === "SchedulePosts") || [];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const _handleClose = e => {
    setAnchorEl(null);
  };
  const _handleMenuItemClick = (e, cd) => {
    setSelectedCampaignDefinition(cd);
    const cType = cd.type;
    //router
    switch (cType) {
      case "Connector":
        history.push("/connector-campaign/people");
        break;
      case "Messenger":
        history.push("/messenger-campaign/people");
        break;
      case "InMail":
        history.push("/inmail-campaign/people");
        break;
      case "AutoAccept":
        history.push("/auto-accept-campaign/people");
        break;
      case "Flow":
        history.push("/flow-campaign/people");
        break;
      case "SchedulePosts":
        history.push("/schedule-posts-campaign/steps")
        break;
      default:
        console.debug("UNHANDLED TYPE: " + cd.type);
    }
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <Button
        color="inherit"
        disabled={!cdsList || cdsList.length === 0}
        onClick={handleClick}
        endIcon={anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      >
        {`Campaigns`}
      </Button>
      <Menu
        id={`campaign-dropdown-menu`}
        classes={{ paper: classes.menu }}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={_handleClose}
      >
        {FlowCampaigns.length > 0 && (
          <span>
            <ListSubheader> Flow Campaigns </ListSubheader>
            <Divider />
          </span>
        )}
        {cdsList &&
          cdsList
            .filter(cd => cd.type === "Flow")
            .map((cd, index) => (
              <MenuItem
                key={cd.name}
                onClick={event => _handleMenuItemClick(event, cd)}
              >
                {cd.name}
                {/* <EditableCampaignEntry
                  campaignDefinition={cd}
                  updateCampaignDefinition={updateCampaignDefinition}
                  updateCampaignDefinitionResults={
                    updateCampaignDefinitionResults
                  }
                /> */}
              </MenuItem>
            ))}
        {ConnectorCampaigns.length > 0 && (
          <span>
            <ListSubheader> Connector Campaigns </ListSubheader>
            <Divider />
          </span>
        )}
        {cdsList &&
          cdsList
            .filter(cd => cd.type === "Connector")
            .map((cd, index) => (
              <MenuItem
                key={cd.name}
                onClick={event => _handleMenuItemClick(event, cd)}
              >
                {cd.name}
                {/* <EditableCampaignEntry
                  campaignDefinition={cd}
                  updateCampaignDefinition={updateCampaignDefinition}
                  updateCampaignDefinitionResults={
                    updateCampaignDefinitionResults
                  }
                /> */}
              </MenuItem>
            ))}
        {MessengerCampaigns.length > 0 && (
          <span>
            <Divider />
            <ListSubheader> Messenger Campaigns</ListSubheader>
            <Divider />
          </span>
        )}

        {cdsList &&
          cdsList
            .filter(cd => cd.type === "Messenger")
            .map((cd, index) => (
              <MenuItem
                key={cd.name}
                onClick={event => _handleMenuItemClick(event, cd)}
              >
                {cd.name}
                {/* <EditableCampaignEntry
                  campaignDefinition={cd}
                  updateCampaignDefinition={updateCampaignDefinition}
                  updateCampaignDefinitionResults={
                    updateCampaignDefinitionResults
                  }
                /> */}
              </MenuItem>
            ))}
        {InMailCampaigns.length > 0 && (
          <span>
            <Divider />
            <ListSubheader> InMail Campaigns </ListSubheader>
            <Divider />
          </span>
        )}

        {cdsList &&
          cdsList
            .filter(cd => cd.type === "InMail")
            .map((cd, index) => (
              <MenuItem
                key={cd.name}
                onClick={event => _handleMenuItemClick(event, cd)}
              >
                {cd.name}
                {/* <EditableCampaignEntry
                  campaignDefinition={cd}
                  updateCampaignDefinition={updateCampaignDefinition}
                  updateCampaignDefinitionResults={
                    updateCampaignDefinitionResults
                  }
                /> */}
              </MenuItem>
            ))}
        {AutoAcceptCampaigns.length > 0 && (
          <span>
            <Divider />
            <ListSubheader> AutoAccept Campaigns </ListSubheader>
          </span>
        )}
        {cdsList &&
          cdsList
            .filter(cd => cd.type === "AutoAccept")
            .map((cd, index) => (
              <MenuItem
                key={cd.name}
                onClick={event => _handleMenuItemClick(event, cd)}
              >
                {cd.name}
                {/* <EditableCampaignEntry
                  campaignDefinition={cd}
                  updateCampaignDefinition={updateCampaignDefinition}
                  updateCampaignDefinitionResults={
                    updateCampaignDefinitionResults
                  }
                /> */}
              </MenuItem>
            ))}


{SchedulePostsCampaigns.length > 0 && (
          <span>
            <Divider />
            <ListSubheader> Schedule Posts Campaigns</ListSubheader>
            <Divider />
          </span>
        )}

        {cdsList &&
          cdsList
            .filter(cd => cd.type === "SchedulePosts")
            .map((cd, index) => (
              <MenuItem
                key={cd.name}
                onClick={event => _handleMenuItemClick(event, cd)}
              >
                {cd.name}
                {/* <EditableCampaignEntry
                  campaignDefinition={cd}
                  updateCampaignDefinition={updateCampaignDefinition}
                  updateCampaignDefinitionResults={
                    updateCampaignDefinitionResults
                  }
                /> */}
              </MenuItem>
            ))}
      </Menu>
    </div>
  );
};
