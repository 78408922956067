import { GET_TASKS, GET_TASKS_TABLE } from "../gql/queries/tasks";
import { CREATE_TASK, UPDATE_TASK, DELETE_TASK, REGENERATE_TASK } from "../gql/mutations/Tasks";

import { useMainLayoutContext } from "../contexts/mainLayoutState/MainLayoutProvider";

import { useLazyQuery, useMutation } from "@apollo/client";
import React from "react";


const useTasks = (ssp, um, selectedCompletedTimesState) => {

  const mainLayoutCtx = useMainLayoutContext();




  let { selectedSonityProfile, eventSubs } = mainLayoutCtx.state;
  const [
    message,
    { updateMessage, hideMessage }
  ] = mainLayoutCtx.useMessageHook;

  const [tasksTable, setTasksTable] = React.useState([]);



  const [getTasksTable, tasksTableData] = useLazyQuery(GET_TASKS_TABLE, {
    fetchPolicy: "network-only",
    errorPolicy: "ignore",
    onCompleted: data => {
      let allTasks = data.tasks_table;
      setTasksTable(allTasks);
    },
    onError: (error) => {
      console.log("[getTasksTable]error", error);
    }
  });

  React.useEffect(() => {
    if (selectedCompletedTimesState && selectedSonityProfile) {
      getTasksTable({
        variables: {
          input: {
            sonity_profile_id: parseInt(selectedSonityProfile?.id),
            completed_time: selectedCompletedTimesState
          }
        }
      });
    }
  }, [selectedSonityProfile, selectedCompletedTimesState]);

  React.useEffect(() => {

    if (eventSubs && eventSubs.type && eventSubs.type === "TASK_UPDATED") {

      const update = { ...eventSubs.payload };
      if (typeof update.updated_at === 'string') update.updated_at = new Date(update.updated_at);
      if (typeof update.created_at === 'string') update.created_at = new Date(update.created_at);

      setTasksTable(oldTasksTable => {
        return oldTasksTable.map(t => {
          if (t.id === update.id) {

            if (t.campaign_name)
              update.campaign_name = t?.campaign_name

            return update
          }
          return t;
        });
      });
    }
    if (eventSubs && eventSubs.type && eventSubs.type === "TASKS_CREATED") {
      console.log("HANDLE_TASKS_CREATED")
    }
    if (eventSubs && eventSubs.type && eventSubs.type === "TASKS_UPDATED") {
      console.log("HANDLE_TASKS_UPDATED")
    }
  }, [eventSubs]);

  const [createTask, createTaskResult] = useMutation(CREATE_TASK, {
    onCompleted: ({ create_task }) => {
      if (create_task.errors && updateMessage) {
        updateMessage({
          isShown: true,
          variant: "error",
          content:
            "There was a problem creating the task associated with this queued msg."
        });
      }

      if (create_task && updateMessage) {
        updateMessage({
          isShown: true,
          variant: "success",
          content: "Task was created successfully."
        });
      }
      /*const updatedCampaigns = updateCampaignStatus(
            campaignsWithChats,
            update_tracking_campaign_prospect_table_status
          );
          setCampaignsWithChats(updatedCampaigns);*/
    }
  });

  const [updateTask, updateTaskData] = useMutation(UPDATE_TASK, {
    onCompleted: ({ update_task }) => {
      if (update_task.errors && updateMessage) {
        updateMessage({
          isShown: true,
          variant: "error",
          content: "There was a problem updating this task."
        });
      } else {
        setTasksTable(oldTasks => {
          const taskIndex = oldTasks.findIndex(t => t.id == update_task.id);

          return [
            ...oldTasks.slice(0, taskIndex),
            {
              ...oldTasks[taskIndex],
              ...update_task
            },
            ...oldTasks.slice(taskIndex + 1)
          ];
        });

        if (updateMessage)
          updateMessage({
            isShown: true,
            variant: "success",
            content: "Task has been successfully updated."
          });

      }
    }
  });

  const [regenerateTask, regenerateTaskData] = useMutation(REGENERATE_TASK, {
    onCompleted: (data) => {
      const { regenerate_task } = data;
      if (regenerate_task.errors && updateMessage) {
        updateMessage({
          isShown: true,
          variant: "error",
          content: "There was a problem regenerating this task."
        });
      }
      if (updateMessage)
        updateMessage({
          isShown: true,
          variant: "success",
          content: "Task has been successfully regenerated."
        });


    }
  });

  //DELETE TASK (THE QUEUED ONE)
  const [deleteTask, deleteTaskData] = useMutation(DELETE_TASK, {
    //refetchQueries: ["GetTasksTable"],
    onCompleted: ({ delete_task }) => {
      updateMessage({
        isShown: true,
        variant: "success",
        content: "Task has been successfully deleted."
      });
      //setQueuedMessage(null)
      /*const updatedCampaigns = updateCampaignStatus(
            campaignsWithChats,
            update_tracking_campaign_prospect_table_status
            );
            setCampaignsWithChats(updatedCampaigns);*/
    },
    onError: ({ error }) => {
      console.log("errr", error);
    }
  });

  //GET QUEUED TASKS
  //cant use query operations 1 OR 3
  const [getTasks, getTasksResult] = useLazyQuery(GET_TASKS, {
    fetchPolicy: "network-only",
    onCompleted: ({ tasks }) => {
      console.log("[useTasks]getTasks -- what to do here?", { tasks })
    }
  });

  return [
    {
      regenerateTaskData,
      updateTaskData,
      deleteTaskData,
      createTaskResult,
      getTasksResult,
      tasksTableData,
      tasksTable,
    },
    {

      setTasksTable,
      getTasksTable,
      getTasks,
      updateTask,
      createTask,
      deleteTask,
      regenerateTask
    }
  ];
};

export { useTasks };
