const drawerMenus = [
  {
    name: "home",
    title: "Home",
    destination: "/home"
  },
  {
    name: "profile",
    title: "Profiles",
    destination: "/profiles"
  },

  {
    name: "chats",
    title: "Chats",
    destination: "/campaigns-chats"
  },
  {
    name: "reports",
    title: "Reports",
    destination: "/reports"
  },
  {
    name: "list_manager",
    title: "List Manager",
    destination: "/list-manager"
  },
  {
    name: "faq",
    title: "FAQ",
    destination: ""
  },
  {
    name: "logout",
    title: "Logout",
    destination: "/logout"
  }
];

export default drawerMenus;
