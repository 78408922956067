import React from "react";
import { Link, useHistory } from "react-router-dom";
import { signoutUserLocal } from "../../../../attach-client";
import InitialState from "../../../../contexts/mainLayoutState/mainLayoutInitialState";
import menuIcons from "./menuIcons";
import { SonityLocalStore } from "../../../../utils/sonityLocalStore";
import { Divider, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useMainLayoutContext } from "../../../../contexts/mainLayoutState/MainLayoutProvider";
import { width } from "@mui/system";


export function UserProfile({ id, image, name, email, onClick, hideButton }) {
  return (
    <button
      type="submit"
      style={{
        paddingBottom: "0.7rem",
        paddingTop: "0.7rem"
      }}

      className={`text-left gap-2 bg-white-500 text-sm text-gray-700 ${!hideButton ? "hover:bg-gray-50" : ""
        }`}
      role="menuitem"
    >
      <div onClick={() => onClick()} className="flex items-start gap-x-2  ">
        <img
          style={{
            width: "2.5rem",
            height: "2.5rem"
          }}
          className="object-cover rounded-full"
          src={image}
          alt=""
        />

        <div className="ml-2">
          <span className="text-left font-semibold text-gray-700 capitalize dark:text-white">
            {name}
          </span>

          <div

            className="text-sm text-gray-500 dark:text-gray-400 w-32 overflow-hidden text-ellipsis"
          >
            {email}
          </div>
        </div>
      </div>
    </button>
  );
}

export function SonityProfileSelector({
  selectedSonityProfile,
  sonityProfiles,
  setSelectedSonityProfile
}) {
  const [showList, setShowList] = React.useState(false);

  const getSelectedProfile = () => {
    if (selectedSonityProfile) {
      let name = selectedSonityProfile.contact
        ? [
          selectedSonityProfile.contact?.first_name,
          selectedSonityProfile.contact?.last_name
        ].join(" ")
        : "LinkedIn Profile";
      let image = selectedSonityProfile.profile_image
        ? selectedSonityProfile.profile_image?.thumb
        : "https://loremflickr.com/320/240";
      return (
        <UserProfile
          id={selectedSonityProfile.id}
          name={name}
          image={image}
          email={selectedSonityProfile.email}
          onClick={() => setShowList(!showList)}
          hideButton={true}
        />
      );
    }

    return <div>Select Sonity Profile</div>;
  };

  const onProfileSelect = sp => {
    setSelectedSonityProfile(sp);
    setShowList(false);
  };

  return (
    <div className="relative" style={{ marginTop: "3.7rem" }}>
      <div
        className="inline-flex items-center w-full divide-x divide-gray-100 overflow-hidden border-b border-gray-300 bg-white"
        onClick={() => setShowList(!showList)}
      >
        <a
          href="#"
          className="mt-6 text-sm w-full leading-none text-gray-600 hover:bg-gray-50 hover:text-gray-700"
        >
          {getSelectedProfile()}
        </a>

        {!selectedSonityProfile && (
          <button className="h-full pt-4 text-gray-600 hover:bg-gray-50 hover:text-gray-700" style={{
            paddingTop: "0.7rem",
            paddingBottom: "0.7rem"
          }}>
            <span className="sr-only">Menu</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        )}
      </div>

      {showList && (
        <div
          className="absolute w-full sm:w-auto md:right-0 z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
        >
          <div style={{ background: "white" }}>
            {sonityProfiles.map((sp, index) => {
              console.log(sp);
              let name = sp.contact
                ? [sp.contact?.first_name, sp.contact?.last_name].join(" ")
                : "LinkedIn Profile";
              let image = sp.profile_image
                ? sp.profile_image?.thumb
                : "https://loremflickr.com/320/240";
              return (
                <div>
                  <UserProfile
                    key={index}
                    id={sp.id}
                    name={name}
                    image={image}
                    email={sp.email}
                    onClick={() => onProfileSelect(sp)}
                  />
                  <Divider />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export function NavMenu({
  name,
  title,
  disableMenu,
  destination,
  handleClickMenu
}) {
  let history = useHistory();
  const mainLayoutCtx = useMainLayoutContext();

  const routeToPage = async destination => {
    if (destination) {
      if (name === "logout") {
        // Signout

        try {
          await signoutUserLocal();
          history.push("/login");
          mainLayoutCtx.stateFunctions.clearState()
          let contextStore = new SonityLocalStore()
          contextStore.clearContexts()
        } catch (err) {
          console.log("Logout error: ", err)
        }

      } else {
        history.replace(destination);
      }

      handleClickMenu()
    }
  };

  return (
    <button
      disabled={disableMenu}
      type="submit"
      className={`flex bg-white-500 w-full items-center gap-2 px-4 py-2 text-sm text-gray-700 ${!disableMenu ? "hover:bg-gray-50" : ""
        }`}
      role="menuitem"
      onClick={() => routeToPage(destination)}
    >
      {menuIcons[name]}
      {title}
    </button>
  );
}

export function CampaignSelector({
  campaignDefinitions,
  selectedCampaignDefinition,
  setSelectedCampaignDefinition,
  handleCampaignClick
}) {
  const history = useHistory();

  const handleOnChange = campaignId => {
    let campaignDefinition = campaignDefinitions.find(
      cd => cd.id == campaignId
    );
    setSelectedCampaignDefinition(campaignDefinition);

    switch (campaignDefinition.type) {
      case "Connector":
        history.push("/connector-campaign/people");
        break;
      case "Messenger":
        history.push("/messenger-campaign/people");
        break;
      case "InMail":
        history.push("/inmail-campaign/people");
        break;
      case "AutoAccept":
        history.push("/auto-accept-campaign/people");
        break;
      case "Flow":
        history.push("/flow-campaign/people");
        break;//
      default:
        console.log("UnhandledType", campaignDefinition.type);
    }

    if (handleCampaignClick) handleCampaignClick()
  };

  if (campaignDefinitions.length == 0) return <></>;


  let flowCampaigns = campaignDefinitions.filter(cd => cd.type === "Flow")
  let messengerCampaigns = campaignDefinitions.filter(cd => cd.type === "Messenger")
  let connectorCampaigns = campaignDefinitions.filter(cd => cd.type === "Connector")
  let inMailCampaigns = campaignDefinitions.filter(cd => cd.type === "InMail")
  let autoAcceptCampaigns = campaignDefinitions.filter(cd => cd.type === "AutoAccept")


  const CampaignHead = ({ showHeader, headName }) => {
    return (
      <div>
        {showHeader && <option
          style={{ fontWeight: "bold" }}
          className="font-bold text-sm"
          disabled
        >
          {headName}
        </option>}
      </div>
    )
  }




  return (
    <div style={{ margin: "3% 8% 3% 3%" }}>


      <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: "100%" }}>
        <InputLabel id="campaign-definitions-selector-label">Campaign Definitions</InputLabel>
        <Select
          labelId="campaign-definitions-selector-label"
          id="campaign-definitions-selector"
          value={selectedCampaignDefinition && selectedCampaignDefinition.id ? selectedCampaignDefinition.id : 0}
          label="Campaign Definitions"
          onChange={event => handleOnChange(event.target.value)}
        >
          <MenuItem value="0">
            <em>Select Campaign Definition</em>
          </MenuItem>


          <option
            style={{ fontWeight: "bold" }}
            className="font-bold text-sm"
            disabled
          >
            Flow Campaigns
          </option>

          <CampaignHead
            headName="Flow Campaigns"
            showHeader={flowCampaigns.length > 0}
          />

          {flowCampaigns.map(cd => {
            return (
              <MenuItem key={cd.id} value={cd.id}>
                {cd.name}
              </MenuItem>
            );
          })}


          <option
            style={{ fontWeight: "bold" }}
            className="font-bold text-sm"
            disabled
          >
            Connector Campaigns
          </option>


          <CampaignHead
            headName="Connector Campaigns"
            showHeader={connectorCampaigns.length > 0}
          />


          {connectorCampaigns.map(cd => {
            return (
              <MenuItem key={cd.id} value={cd.id}>
                {cd.name}
              </MenuItem>
            );
          })}


          <option
            style={{ fontWeight: "bold" }}
            className="font-bold text-sm"
            disabled
          >
            Messenger Campaigns
          </option>

          <CampaignHead
            headName="Messenger Campaigns"
            showHeader={messengerCampaigns.length > 0}
          />

          {messengerCampaigns.map(cd => {
            return (
              <MenuItem key={cd.id} value={cd.id}>
                {cd.name}
              </MenuItem>
            );
          })}


          <CampaignHead
            headName="InMail Campaigns"
            showHeader={inMailCampaigns.length > 0}
          />

          {inMailCampaigns.map(cd => {
            return (
              <MenuItem key={cd.id} value={cd.id}>
                {cd.name}
              </MenuItem>
            );
          })}

          <CampaignHead
            headName="AutoAccept Campaigns"
            showHeader={autoAcceptCampaigns.length > 0}
          />

          {autoAcceptCampaigns.map(cd => {
            return (
              <MenuItem key={cd.id} value={cd.id}>
                {cd.name}
              </MenuItem>
            );
          })}
        </Select>



      </FormControl>
    </div>
  );
}
