import React, { useEffect, useState } from "react";

import {
  CAMPAIGN_SEED_DATA,
  GET_TCP_SUMMARY_TABLE
} from "../gql/queries/CampaignDefinitions";
import { useLazyQuery } from "@apollo/client";

export const SUPPORTED_EVENTS = [
  "requests",
  "connected",
  "errors",
  "welcome_messages",
  "campaign_messages",
  "send_inmail",
  "inmail_accepted",
  "inmail_denied",
  "replied_to_invite",
  "replied_to_other",
  "liked_post",
  "followed",
  "endorsement",
  "talking",
  "closed"
];

/* Reports section is a selector for SPs and their subsequent available CDs */
export const useReports = () => {
  const [getTcpSummaryTable, getTcpSummaryTableResults] = useLazyQuery(
    GET_TCP_SUMMARY_TABLE,
    {
      fetchPolicy: "no-cache",
      onCompleted: data => {
        const { get_tcp_summary_table } = data;
        console.log("summary table rows: ", JSON.parse(get_tcp_summary_table));
      }
    }
  );

  console.log("Get Table Summary Results: ", getTcpSummaryTableResults);

  // React.useEffect(() => {
  //   console.log("useReports loaded");
  //   if (selectedCampaignDefinition) {
  //     getTcpSummaryTable({
  //       variables: {
  //         campaign_definition_ids: [selectedCampaignDefinition.id],
  //         timeRange: ["herb", "derp"]
  //       }
  //     });
  //   }
  // }, [selectedCampaignDefinition]);

  return [
    {
      getTcpSummaryTable
    },
    {
      getTcpSummaryTableResults
    }
  ];
};

export const generateCubeQueries = (
  chartNames,
  sonityProfiles,
  campaignDefinitions
) => {
  const cubeQueryObjects = {};

  chartNames.map((chartName, index) => {
    // console.log("Generating cubequery for " + chartName);
    // console.log(sonityProfiles);
    let filters =
      sonityProfiles.length > 0
        ? [
            {
              member:
                chartName != "Events"
                  ? `SonityProfiles.email`
                  : `Events.sonityProfileEmail`,
              operator: "equals",
              values: sonityProfiles.map(sp => sp.email)
            },
            {
              member:
                chartName != "Events"
                  ? `CampaignDefinitions.name`
                  : "Events.campaignDefinitionName",
              operator: "equals",
              values: campaignDefinitions.map(cd => cd.name)
            }
          ]
        : [];

    let timeDimensions = [
      {
        dimension: `${chartName}.createdAt`,
        // dateRange: [sliderStartDate, `${mm}-${dd}-${yyyy}`],  left null it returns whole data set, set dateRange after initial load
        granularity: "day" // only granularity can change
      }
      // timeDimensions
    ];

    let dimensions = [`${chartName}.email`];

    if (chartName === "Events") {
      dimensions = [`${chartName}.type`];
      // filters.push()
      // filters = {
      //   member: "Events.type",
      //   operator: "notEquals",
      //   values: ["undetermined"]
      // }
      filters.push({
        member: "Events.type",
        operator: "equals",
        values: SUPPORTED_EVENTS
      });
      console.log("Events chart filters: ", filters);
    }

    if (chartName === "TrackingCampaignProspects") {
      dimensions = [`${chartName}.status`];
      filters = [
        {
          member: "TrackingCampaignProspects.campaign_definition_name",
          operator: "equals",
          values: campaignDefinitions.map(cd => cd.name)
        },
        {
          member: "TrackingCampaignProspects.sonity_profile_email",
          operator: "equals",
          values: sonityProfiles.map(sp => sp.email)
        }
      ];
      timeDimensions = [];
    }

    const newObject = {
      measures: [`${chartName}.count`],
      timeDimensions: timeDimensions,
      order: {
        [`${chartName}.count`]: "desc"
      },
      dimensions: dimensions,
      filters: filters
    };

    //console.log(`${chartName} Query: `, newObject);

    cubeQueryObjects[chartName] = newObject;
  });

  return cubeQueryObjects;
};

export const generatePivotConfigs = chartNames => {
  const pivotConfigs = {};

  chartNames.map((chartName, index) => {
    let x = [`${chartName}.email`, `${chartName}.createdAt.day`];
    let y = ["measures"];

    if (chartName == "Events") {
      x = ["Events.createdAt.day"];
      y = ["Events.type", "measures"];
    }
    pivotConfigs[chartName] = {
      x: x,
      y: y,
      fillMissingDates: true,
      joinDateRange: false
    };
  });

  console.log("AllPivotConfigs: ", pivotConfigs);

  return pivotConfigs;
};

export const getChartFirstDate = async (chartName, cubejsApi, cubeQuery) => {
  // MULTIGRAPH => In the meantime we dont need to touch this
  let resultSet = await cubejsApi?.load(cubeQuery);

  if (!resultSet.loadResponse.results[0].data) return null;

  console.log("Set timerange here!");
  let data = resultSet.loadResponse.results[0].data.sort(
    (a, b) =>
      new Date(a[`${chartName}.createdAt`]) -
      new Date(b[`${chartName}.createdAt`])
  );
  console.log(data);

  let firstRecordCreatedAt =
    data.length > 0 ? data[0][`${chartName}.createdAt`] : null;

  if (
    firstRecordCreatedAt != null &&
    firstRecordCreatedAt != undefined &&
    firstRecordCreatedAt != ""
  ) {
    return firstRecordCreatedAt;
  }

  return null;
};

export const changeChartTimeDimensions = (cubeQuery, startDate, endDate) => {
  let newTimeDimesions = cubeQuery?.timeDimensions
    ? cubeQuery.timeDimensions
    : [];

  if (newTimeDimesions && newTimeDimesions.length > 0) {
    newTimeDimesions[0].dateRange = [startDate, endDate];

    return {
      ...cubeQuery,
      timeDimensions: newTimeDimesions
    };
  }
};

export const formatDate = timestamp => {
  var d = new Date(timestamp),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  let finalDate = [year, month, day].join("-");

  console.log(finalDate);

  return finalDate;
};

let processTimeDimensions = cubes => {
  let dimensions = [];

  cubes.map(cube => {
    cube.dimensions.map(dimension => {
      dimensions.push({
        cubeName: cube.name,
        cubeTitle: cube.title,
        ...dimension
      });
    });
  });

  let finalDimensions = dimensions.filter(
    dim =>
      dim.name.toLowerCase().includes("createdat") ||
      dim.name.toLowerCase().includes("updatedat") ||
      dim.name.toLowerCase().includes("lastrepliedat")
  );

  return finalDimensions;
};

export const getCubeMeta = async cubejsApi => {
  // MULTIGRAPH => We dont need to touch this
  const meta = await cubejsApi.meta();
  let processedTime = processTimeDimensions(meta.cubes);

  return {
    meta: meta,
    processedTimeDimensions: processedTime
  };
};
