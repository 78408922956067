import gql from "graphql-tag";


export const UPSERT_USER = gql`
  mutation upsertUser($input: UserInput) {
    upsert_user(input: $input) {
      id
      email
      user_status_id
      user_role_id
      sonity_account_id
      selenium_endpoint
      extensions
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($input: UserInput) {
    update_user(input: $input) {
      id
      email
      user_status_id
      user_role_id
      sonity_account_id
      selenium_endpoint
      extensions
    }
  }
`;


