import { createTheme } from "@mui/material/styles";
// import WorkSansRegular from "./fonts/workSans/WorkSans-Regular.ttf"

export const darkTheme = createTheme({
  palette: {
    mode: "dark"
  }
});

export const lightTheme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      root:  {
        '&$focused $notchedOutline': {
          boxShadow: 'none !important', // Remove the box-shadow on focus with !important
        },
      },
    },
    MuiInputLabel: {
      root: {
        //top: '-0.5rem',
      }
    }
  },
  palette: {
    mode: "light",
    primary: {
      // light: "#EAEBF3",
      main: "#28347E"
      // dark: "#EDF0F7"
    },
    secondary: {
      main: "#ee1d57ff"
    },
    success: {
      main: "#6FB85E"
    },
    error: {
      main: "#ee2e3cff"
    },
    warning: {
      main: "##E96A47"
    }
  },
  typography: {
    fontFamily: ["Open Sans", "WorkSans", "Rubik"].join(",")
  }
});
