import gql from "graphql-tag";

export const GET_COMMUNICATION_STATS = gql`
  query getCommunicationStats(
    $initiator_l_id: String!
    $sonity_profile_id: ID!
  ) {
    get_communication_stats(
      initiator_l_id: $initiator_l_id
      sonity_profile_id: $sonity_profile_id
    ) {
      connection_requests
      connected
      replied_to_connection
      replied_to_other
    }
  }
`;

export const GET_RECENTLY_COMPLETED_TASKS = gql`
  query GetRecentlyCompletedTasks($input: TasksInput) {
    get_recently_completed_tasks(input: $input) {
      id
      type
      campaign_definition_id
      campaign_name
      sonity_profile_id
      sonity_profile_email
      task_status
      task_status_id
      target_l_id
      tracking_campaign_prospect_id
      profile_name
      payload {
        name
        url
        error
        visit_max
        delay
        message_type
        message_content
        fallback_message
        inmail_subject
        thread_id
        salesnav_thread_id
        file_path
        isMessenger
        list_id
        add_to_list
        like_post
      }
      campaign_step
      message_step
      created_at
      updated_at
    }
  }
`;

export const GET_TASKS = gql`
  query GetTasks($input: TasksInput) {
    tasks(input: $input) {
      id
      type
      campaign_definition_id
      campaign_name
      sonity_profile_id
      sonity_profile_email
      paused
      task_status
      task_status_id
      target_l_id
      contact {
        id
        l_id
        email
        prospect {
          id
          profile_name
          profile_description
          profile_location
          profile_company
          profile_current_position
        }
      }
      tracking_campaign_prospect {
        id
        l_id
        tcp_placeholder {
          id
          first_name
          middle_name
          last_name
          position
          title
          location
          company
          tracking_campaign_prospect_id
          welcome_message
          connection_message
          message_x
          created_at
          updated_at
        }
      }
      profile_name
      payload {
        name
        url
        error
        visit_max
        delay
        message_type
        message_content
        fallback_message
        inmail_subject
        thread_id
        salesnav_thread_id
        file_path
        isMessenger
        list_id
        add_to_list
        like_post
      }
      campaign_step
      message_step
      created_at
      updated_at
    }
  }
`;

export const GET_TASKS_TABLE = gql`
  query GetTasksTable($input: TasksInput) {
    tasks_table(input: $input) {
      id
      type
      campaign_definition_id
      campaign_name
      sonity_profile_id
      sonity_profile_email
      tracking_campaign_prospect_id
      paused
      task_status_id
      target_l_id
      tracking_campaign_prospect {
        id
        l_id
        contact {
          id
          l_id
          email
        }
        prospect {
          id
          contact_l_id
          profile_name
          profile_description
          profile_location
          profile_company
          profile_current_position
        }
        tcp_placeholder {
          id
          first_name
          middle_name
          last_name
          position
          title
          location
          company
          tracking_campaign_prospect_id
          welcome_message
          connection_message
          message_x
          created_at
          updated_at
        }
      }
      profile_name
      payload {
        name
        url
        error
        visit_max
        delay
        complete_time
        message_type
        message_content
        fallback_message
        inmail_subject
        thread_id
        salesnav_thread_id
        file_path
        isMessenger
        list_id
        add_to_list
        like_post
        complete_time
        post_event_end
        post_event_name
        post_s3filename
        post_description
        post_event_start
      }
      campaign_step
      message_step
      created_at
      updated_at
    }
  }
`;

export const GET_ALL_ACTIVITY_LOGS = gql`
  query getAllActivityLogs($input: ActivtyLogsInput!) {
    activity_logs(input: $input) {
      time
      message
    }
  }
`;
