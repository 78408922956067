
import { useLazyQuery, useMutation } from "@apollo/client";
import { UPDATE_USER, UPSERT_USER } from "../gql/mutations/Users";

const useUsers = () => {
  const [updateUser, updateUserResults] = useMutation(UPDATE_USER, {
    fetchPolicy: "network-only",
    onCompleted: data => {

    }
  });

  const [upsertUser, upsertUserResults] = useMutation(UPSERT_USER, {
    fetchPolicy: "network-only",
    onCompleted: data => {
    }
  });

  return [
    {
      upsertUserResults,
      updateUserResults
    },
    {
      upsertUser,
      updateUser
    }
  ];
};

export { useUsers };
