const InitialState = {
  selectedSonityProfile: null,
  selectedCampaignDefinition: null,
  textSearch: "",
  itemToDelete: null,
  messageType: "",
  selectedTaskType: "upcomingTasks",
  selectedCompletedTimesState: "1 Day",
  allowableCompletedTypes: ["All"],
  selectedCompletedTypeState: "All",
  confirmationDialogOpen: false,
  notificationsDialogOpen: false,
  driverModalOpened: false,
  faqModalOpen: false,
  isExtractContactsDialogOpen: false,
  tasksState: {
    upcomingTasks: [],
    completedTasks: [],
    blockedTasks: [],
    errorTasks: []
  }
};

export default InitialState;
