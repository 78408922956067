import gql from "graphql-tag";

export const GET_TABLE_STATUSES = gql`
  {
    campaign_statuses {
      id
      status_flag
      label
    }
  }
`;

export const GET_MESSAGE = gql`
  {
    message @client {
      isShown
      content
      variant
    }
  }
`;

export const UPDATE_MESSAGE = gql`
  mutation UpdateMessage($updatedMessage: Message) {
    updateMessage(updatedMessage: $updatedMessage) @client
  }
`;
export const GET_PROSPECTS = gql`
  query GetProspects {
    tracking_campaign_prospects {
      id
      campaign_status {
        id
        status_flag
        label
      }
      prospect {
        id
        first_name
        profile_name
        last_name
        age
        profile_company
        profile_location
        profile_current_position
      }
    }
  }
`;

export const UPDATE_CONTACT_STATUS = gql`
  mutation UpdateContactStatus($id: ID!, $new_status_id: ID) {
    update_tracking_campaign_prospect_table_status(
      id: $id
      new_status_id: $new_status_id
    ) {
      name
      company
      prospect_id
    }
  }
`;

export const GET_CONTACTS_PAGINATED = gql`
  query GetContactsPaginated($text_searches: [String]) {
    tracking_campaign_prospects_table(
      input: {
        limit: 100
        offset: 0
        campaign_definition_id: 1
        text_searches: $text_searches
      }
    ) {
      count
      rows {
        id
        prospect_id
        contact_id
        thumb
        name
        company
        location
        status
      }
    }
  }
`;
