import { SonityLocalStore, contextsList } from "../../utils/sonityLocalStore";


const sonityLocalStore = new SonityLocalStore()


const initialState = {
  selectedPeople: [],
  newCampaignName: null,
  loading: false,
  error: false
};


const storedState = sonityLocalStore.getContextState(contextsList.tcpsContext)



export default {
  ...initialState,
  ...storedState
}
