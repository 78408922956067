import gql from "graphql-tag";

export const CREATE_TASK = gql`
  mutation create_task($input: TasksInput!) {
    create_task(input: $input) {
      id
      task_status_id
      paused
      type
      target_l_id
      payload {
        message_type
        message_content
        delay
        thread_id
        complete_time
      }
    }
  }
`;

export const DELETE_TASK = gql`
  mutation delete_task($id: ID! $sonity_account_id:ID $sonity_profile_id:ID) {
    delete_task(id: $id sonity_account_id:$sonity_account_id sonity_profile_id:$sonity_profile_id) {
      num_modified
    }
  }
`;

export const UPDATE_TASK = gql`
  mutation update_task($input: TasksInput!) {
    update_task(input: $input) {
      id
      task_status_id
      paused
      type
      target_l_id
      payload {
        message_type
        message_content
        delay
        complete_time
        post_description
        post_event_end
        post_event_name
        post_event_start
        post_s3filename
      }
    }
  }
`;

export const REGENERATE_TASK = gql`
  mutation regenerate_task($input: TasksInput!) {
    regenerate_task(input: $input) {
      id
      task_status_id
      paused
      type
      target_l_id
      payload {
        message_type
        message_content
        delay
        complete_time
      }
    }
  }
`;