import { SonityLocalStore, contextsList } from "../../utils/sonityLocalStore";
import actionTypes from "./TcpsActionTypes";

let sonityLocalStore = new SonityLocalStore()

const tcpsReducer = (state, action) => {

  console.log("[TCPs  Reducer]: ", {
    state,
    action
  });

  let newState = state;


  switch (action.type) {
    case actionTypes.set_state:
      newState = {
        ...state,
        ...action.payload
      };
      break
    case actionTypes.set_selected_people:
      newState = {
        ...state,
        selectedPeople: action.payload
      };
      break
    case actionTypes.set_new_campaign_name:
      newState = {
        ...state,
        newCampaignName: action.payload
      };
      break
    case actionTypes.set_loading_mode:
      newState = {
        ...state,
        loading: action.payload
      };
      break
    case actionTypes.set_error_mode:
      newState = {
        ...state,
        error: action.payload
      };
      break
    default:
      newState =  state;
      break;
  }

  sonityLocalStore.storeContextState(contextsList.tcpsContext, newState)
  return newState;
};

export default tcpsReducer;
