import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { GET_COMMUNICATION_STATS } from "../gql/queries/tasks";

import { useMainLayoutContext } from "../contexts/mainLayoutState/MainLayoutProvider";

const useStatistics = () => {
  const mainCtx = useMainLayoutContext();
  const { selectedSonityProfile } = mainCtx.state;
  const {
    loading: statsLoading,
    error: statsError,
    data: statsData
  } = useQuery(GET_COMMUNICATION_STATS, {
    fetchPolicy: "network-only",
    variables: {
      initiator_l_id: selectedSonityProfile?.contact?.l_id ? selectedSonityProfile?.contact?.l_id : null,
      sonity_profile_id: selectedSonityProfile ? selectedSonityProfile.id : null
    }
  });

  return [
    {
      statsData,
      statsLoading,
      statsError
    },
    {}
  ];
};

export { useStatistics };
