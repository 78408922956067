import React, { useEffect, useState } from "react";

import { GET_TRACKING_CAMPAIGN_PROSPECT, GET_TRACKING_CAMPAIGN_PROSPECTS, GET_TRACKING_CAMPAIGN_PROSPECTS_2 } from "../gql/queries/TrackingCampaignProspects";

import {
  BULK_CLONE_TO_CAMPAIGN,
  DELETE_TRACKING_CAMPAIGN_PROSPECT,
  DELETE_TRACKING_CAMPAIGN_PROSPECTS,
  REVERT_TRACKING_CAMPAIGN_PROSPECTS_TABLE_STATUS,
  UPDATE_TRACKING_CAMPAIGN_PROSPECT_TABLE_STATUS,
  UPDATE_TRACKING_CAMPAIGN_PROSPECTS_TABLE_STATUS,
  UPDATE_TRACKING_CAMPAIGN_PROSPECT,
  UPDATE_TRACKING_CAMPAIGN_PROSPECTS,
  BULK_SEARCH_RESULT_CREATE,
  CREATE_TRACKING_CAMPAIGN_PROSPECTS,
  DELETE_TCP_FROM_CAMPAIGNS,
  REQUEUE_WITHDRAWN
} from "../gql/mutations/TrackingCampaignProspects";

import { useLazyQuery, useMutation } from "@apollo/client";
import log from "loglevel";



/**
 * @name useTcps
 * @type component
 * @desc A hook that allows you query, mutate and manipulate prospects
 * @prop {Object} selectedSonityProfile - Current selected profile
 * @prop {Object} selectedCampaignDefinition - Current selected campaign
 * @prop {Function} updateMessage - A function to display snackbar
 * @prop {Function} setSelectedPeople - A state function for setting selected people
 * @prop {Function} setLoading - Sets loading to either true or false
 * @prop {*} textFilters - Text filters 
 * @prop {*} statusFilters - Status filters 
 * @prop {*} allFilters - All combined filters 
 * @prop {*} campaignDefinitionFilter - A campaign filter
 * @prop {Object} mainLayoutCtx - Main Layout Context
* @returns An object of data and functions to query, mutate and manipulate prospects
 */


export const useTcps = (
  selectedSonityProfile,
  selectedCampaignDefinition,
  updateMessage,
  setSelectedPeople,
  setLoading,
  textFilters,
  statusFilter,
  allFilters,
  campaignDefinitionFilter,
  mainLayoutCtx
) => {

  const { selectedSonityAccount } = mainLayoutCtx.state;
  const [
    trackingCampaignProspects,
    setTrackingCampaignProspects
  ] = useState([]);

  const [
    trackingCampaignProspectsTemp,
    setTrackingCampaignProspectsTemp
  ] = useState([]);

  const [allTCPs, setAllTCPs] = useState([]);
  const [tcpsPaginated, setTCPsPaginated] = useState({
    limit: 10,
    offset: 0,
    count: 0,
    limits_changed: false
  });




  let eventSubsData = mainLayoutCtx.state.eventSubs


  const [bulkSearchTcpsCreate, bulkSearchTcpsCreateResult] = useMutation(
    BULK_SEARCH_RESULT_CREATE,
    {
      refetchQueries: ["GetTrackingCampaignProspectsPaginated"],
      onCompleted: () => {
        setSelectedPeople([]);
      }
    }
  );

  const [updateTcpsStatus, { isUpdatingTcpsStatus }] = useMutation(
    UPDATE_TRACKING_CAMPAIGN_PROSPECTS_TABLE_STATUS,
    {
      onCompleted: (data) => {
        setSelectedPeople([]);
      }
    }
  );

  const [
    updateTrackingCampaignProspectStatus,
    updateTrackingCampaignProspectStatusResult
  ] = useMutation(UPDATE_TRACKING_CAMPAIGN_PROSPECT_TABLE_STATUS, {
    onCompleted: (data) => {
      const { update_tracking_campaign_prospect_table_status } = data;

      console.log("[useTcps]updateTcps", { update_tracking_campaign_prospect_table_status, allTCPs, trackingCampaignProspects })

      //this used in campaigns table
      setAllTCPs(oldTcps => oldTcps.map(oTcp => {
        if (oTcp.id == update_tracking_campaign_prospect_table_status.id) return {
          ...oTcp,
          campaign_status_id: update_tracking_campaign_prospect_table_status.campaign_status_id
        }
        return oTcp
      }))
      //this is used in current chats
      setTrackingCampaignProspects(oldTcps => oldTcps.map(oTcp => {
        if (oTcp.id == update_tracking_campaign_prospect_table_status.id) return {
          ...oTcp,
          campaign_status_id: update_tracking_campaign_prospect_table_status.campaign_status_id
        }
        return oTcp
      }))
    }
  });


  const [
    requeueWithdrawn,
    requeueWithdrawnResult
  ] = useMutation(REQUEUE_WITHDRAWN, {
    onCompleted: (data) => {
      console.log("[useTcps]requeueWithdrawn", data);

      updateMessage({
        isShown: true,
        variant: "success",
        content: "Contacts have been successfully requeued"
      });

    },
    onError: (error) => {

      updateMessage({
        isShown: true,
        variant: "error",
        content: "There was an error requeuing contacts. Please try again or contact support."
      });
    }
  });


  const [updateSelectedTcps, { isUpdatingSelectedTcps }] = useMutation(
    UPDATE_TRACKING_CAMPAIGN_PROSPECTS_TABLE_STATUS,
    {
      onCompleted: () => {
        setSelectedPeople([]);
        console.log("[useTcps]updateSelectedTcps")
      }
    }
  );
  const [
    updateTrackingCampaignProspect,
    updateTrackingCampaignProspectResult
  ] = useMutation(UPDATE_TRACKING_CAMPAIGN_PROSPECT, {
    onCompleted: data => {
      console.log("[useTcps]updateTrackingCampaignProspect: ", data);
      //moveTcpToCampaignCallback([data.update_tracking_campaign_prospect]);
    }
    // onError,
    // refetchQueries
    // fetchPolicy
  });

  const [
    updateTrackingCampaignProspects,
    updateTrackingCampaignProspectsResult
  ] = useMutation(UPDATE_TRACKING_CAMPAIGN_PROSPECTS, {
    onCompleted: data => {
      console.log("[useTcps]updateTrackingCampaignProspects: ", data.update_tracking_campaign_prospects);
      const pruneList = data.update_tracking_campaign_prospects.filter(
        tcp => tcp.id !== selectedCampaignDefinition.id
      );

      let newTCPs = trackingCampaignProspects.filter(
        tcp => !pruneList.find(p => p.id === tcp.id)
      );

      setTrackingCampaignProspects(newTCPs);
      setTrackingCampaignProspectsTemp(newTCPs);

      setTCPsPaginated({
        ...tcpsPaginated,
        count: newTCPs.length
      });

      updateMessage({
        isShown: true,
        variant: "success",
        content: "Prospect(s) have been updated successfully."
      });
      //moveTcpToCampaignCallback([...data.update_tracking_campaign_prospects]);
    }
    // onError,
    // refetchQueries
    // fetchPolicy
  });

  // gets all the TCP data for a CD   ---> GOAL: RUN ONCE
  const [
    getTrackingCampaignProspectsTable,
    getTrackingCampaignProspectsTableResult
  ] = useLazyQuery(GET_TRACKING_CAMPAIGN_PROSPECTS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    onCompleted: data => {
      console.log("Got paginated datas: ", data);
    }
  });

  // gets all the TCP data for a CD   ---> GOAL: RUN ONCE
  const [
    getTrackingCampaignProspects,
    getTrackingCampaignProspectsResults
  ] = useLazyQuery(GET_TRACKING_CAMPAIGN_PROSPECTS_2, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    onCompleted: data => {
      console.log("Got paginated datas: ", data);
    }
  });

  const [
    deleteTrackingCampaignProspect,
    deleteTrackingCampaignProspectResult
  ] = useMutation(DELETE_TRACKING_CAMPAIGN_PROSPECT, {
    onCompleted: () => {
      setSelectedPeople([]);
    }
  });

  //overrides input with sonity_account_id
  // const deleteTrackingCampaignProspect = (variables) => {

  //   console.log("deleteTcp", variables)
  //   const mergedVariables = {
  //     ...variables,
  //     sonity_account_id: selectedSonityAccount.id
  //   };
  //   return deleteTrackingCampaignProspectMutation({ variables: mergedVariables });

  // };

  const [deleteTCPFromCampaigns, deleteTCPFromCampaignsResults] = useMutation(
    DELETE_TCP_FROM_CAMPAIGNS,
    {
      refetchQueries: ["GetTrackingCampaignProspectsPaginated"],
      onCompleted: () => {
        updateMessage({
          isShown: true,
          variant: "success",
          content: "Prospect was deleted from campaigns."
        });
      }
    }
  );



  const [
    deleteTrackingCampaignProspects,
    deleteTrackingCampaignProspectsResult
  ] = useMutation(DELETE_TRACKING_CAMPAIGN_PROSPECTS, {
    refetchQueries: ["GetTrackingCampaignProspectsPaginated"],
    onCompleted: () => {
      setSelectedPeople([]);
    }
  });

  const [
    requeueErrors,
    { loading: isRequeueErrorsLoading, error: isRequeueErrorsError }
  ] = useMutation(REVERT_TRACKING_CAMPAIGN_PROSPECTS_TABLE_STATUS, {
    onCompleted: data => {
      console.log(
        "REVERTED TASKS & TCPS :",
        data.revert_tracking_campaign_prospects_
      );
      updateMessage({
        isShown: true,
        variant: "success",
        content:
          "Tasks and status associated with this prospect have been updated."
      });
    },
    onError: err => {
      console.log("requeue err:", err);
      updateMessage({
        isShown: true,
        variant: "error",
        content:
          "There was a problem reverting the task associated with this prospect."
      });
    }
  });

  const [createTcps, createTcpsResults] = useMutation(
    CREATE_TRACKING_CAMPAIGN_PROSPECTS,
    {
      refetchQueries: ["GetTrackingCampaignProspectsPaginated"],
      onError: err => {
        console.log("ERROR CREATING TCPS FROM apollo onError", err);
        updateMessage({
          variant: "error",
          isShown: true,
          content: `One or more of the prospects you are adding is already in "Messenger/Connector" campaign`
        });
      }
    }
  );



  const [bulkCloneToCampaign, bulkCloneToCampaignResult] = useMutation(
    BULK_CLONE_TO_CAMPAIGN,
    {
      onCompleted: data => {
        console.log("bulk clone to campaign: ", data.bulk_clone_to_campaign);
        updateMessage({
          isShown: true,
          variant: "success",
          content: "Prospect(s) have been copied successfully."
        });
      },
      onError: data => {
        console.log("bulk clone to campaign error: ", data);
        updateMessage({
          isShown: true,
          variant: "error",
          content: "There was a problem copying the prospect(s)."
        });
      }
    }
  );

  function handleRequeueErrors(variables) {
    requeueErrors({
      variables
    });
  }

  const handleDelete = async id => {
    setLoading(true);
    await deleteTrackingCampaignProspect({
      variables: {
        tracking_campaign_prospect_id: id,
        sonity_profile_id: selectedSonityProfile.id
      }
    });
    console.log("[useTcps]Deleted: ", id);

    setTrackingCampaignProspects(oldTcps =>
      oldTcps.filter(tcp => parseInt(tcp.id) !== parseInt(id))
    );
    setTrackingCampaignProspectsTemp(oldTcps =>
      oldTcps.filter(tcp => parseInt(tcp.id) !== parseInt(id))
    );
    setLoading(false);
  };

  const removeDeletedProspects = async ids => {
    setTrackingCampaignProspects(oldTcps =>
      oldTcps.filter(
        tcp => !ids.map(id => parseInt(id)).includes(parseInt(tcp.id))
      )
    );
    setTrackingCampaignProspectsTemp(oldTcps =>
      oldTcps.filter(
        tcp => !ids.map(id => parseInt(id)).includes(parseInt(tcp.id))
      )
    );
  };


  /**
   * @name handleDeleteTCPFromCampaigns
   * @desc Executes when we remove a prospect or TCP from a campaign
   * @param {string} l_id 
   * @param {[Object]} campaigns 
   * @returns null
   */
  const handleDeleteTCPFromCampaigns = async (l_id, campaigns) => {
    let result = await deleteTCPFromCampaigns({
      variables: {
        l_id: l_id,
        campaigns: [...campaigns]
      }
    });

    // let deletedIds = result.data.delete_tcps_from_campaigns

    // if (deletedIds.length > 0) removeDeletedProspects(deletedIds)
  };

  /**
   * @name handleDeleteSelected
   * @desc Executes when we remove  prospects or TCPs from a campaign
   * @param {[integer]} ids - Prospect ids to delete 
   * @returns null
   */
  const handleDeleteSelected = async ids => {
    setLoading(true);
    await deleteTrackingCampaignProspects({
      variables: {
        values: [...ids]
      }
    });
    console.log("Deleteds: ", ids);
    await removeDeletedProspects(ids);
    setLoading(false);
  };

  /**
   * @name applyTCPFilters
   * @desc Apply filters to the prospects or TCP list
   * @param {*} campaignStatusFilter 
   * @param {*} textFilters 
   * @param {*} allFilters 
   * @param {*} campaignDefinitionFilter 
   * @returns A list of filters
   */
  const applyTCPFilters = (
    campaignStatusFilter,
    textFilters,
    allFilters,
    campaignDefinitionFilter
  ) => {
    console.log("Current Filters: ", {
      campaignStatusFilter,
      textFilters,
      allFilters,
      campaignDefinitionFilter
    });
    const filters = {};

    let hasCampaignStatusId =
      campaignStatusFilter &&
      Object.keys(campaignStatusFilter).includes("value") &&
      campaignStatusFilter.value !== "all";
    let hasTextSearches = textFilters && textFilters.length > 0;

    // Add Campaign Status ID if present
    if (hasCampaignStatusId) {
      filters.campaign_status_ids = campaignStatusFilter.value;
    }

    // Add text searches
    if (hasTextSearches) {
      filters.text_searches = textFilters.map(text_filter => text_filter.value);
    }

    // Add campaign definition filter
    if (campaignDefinitionFilter) {
      filters.campaign_filter = campaignDefinitionFilter.value;
    }

    // Add age Filters
    if (allFilters) {
      let allFiltersObj = {};

      allFilters.forEach(filter => {
        let value = filter.value;

        if (!["age", "gender", "gender_prob"].includes(filter.type)) {
          return;
        }

        // If age range is maxed out dont add it to the filters
        if (filter.type === "age" && value[0] === 20 && value[1] === 80) {
          return;
        }

        // If age range is -1 to -1 dont add it to the filters
        if (filter.type === "age" && (value[0] < 0 || value[1] < 0)) {
          return;
        }

        // If gender is set to "all" dont add it to the filters
        if (filter.type === "gender" && value === "all") {
          return;
        }

        // If gender prob is set to 50 dont add it to the filters
        if (filter.type === "gender_prob" && value <= 50) {
          return;
        }

        allFiltersObj[filter.type] = value;
      });

      if (Object.keys(allFiltersObj).length > 0) {
        filters.age_gender_filter = allFiltersObj;
      }
    }

    console.log("Applied Filters: ", filters);

    return filters;
  };


  /**
   * @name retrieveTCPs
   * @desc Apply TCP filters and fetch prospects or tcps
   * @param {Object} selectedSonityProfile 
   * @param {Object} selectedCampaignDefinition 
   * @param {Object} tcpsPaginated - An object with pagination attributes
   * @returns null
   */
  const retrieveTCPs = (
    selectedSonityProfile,
    selectedCampaignDefinition,
    tcpsPaginated
  ) => {
    let callVariables = {
      sonity_profile_id: selectedSonityProfile.id,
      campaign_definition_id: selectedCampaignDefinition.id,
      limit: tcpsPaginated.limit,
      offset: tcpsPaginated.offset
    };

    let appliedTCPFilters = applyTCPFilters(
      statusFilter,
      textFilters,
      allFilters,
      campaignDefinitionFilter
    );

    getTrackingCampaignProspectsTable({
      variables: {
        ...callVariables,
        ...appliedTCPFilters,
        sonity_profile_id: selectedSonityProfile.id
      }
    });
  };

  /**
   * @name EventSubsData Effect
   * @type function
   * @desc Executes on eventSubsData change. If the eventSubsData type is TRACKING_CAMPAIGN_PROSPECTS_UPDATED, this effect will make the necessary
   *         the current TCP list. An update here can be prospect adding or an update to an existing prospect or TCP
   * @returns null
   */
  useEffect(() => {
    console.log("[useTcps]eventSubsData", eventSubsData)
    if (eventSubsData && eventSubsData.type && eventSubsData.type === 'TRACKING_CAMPAIGN_PROSPECTS_CREATED'
    ) {
      const newTcps = typeof eventSubsData.payload === "string" ? [...JSON.parse(eventSubsData.payload)] : [...eventSubsData.payload];
      const relevantTcps = newTcps.filter(tcp => selectedCampaignDefinition && tcp.campaign_definition_id == selectedCampaignDefinition?.id);
      if (!relevantTcps || relevantTcps.length === 0) return

      setTrackingCampaignProspects([
        ...relevantTcps,
        ...trackingCampaignProspects
      ]);

      setTrackingCampaignProspectsTemp([
        ...relevantTcps,
        ...trackingCampaignProspects
      ]);
      setAllTCPs([
        ...relevantTcps,
        ...trackingCampaignProspects
      ])

    }
    if (eventSubsData && eventSubsData.type && eventSubsData.type === 'TRACKING_CAMPAIGN_PROSPECTS_UPDATED') {

      let tcpsToUpdate = typeof eventSubsData.payload === "string" ? [...JSON.parse(eventSubsData.payload)] : [...eventSubsData.payload];

      //Could insert the TCP here
      if (!tcpsToUpdate || !tcpsToUpdate[0]) return

      //formats the dates
      tcpsToUpdate = tcpsToUpdate.map(tcp => ({
        ...tcp,
        updated_at: tcp.updated_at === 'string' ? new Date(tcp.updated_at) : tcp.updated_at,
        created_at: tcp.created_at === 'string' ? new Date(tcp.created_at) : tcp.created_at
      }));

      console.log("[useTcps]eventSubs -- tcpsToUpdate", { tcpsToUpdate })
      const copy = trackingCampaignProspects.map(tcp => {
        //console.log("[useTcps]tcpmap", tcp)
        const tcp2u = tcpsToUpdate.find(tcp2u => tcp.id == tcp2u.id);
        if (tcp2u) return { ...tcp, ...tcp2u }
        return { ...tcp }
      });




      setTrackingCampaignProspects(copy);
      setTrackingCampaignProspectsTemp(copy);
      setAllTCPs(copy)


    }

  }, [eventSubsData]);

  // Runs when we get data back

  /**
 * @name getTrackingCampaignProspectsTableResult Effect
 * @type function
 * @desc Executes on getTrackingCampaignProspectsTableResult change. When there data on the getTrackingCampaignProspectsTableResult object it will set 
 *         trackingCampaignProspect to that.
 * @returns null
 */
  useEffect(() => {
    if (
      !getTrackingCampaignProspectsTableResult.loading &&
      getTrackingCampaignProspectsTableResult.data
    ) {
      console.log("fetch success setting tcps:");
      console.log(getTrackingCampaignProspectsTableResult.data);

      console.log("Current TCPs Paginated: ", tcpsPaginated);

      setTCPsPaginated({
        ...tcpsPaginated,
        count:
          getTrackingCampaignProspectsTableResult.data
            .tracking_campaign_prospects_table.count
      });

      let newTrackingCampaignProspects = getTrackingCampaignProspectsTableResult.data
        .tracking_campaign_prospects_table
        .rows;

      setTrackingCampaignProspects(newTrackingCampaignProspects);

      let addTCPs = []

      newTrackingCampaignProspects.forEach(tcp => {
        let tcpAlreadPresent = allTCPs.filter(t => t.id == tcp.id, []).length === 0

        if (tcpAlreadPresent) {
          addTCPs.push(tcp);
        }
      });

      setAllTCPs([
        ...addTCPs,
        ...allTCPs
      ]);

      // setTrackingCampaignProspectsTemp(newTrackingCampaignProspects);
    }
  }, [getTrackingCampaignProspectsTableResult]);

  // Runs when limit, offset or textFilters changes

  /**
* @name tcpsPaginated Effect
* @type function
* @desc Runs when limit, offset or textFilters changes. It either sets a slice of the trackingCampaignProspects either from memory or from a fresh call
* @returns null
*/
  useEffect(() => {
    //on mount
    console.log("limit/offset fetch:", {
      tcpsPaginated,
      textFilters,
      statusFilter,
      isLoading: getTrackingCampaignProspectsTableResult.loading
    });

    if (tcpsPaginated.limits_changed) {
      if (tcpsPaginated.offset + tcpsPaginated.limit <= allTCPs.length) {
        console.log("USE MEM RESULT");
        setTrackingCampaignProspects(
          allTCPs.slice(
            tcpsPaginated.offset,
            tcpsPaginated.offset + tcpsPaginated.limit
          )
        );
      } else {
        console.log("Fresh call");
        retrieveTCPs(
          selectedSonityProfile,
          selectedCampaignDefinition,
          tcpsPaginated
        );

        setTCPsPaginated({
          ...tcpsPaginated,
          limits_changed: false
        });
      }
    }
  }, [tcpsPaginated]);

  /**
   * @name filters Effect
   * @type function
   * @desc Its support to retrieve prospects or tracking campaign prospects on filters change
   * @returns null
   */
  useEffect(() => {
    console.log("Filters use effect");
    if (selectedCampaignDefinition) {
      console.log("Some filters changed: ", {
        textFilters,
        statusFilter,
        allFilters
      });

      let newTCPsPaginated = {
        ...tcpsPaginated,
        offset: 0
      };

      setAllTCPs([]);
      setTCPsPaginated(newTCPsPaginated);
      retrieveTCPs(
        selectedSonityProfile,
        selectedCampaignDefinition,
        newTCPsPaginated
      );
    }
  }, [textFilters, statusFilter, allFilters]);


  return [
    {
      getTrackingCampaignProspectsResults,
      allTCPs,
      trackingCampaignProspects,
      tcpsPaginated,
      trackingCampaignProspectsTemp,
      isRequeueErrorsLoading,
      isUpdatingTcpsStatus,
      bulkSearchTcpsCreateResult,
      createTcpsResults,
      isUpdatingTcpsStatus,
      isUpdatingSelectedTcps,
      getTrackingCampaignProspectsTableResult,
      deleteTrackingCampaignProspectResult,
      deleteTrackingCampaignProspectsResult,
      updateTrackingCampaignProspectResult,
      updateTrackingCampaignProspectsResult,
      updateTrackingCampaignProspectStatusResult,
      bulkCloneToCampaignResult,
      requeueWithdrawnResult
    },
    {
      getTrackingCampaignProspects,
      bulkCloneToCampaign,
      setAllTCPs,
      setTrackingCampaignProspects,
      setTCPsPaginated,
      setTrackingCampaignProspectsTemp,
      bulkSearchTcpsCreate,
      createTcps,
      deleteTCPFromCampaigns,
      updateTcpsStatus,
      requeueWithdrawn,
      getTrackingCampaignProspectsTable,
      handleDelete,
      handleDeleteSelected,
      handleDeleteTCPFromCampaigns,
      deleteTrackingCampaignProspect,
      deleteTrackingCampaignProspects,
      handleRequeueErrors,
      updateSelectedTcps,
      updateTrackingCampaignProspect,
      updateTrackingCampaignProspects,
      updateTrackingCampaignProspectStatus,

    }
  ];
};
