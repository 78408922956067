import { SonityLocalStore, contextsList } from "@/utils/sonityLocalStore";
import actionTypes from "./flowActionTypes";

let sonityLocalStore = new SonityLocalStore()

const reducer = (state, action) => {
  console.log("Current State Change: ", {
    state,
    action
  });

  let newState = state

  switch (action.type) {
    case "set_name":
      newState = {
        ...state,
        name: action.payload
      };
      break;
    case actionTypes.show_add_action_menu:
      newState = {
        ...state,
        show_add_action: {
          show_dilaog: !state.show_add_action,
          id: action.payload
        }
      };
      break;
    case actionTypes.close_open_popups:
      newState = {
        ...state,
        show_add_action: false
      };
      break;
    case actionTypes.show_change_delay_popup:
      newState = {
        ...state,
        timeframe: action.payload
      };
      break;
    case actionTypes.set_state:
      newState = {
        ...state,
        ...action.payload
      };
      break
    default:
      return state;
  }

  console.log("Sonity Local Store: ", sonityLocalStore)


  sonityLocalStore.storeContextState(contextsList.flow, newState)
  return newState;
};

export default reducer;
