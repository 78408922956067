import React, { createContext, useContext, useReducer } from "react";

import reducer from "./flowReducer";
import initialState from "./flowInitialState";
import stateFunctions from "./flowStateFunctions";

let ReactFlowContext = createContext();

export const FlowProvider = ({ children }) => {
  console.log("FLowProvider init:", initialState);
  const [state, dispatch] = useReducer(reducer, initialState);

  const stateFuncs = stateFunctions(state, dispatch);

  let value = {
    state,
    dispatch,
    stateFunctions: stateFuncs
  };

  return (
    <ReactFlowContext.Provider value={value}>
      {children}
    </ReactFlowContext.Provider>
  );
};

export const useFlowContext = () => {
  return useContext(ReactFlowContext);
};
