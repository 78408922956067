let actionTypes = {
  set_state: "SET_STATE",
  set_steps: "SET_STEPS",
  set_prev_steps: "SET_PREV_STEPS",
  update_step: "UPDATE_STEP",
  set_commit_bar_status: "SET_COMMIT_BAR_STATUS",
  change_commit_status: "CHANGE_COMMIT_STATUS"
};

export default actionTypes;
