import { SonityLocalStore, contextsList } from "../../utils/sonityLocalStore";



const sonityLocalStore = new SonityLocalStore();



let initialState = {
  user: null,
  token: null,
  cubejsApi: null,
}

let storedState = sonityLocalStore.getContextState(contextsList.auth)


export default {
  ...initialState,
  ...storedState
}


