import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  actionButton: {
    margin: "0.25rem 0"
  },
  root: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15)
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('xl')]: {
      display: "none"
    }
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20
  },
  details: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  column: {
    flexBasis: "33.33%"
  },
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  col: {
    display: "flex",
    flexDirection: "column",
    margin: "0 1rem 0 0",
    alignItems: "flex-end"
  },
  columnLeft: {
    minWidth: "200px"
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  container: {
    margin: "0",
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    width: 200
  },
  inputField: {
    backgroundColor: theme.palette.background.paper
  },
  menu: {
    width: 200,
    backgroundColor: theme.palette.background.paper
  },
  removeAdornment: {
    cursor: "pointer"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%"
    // minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  webhookSettingsContainer: {
    marginTop: theme.spacing(3),
    width: "100%"
  }
}));
