import gql from "graphql-tag";

export const CREATE_CAMPAIGN_DEFINITION = gql`
  mutation createCampaignDefinition(
    $name: String!
    $sonity_profile_id: ID!
    $extensions: ExtensionsInput
    $campaign_messages: [CampaignMessagesInput]
    $type: CampaignDefinitionsType
  ) {
    create_campaign_definition(
      name: $name
      sonity_profile_id: $sonity_profile_id
      extensions: $extensions
      campaign_messages: $campaign_messages
      type: $type
    ) {
      id
      name
      start_date
      set_active
      set_inmail
      type
      extensions {
        requeue_errors
        requeue_withdrawn
      }
      sonity_profile_id
      campaign_messages {
        id
        step
        delay
        type
        text
        fallback_text
        inmail_subject
        extensions
        created_at
        updated_at
        campaign_definition_id
        like_post
        skip_message
        complete_time
      }
      created_at
      updated_at
    }
  }
`;

export const UPDATE_CAMPAIGN_DEFINITION = gql`
  mutation updateCampaignDefinition($input: CampaignDefinitionsInput!) {
    update_campaign_definition(input: $input) {
      id
      name
      start_date
      set_active
      set_inmail
      extensions {
        requeue_errors
        requeue_withdrawn
      }
      sonity_profile_id
      created_at
      updated_at
    }
  }
`;

/* using refetchQuery */
export const UPDATE_CAMPAIGN_DEFINITION_RETURN_TASKS = gql`
  mutation updateCampaignDefinition($input: CampaignDefinitionsInput!) {
    update_campaign_definition(input: $input) {
      id
      name
      start_date
      set_active
      set_inmail
      extensions {
        requeue_errors
        requeue_withdrawn
      }
      sonity_profile_id
      created_at
      updated_at
            
    }
  }
`;

export const DELETE_CAMPAIGN_DEFINITION = gql`
  mutation deleteCampaignDefinition($id: ID! $sonity_profile_id: ID!) {
    delete_campaign_definition(id: $id, sonity_profile_id:$sonity_profile_id) {
      num_modified
    }
  }
`;
