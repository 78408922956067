import React from "react";
import SonityLogoBasic from "../Icons/SonityLogoBasic";

const LoadingFallback = () => {
  return (
    <div className={`w-full h-full flex items-center justify-center flex-col`}>
      <SonityLogoBasic className="fill-text_primary-900" />

      {/* Pinging 2 dots */}
      <div className="flex items-center justify-center space-x-2 mt-2 ">
        <div className="w-6 h-6 bg-primary-500 rounded-full animate-ping2 "></div>
        <div
          className="w-6 h-6 bg-primary-500 rounded-full animate-ping2 "
          style={{ animationDelay: "0.1s" }}
        ></div>
        <div
          className="w-6 h-6 bg-primary-500 rounded-full animate-ping2 "
          style={{ animationDelay: "0.2s" }}
        ></div>
        <div
          className="w-6 h-6 bg-primary-500 rounded-full animate-ping2 "
          style={{ animationDelay: "0.3s" }}
        ></div>
        <div
          className="w-6 h-6 bg-primary-500 rounded-full animate-ping2 "
          style={{ animationDelay: "0.4s" }}
        ></div>
      </div>
    </div>
  );
};

export default LoadingFallback;
