import { SonityLocalStore, contextsList } from "../../utils/sonityLocalStore";
import actionTypes from "./mainLayoutActionTypes";
import { originalInitialState, combinateInitialState } from "./mainLayoutInitialState"


const sonityLocalStorage = new SonityLocalStore();

const mainLayoutReducer = (state, action) => {
    console.log("[mainLayoutReducer]", {action, state});

    let newState = originalInitialState


    switch (action.type) {
        case actionTypes.set_sonity_account:
            newState = {
                ...state,
                selectedSonityAccount: action.payload
            };
            break
        case actionTypes.set_event_subs:
            newState = {
                ...state,
                eventSubs: action.payload
            };
            break
        case actionTypes.set_sonity_accounts:
            newState = {
                ...state,
                sonityAccounts: action.payload
            };
            break
        case actionTypes.set_sonity_profile:
            newState = {
                ...state,
                selectedSonityProfile: action.payload
            };
            break
        case actionTypes.set_sonity_profiles:
            newState = {
                ...state,
                sonityProfiles: action.payload
            };
            break
        case actionTypes.set_campaign_definition:
            newState = {
                ...state,
                selectedCampaignDefinition: action.payload
            };
            break
        case actionTypes.set_campaign_definitions:
            newState = {
                ...state,
                campaignDefinitions: action.payload
            };
            break
        case actionTypes.set_user:
            newState = {
                ...state,
                user: action.payload
            };
            break
        case actionTypes.set_state:
            newState = {
                ...state,
                ...action.payload
            };
            break
        case actionTypes.clear_state:
            newState = originalInitialState
            break
        case actionTypes.toggle_faq_modal:
        return {
            ...state,
            faqModalOpen: !state.faqModalOpen
        };
        case actionTypes.toggle_account_settings_modal:
        return {
            ...state,
            accountSettingsModalOpen: !state.accountSettingsModalOpen
        };
        default:
            newState = combinateInitialState;
            break 
    }

    sonityLocalStorage.storeContextState(contextsList.mainLayout, newState)
    return newState;
};

export default mainLayoutReducer;
