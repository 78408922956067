import { feathersClient } from "../attach-client";

const CONTEXTS_STORE = "sonity-contexts-store";



const currentEnv = process.env.NODE_ENV



export const contextsList = {
  mainLayout: "main-layout",
  auth: "auth",
  tcpsContext: "tcps-context",
  listManager: "list-manager",
  reports: "reports",
  steps: "steps",
  flow: "flow"
}


export class SonityLocalStore {


  constructor(){

  }

  

  clearContexts(){
    console.log("[sonityLocalStore]clearContexts")
    // window.localStorage.setItem(CONTEXTS_STORE, JSON.stringify({}));
    // window.localStorage.setItem(CONTEXTS_STORE, JSON.stringify({}));
    const keysToDelete = [CONTEXTS_STORE]
    keysToDelete.forEach(key => {
      console.log("[sonityLocalStore]delete", key)
      window.localStorage.removeItem(key);
    });

  }

  isUserLoggedIn() {
    let contextsStore  = this.getStore(CONTEXTS_STORE)
    
    try {

      let auth = contextsStore[contextsList.auth]

      if (auth?.token?.length > 0){
        return true
      }
     this.clearContexts();
      return false

    }catch(err){
      this.clearContexts();
      return false
    }
  }


  storeContextState(contextName, state){
    const { authenticated } = feathersClient.authentication;

    // if (currentEnv !== "development") return

    if(!authenticated) {
      console.log("[storeContextState]clearContexts")
      return this.clearContexts();
    }

    let store = this.getStore(CONTEXTS_STORE);
    store[contextName] = state;
    this.saveStore(CONTEXTS_STORE, store);
    console.log("storeContextState", {
      authenticated,
      contextName,
      state:store[contextName]
    })
  }

  getContextState(contextName){
    // if (currentEnv !== "development") return
    console.log("[getContextState]", contextName)
    let contextState = this.getStore(CONTEXTS_STORE)[contextName];

    if (contextState){
      return contextState;
    }

    return {};
  }


  getStore(storeName = CONTEXTS_STORE){
    if(!window.localStorage.getItem(storeName)) window.localStorage.setItem(storeName, JSON.stringify({}))
    return JSON.parse(window.localStorage.getItem(storeName));
  }

  saveStore(storeName = CONTEXTS_STORE, store = {}){
    window.localStorage.setItem(storeName, JSON.stringify(store));
  }

  getItem(name, currentValue, condition=false){

    if (condition){
      console.log("Sonity Local Store: Condition met")
      return currentValue
    }
      

    let item = this.getStore()[name];

    if (item){
      return item;
    }

    return null;
  }


  storeItem(name, item){
    let store = this.getStore();
    store[name] = item;
    this.saveStore(CONTEXTS_STORE, store);
  }


}