import gql from "graphql-tag";

export const UPSERT_PLACEHOLDER = gql`
  mutation UpsertPlaceholder($input: PlaceholdersInput!) {
    upsert_placeholder(input: $input) {
      id
      tracking_campaign_prospect_id
      first_name
      middle_name
      last_name
      description
      phone
      email
      website
      position
      title
      location
      company
      connection_message
      welcome_message
      message_x
      created_at
      updated_at
    }
  }
`;

export const BULK_CREATE_PLACEHOLDERS = gql`
  mutation BulkCreatePlaceholders($input: [PlaceholdersInput!]!) {
    bulk_create_placeholders(input: $input) {
      id
      tracking_campaign_prospect_id
      first_name
      middle_name
      last_name
      description
      phone
      email
      website
      position
      title
      location
      company
      connection_message
      welcome_message
      message_x
      created_at
      updated_at
    }
  }
`;
