import { SonityLocalStore, contextsList } from "../../utils/sonityLocalStore";
import actionTypes from "./ActionTypes";



/**
 * @name tourReducer
 * @param {*} state - Current tour state
 * @param {Object} action - Requested action. Contains type of action and paylod
 * @returns new state
 */
const tourReducer = (state, action) => {

  let newState = state;


  switch (action.type) {
    case actionTypes.set_state:
      newState = {
        ...state,
        ...action.payload
      };
      break
    case actionTypes.set_run:
      newState = {
        ...state,
        ...action.payload
      };
      break
    case actionTypes.set_steps:
      newState = {
        ...state,
        steps: action.payload
      };
      break
    default:
      newState =  state;
      break;
  }

  // sonityLocalStore.storeContextState(contextsList.tcpsContext, newState)
  return newState;
};

export default tourReducer;
