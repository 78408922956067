import { CircularProgress, TextField } from "@mui/material";
import { myFetch } from "../../../attach-client";

import makeStyles from '@mui/styles/makeStyles';

import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Typography
} from "@mui/material";
import { ContactMail } from "@mui/icons-material";
import React, { useEffect } from "react";
import { feathersClient } from "../../../attach-client";
import { useMainLayoutContext } from "../../../contexts/mainLayoutState/MainLayoutProvider";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  button: {
    marginRight: theme.spacing(1)
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  completed: {
    display: "inline-block"
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const AnimalCaptchaVerificationModal = ({ user, payload, open, handleClose }) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [snapshot, setSnapshot] = React.useState({});
  const [pick, setPick] = React.useState();
  const [sending, setSending] = React.useState(false);

  const mainLayoutCtx = useMainLayoutContext();
  const { selectedSonityAccount, selectedSonityProfile } = mainLayoutCtx.state;
 

  const handleChange = event => {
    console.log("Event: ", event);
    setPick(event.target.value);
  };

  const closeModal = () => {
    handleClose(false);
    setPick("");
  };

  const sendPickToDriver = async () => {
    console.log("the pick: ", pick);

    setSending(true);
    await fetch(
      `${mainLayoutCtx.state.selectedSonityAccount.selenium_endpoint}/enter-animal-captcha-pick`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-sonity-account-id": mainLayoutCtx.state.selectedSonityAccount.id,
          "x-sonity-user-id": mainLayoutCtx.user.id,
          authorization:
            feathersClient.authentication.options.storage["feathers-jwt"]
        },
        body: JSON.stringify({
          pick,
          sonity_profile_id: payload.sonity_profile_id
        })
      }
    );

    setSending(false);
  };

  const getFullName = () => {
    try {
      if (payload && payload.sonity_profile) {
        let sonityProfile = payload.sonity_profile;
        let fullName =
          sonityProfile.contact.first_name +
          " " +
          sonityProfile.contact.last_name;
        return fullName;
      } else {
        return "";
      }
    } catch (e) {
      return "";
    }
  };

  
  console.log("[animalCaptcha]mainLayoutCtx", {mainLayoutCtx, payload})
  const [{ drivers }, { fetchSnapshot }] = mainLayoutCtx.useDriversHook;
  useEffect(()=>{
    if(fetchSnapshot) {
      fetchSnapshot(payload.sonity_profile_id)
      .then(ss => {
          setLoading(false);
          setSnapshot(ss);
      })
      .catch(e => {
        console.log(e);
        setLoading(false);
      });
    }
  }, [fetchSnapshot])
  
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={closeModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>Animal Captcha Verification {`for ${getFullName()}`}</DialogTitle>
        <DialogContent dividers>
          <div>
          {snapshot?.img && (
            <img
              alt="driver-snapshot"
              src={`data:image/png;base64, ${snapshot.img}`}
              height={320}
              width={400}
            />
          )}
            <Typography>Enter direction of the upright animal</Typography>

            <TextField
              id="filled-name"
              label="Pick"
              placeholder="Pick the direction of the upright animal"
              type="number"
              value={pick}
              onChange={handleChange}
              variant="filled"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between">
            <Grid item>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={sendPickToDriver}
                  className={classes.button}
                >
                  {sending ? "Sending..." : "Send"}
                </Button>
              </div>
            </Grid>
            <Grid item>
              <Button
                autoFocus
                onClick={closeModal}
                variant="contained"
                color="primary"
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AnimalCaptchaVerificationModal;
