import gql from "graphql-tag";

export const GET_PLACEHOLDER = gql`
  query getPlaceholder($id: ID!) {
    placeholder(id: $id) {
      id
      tracking_campaign_prospect_id
      first_name
      middle_name
      last_name
      description
      phone
      email
      website
      position
      title
      location
      company
      connection_message
      welcome_message
      message_x
      created_at
      updated_at
    }
  }
`;

export const GET_PLACEHOLDERS = gql`
  query getPlaceholders($input: PlaceholdersInput!) {
    placeholders(input: $input) {
      id
      tracking_campaign_prospect_id
      first_name
      middle_name
      last_name
      description
      phone
      email
      website
      position
      title
      location
      company
      connection_message
      welcome_message
      message_x
      created_at
      updated_at
    }
  }
`;
