import { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_MESSAGE, UPDATE_MESSAGE } from "../gql/queries/table";

/*
  This hook is used to receive info about some global messages (can be error message or just notification message)
*/
export const useMessage = () => {
  const [message, setMessage] = useState({
    isShown: false,
    content: "",
    variant: null
  });

  // const {
  //   data: { message }
  //   //loading
  // } = useQuery(GET_MESSAGE);
  // const [_updateMessage] = useMutation(UPDATE_MESSAGE, { variables: {} });

  const updateMessage = updatedMessage => {
    setMessage(updatedMessage);
  };

  const hideMessage = variant => {
    setMessage({
      isShown: false,
      content: "",
      variant // need to specify this, so on hide animation background color remains the same
    });
  };

  return [message, { updateMessage, hideMessage }];
};
