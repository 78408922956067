import actionTypes from "./TcpsActionTypes";

const stateFunctions = (state, dispatch) => {
  const setState = newState => {
    dispatch({
      type: actionTypes.set_state,
      payload: newState
    });
  };

  const setSelectedPeople = people => {
    dispatch({
      type: actionTypes.set_selected_people,
      payload: people
    });
  };

  const setNewCampaignName = name => {
    dispatch({
      type: actionTypes.set_new_campaign_name,
      payload: name
    });
  };

  const setLoading = mode => {
    dispatch({
      type: actionTypes.set_loading_mode,
      payload: mode
    });
  };

  const setError = mode => {
    dispatch({
      type: actionTypes.set_error_mode,
      payload: mode
    });
  };

  return {
    setState,
    setSelectedPeople,
    setNewCampaignName,
    setLoading,
    setError
  };
};

export default stateFunctions;
