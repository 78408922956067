import actionTypes from "./mainLayoutActionTypes";

const mainLayoutStateFuncs = (state, dispatch) => {
  const setUser = user => {
    dispatch({
      type: actionTypes.set_user,
      payload: user
    });
  };

  const setSelectedCampaignDefinition = campaign_definition => {
    dispatch({
      type: actionTypes.set_campaign_definition,
      payload: campaign_definition
    });
  };

  const setSonityAccounts = sonity_accounts => {
    dispatch({
      type: actionTypes.set_sonity_accounts,
      payload: sonity_accounts
    });
  };
  const setSelectedSonityProfile = sonity_profile => {
    if (!sonity_profile) {
      console.log("Set cds to null");
      setCampaignDefinitions([]);
    }
    dispatch({
      type: actionTypes.set_sonity_profile,
      payload: sonity_profile
    });
  };

  const setSonityProfiles = sonity_profiles => {
    dispatch({
      type: actionTypes.set_sonity_profiles,
      payload: sonity_profiles
    });
  };

  const setEventSubs = (data) => {

    console.log("setEventSubs data: ", data)
    dispatch({
      type: actionTypes.set_event_subs,
      payload: data
    })
  }

  const setCampaignDefinitions = campaign_definitions => {
    dispatch({
      type: actionTypes.set_campaign_definitions,
      payload: campaign_definitions
    });
  };
  const createCampaignDefinition = campaign_definition => {
    setCampaignDefinitions([
      ...state.campaignDefinitions,
      { ...campaign_definition }
    ]);
  };

  const removeCampaignDefinition = (id, payload) => {
    console.log("removeCD ", id);
    let currCampaignDefinitions = state.campaignDefinitions;
    let index = currCampaignDefinitions.findIndex(cd => cd.id == id);

    setSelectedCampaignDefinition(null);
    setCampaignDefinitions([
      ...currCampaignDefinitions.slice(0, index),
      ...currCampaignDefinitions.slice(index + 1)
    ]);

    let spCopy = [...state.sonityProfiles];
    let spIndex = spCopy.findIndex(
      sp => sp.id == state.selectedSonityProfile.id
    );
    let currSpCds = [...state.selectedSonityProfile.campaign_definitions];
    let cdIndex = currSpCds.findIndex(cd => cd.id == id);

    if (spIndex > -1 && cdIndex > -1) {
      setSelectedSonityProfile({
        ...spCopy[spIndex],
        campaign_definitions: [
          ...currSpCds.slice(0, cdIndex),
          ...currSpCds.slice(cdIndex + 1)
        ]
      });

      setSonityProfiles([
        ...spCopy.slice(0, spIndex),
        {
          ...spCopy[spIndex],
          campaign_definitions: [
            ...currSpCds.slice(0, cdIndex),
            ...currSpCds.slice(cdIndex + 1)
          ]
        },
        ...spCopy.slice(spIndex + 1)
      ]);
    }
  };
  const setSelectedSonityAccount = sonity_account => {
    dispatch({
      type: actionTypes.set_sonity_account,
      payload: sonity_account
    });
  };

  const _setState = payload => {
    dispatch({
      type: actionTypes.set_state,
      payload: {...payload}
    });
  };

  const setMounted = newState => {
    _setState({
      mounted: {...newState}
    });
  };

  const setUpdateDialog = newState => {
    _setState({
      updateDialogOpen: newState
    });
  };

  const updateSonityProfilePreferences = update_preferences => {
    console.log("update reducer ", {
      state,
      update_preferences
    });

    let sonityProfilesUpdated = state.sonityProfiles.map(sp => {
      if (parseInt(sp.preferences.id) !== parseInt(update_preferences.id))
        return { ...sp };
      if (parseInt(sp.preferences.id) === parseInt(update_preferences.id)) {
        console.log("Patching preferences of sp", sp);
        return {
          ...sp,
          preferences: {
            ...sp.preferences,
            ...update_preferences
          }
        };
      }
    });

    // let newSelected = sonityProfilesUpdated.find(
    //   sp => sp.id == update_preferences.sonity_profile_id
    // );
    setSonityProfiles(sonityProfilesUpdated);
    //setSelectedSonityProfile(newSelected);
  };

  const setActiveProfile = newState => {
    _setState({
      activeProfile: newState
    });
  };

  const setSonityProfileToDelete = newState => {
    _setState({
      sonityProfileToDelete: newState
    });
  };

  const setDrivers = newState => {
    _setState({
      drivers: newState
    });
  };
  const setAnimalCaptchaVerification = newState => {
    _setState({
      animalCaptchaVerification: newState
    });
  };
  const setPinVerification = newState => {
    _setState({
      pinVerification: newState
    });
  };

  const triggerAnimalCaptchaVerificationModal = payload => {
    _setState({
      animalCaptchaVerification: true,
      animalCaptchaVerificationPayload: payload
    });
  };
  const triggerPinVerificationModal = payload => {
    _setState({
      pinVerification: true,
      pinVerificationPayload: payload
    });
  };

  const toggleAccountSettingsModal = () => {
    _setState({
      accountSettingsModalOpen: !state.accountSettingsModalOpen
    });
  };
  const closePinVerificationModal = () => {
    _setState({
      pinVerification: false,
      pinVerificationPayload: null
    });
  };

  const setMainLayoutState = payload => {
    _setState({
      ...payload
    });
  };

  const removeSonityProfile = (id, payload) => {
    let currSonityProfiles = state.sonityProfiles;
    let index = currSonityProfiles.findIndex(sp => sp.id == id);

    setSonityProfiles([
      ...currSonityProfiles.slice(0, index),
      ...currSonityProfiles.slice(index + 1)
    ]);
  };
  const updateSonityProfile = (id, payload) => {
    let currSonityProfiles = [...state.sonityProfiles];
    let index = currSonityProfiles.findIndex(sp => sp.id == id);
    currSonityProfiles[index] = {
      ...currSonityProfiles[index],
      ...payload
    };

    setSonityProfiles(currSonityProfiles);
  };

  const setSelectedCampaignMessages = messages => {
    setSelectedCampaignDefinition({
      ...state.selectedCampaignDefinition,
      campaign_messages: messages
    });
  };

  const deleteSelectedCampaignMessage = message_id => {
    setSelectedCampaignDefinition({
      ...state.selectedCampaignDefinition,
      campaign_messages: state.campaign_messages.filter(
        message => message.id !== message_id
      )
    });
  };

  const triggerVerificationModals = event => {
    console.log("Event: ", JSON.parse(event.payload));

    if (event.type == "PIN_VERIFICATION") {
      let payload = JSON.parse(event.payload);
      payload.sonity_profile = state.sonityProfiles.find(
        sp => sp.id == payload.sonity_profile_id
      );

      triggerPinVerificationModal(payload);
    }

    if (event.type == "CAPTCHA_VERIFICATION") {
      let payload = JSON.parse(event.payload);
      payload.sonity_profile = state.sonityProfiles.find(
        sp => sp.id == payload.sonity_profile_id
      );

      triggerAnimalCaptchaVerificationModal(payload);
    }

    if (event.type == "VERIFICATION_SUCCESS") {
      closePinVerificationModal();
    }
  };

  const killDriver = event => {
    if (event.type == "DRIVER_KILLED") {
      let payload = JSON.parse(event.payload);

      let driverIndex = state.drivers.findIndex(
        driver => driver._id == payload._id
      );

      if (driverIndex >= 0) {
        let allDrivers = [
          ...state.drivers.slice(0, driverIndex),
          ...state.drivers.slice(driverIndex + 1)
        ];
        console.log("Deleted driver...");

        setDrivers(allDrivers);
      }
    }
  };
  const updateDriver = event => {
    if (event.type == "DRIVER_UPDATED") {
      
      let payload = JSON.parse(event.payload);

      console.log("A DRIVER_UPDATED event was recieved", {
        payload,
        drivers: state.drivers
      });
      let driverIndex = state.drivers.findIndex(
        driver => driver._id == payload._id
      );

      if (driverIndex >= 0) {
        let allDrivers = state.drivers;
        allDrivers[driverIndex] = payload;
        console.log("Updated drivers: ", allDrivers);

        setDrivers(allDrivers);
      } else {
        //add driver to end
        setDrivers([...state.drivers, {...payload }])
      }
    }
  };

  const createdDriver = event => {
    if (event.type == "DRIVER_CREATED") {
      
      let payload = JSON.parse(event.payload);
      console.log("A DRIVER_CREATED event was recieved", {
        payload,
        drivers: state.drivers
      });
      if (payload) {
        console.log("Adding driver: ", payload);
        setDrivers([...state.drivers, payload]);
      }
    }
  };

  const clearState = () => {
    dispatch({
      type: actionTypes.clear_state,
      payload:null
    });
    console.log("cleared state!", state)
  }

  return {
    setUser,
    setSonityAccounts,
    setSelectedSonityAccount,
    setSelectedCampaignDefinition,
    setCampaignDefinitions,
    setSelectedCampaignMessages,
    deleteSelectedCampaignMessage,
    setSelectedSonityProfile,
    setSonityProfiles,
    setSonityProfileToDelete,
    setMounted,
    setAnimalCaptchaVerification,
    setPinVerification,
    setActiveProfile,
    setUpdateDialog,
    setDrivers,
    setEventSubs,
    triggerAnimalCaptchaVerificationModal,
    triggerPinVerificationModal,
    toggleAccountSettingsModal,
    setMainLayoutState,
    updateSonityProfile,
    removeSonityProfile,
    updateSonityProfilePreferences,
    createCampaignDefinition,
    removeCampaignDefinition,
    triggerVerificationModals,
    updateDriver,
    createdDriver,
    killDriver,
    clearState
  };
};

export default mainLayoutStateFuncs;
