import { feathersClient } from "@/attach-client";
import actionTypes from "./ActionTypes";

export const stateFunctions = (state, dispatch, data) => {
  const { useListsHook, mainLayoutCtx, useTasksHook } = data;

  const {
    useMessageHook
  } = mainLayoutCtx

  const { sonityProfiles } = state;

  const {selectedSonityAccount} = mainLayoutCtx.state

  const [
    { getTasksResult, deleteTaskData },
    { getTasks, deleteTask }
  ] = useTasksHook;

  const [message, { updateMessage, hideMessage }] = useMessageHook;

  const [
    { getLists, deleteLists, updateList, deleteListItems },
    { handleExportContactsToCSV }
  ] = useListsHook;

  const getStatus = id => {
    if (id == 1) return "Upcoming";
    if (id == 2) return "Completed";
    if (id == 3) return "Error";
    if (id == 4) return "Blocked";
  };

  const handleEditList = list => {
    dispatch({
      type: actionTypes.set_state,
      payload: {
        currentList: list,
        openNewListModal: !state.openNewListModal,
        editList: true
      },
      source: "handleEditList"
    });
  };

  const setNewListSearchUrl = url => {
    dispatch({
      type: actionTypes.set_state, 
      payload: {
        newListSearchUrl: url
      }
    })
  }

  const handleNewList = () => {
    dispatch({
      type: actionTypes.toggle_new_list_modal,
      payload: null,
      source: "handleNewList"
    });
  };

  const handleExportList = (listName, contacts) => {
    console.log("Exporting contacts: ", contacts);
    updateMessage({
      isShown: true,
      variant: "info",
      content: "Exporting List..."
    });

    handleExportContactsToCSV(listName, contacts);
  };

  const handleDeleteLists = rows => {
    deleteListTasks(rows.map(r => r.id));

    deleteLists({
      variables: {
        values: rows.map(r => r.id),
        sonity_account_id: selectedSonityAccount.id
      }
    });

    let newLists = state.lists;

    for (let index = 0; index < rows.length; index++) {
      const row = rows[index];
      newLists = newLists.filter(n => n.id != row.id);
    }

    dispatch({
      type: actionTypes.set_lists,
      payload: newLists,
      source: "handleDeleteLists"
    });
  };

  const handleDeleteList = id => {
    /* TODO Delete upcoming task (if queued) for this list */

    deleteListTasks([id]);

    deleteLists({
      variables: {
        values: [id],
        sonity_account_id: selectedSonityAccount.id
      }
    });

    dispatch({
      type: actionTypes.set_lists,
      payload: state.lists.filter(l => l.id != id),
      source: "handleDeleteList"
    });
  };

  const closeListDetailModal = () => {
    dispatch({
      type: actionTypes.toggle_list_detail_modal,
      payload: null,
      source: "closeListDetailModal"
    });
  };

  const handleUpdateList = (id, name, type) => {
    let updateListIndex = state.lists.findIndex(l => l.id == id);
    let newLists = state.lists;

    newLists[updateListIndex] = {
      ...newLists[updateListIndex],
      name: name,
      type: type
    };

    dispatch({
      type: actionTypes.set_state,
      payload: {
        lists: newLists
      }
    });

    updateList({
      variables: {
        value: {
          id: id,
          name: name,
          type: type
        },
        sonity_account_id: selectedSonityAccount.id
      }
    });
  };

  const handleViewListDetail = list => {
    dispatch({
      type: actionTypes.set_state,
      payload: {
        currentList: list,
        openListDetailModal: state.openListDetailModal ? false : true
      },
      source: "handleViewListDetail"
    });
  };

  const handleDeleteContacts = rowIds => {
    deleteListItems({
      variables: {
        values: rowIds,
        list_id: state.currentList.id,
        sonity_account_id: selectedSonityAccount.id
      }
    });

    let newLists = state.lists;
    let newContacts = state.currentList.contacts;

    rowIds.map(id => {
      newContacts = newContacts.filter(n => n.id != id);
    });

    let index = state.lists.map(l => l.id).indexOf(state.currentList.id);
    newLists[index].contacts = newContacts;

    dispatch({
      type: actionTypes.set_state,
      payload: {
        lists: newLists,
        currentList: newLists[index]
      },
      source: "handleDeleteContacts"
    });
  };

  const closeNewListModal = () => {
    dispatch({
      type: actionTypes.set_state,
      payload: {
        newListStep: 0,
        currentList: {},
        openNewListModal: false
      },
      source: "closeNewListModal"
    });

    getAllUpcomingControlTasks();
  };

  const cancelNewListModal = () => {
    let newLists = state.lists;

    if (!state.editList) {
      let currentListId = state.currentList.id;
      newLists = state.lists.filter(l => l.id != currentListId);

      deleteListTasks([currentListId]);

      deleteLists({
        variables: {
          values: [currentListId],
          sonity_account_id: selectedSonityAccount.id
        }
      });
    }

    dispatch({
      type: actionTypes.set_state,
      payload: {
        newListStep: 0,
        currentList: {},
        openNewListModal: false,
        lists: newLists
      },
      source: "cancelNewListModal"
    });
  };

  const getAllLists = () => {
    const user = feathersClient.get('user');
    if(!user) return;
    getLists({
      variables: {
        user_id: user.id
      },
      fetchPolicy: "network-only"
    });
  };

  const processAndDisplayLists = listsData => {
    let processedListData = listsData.map(l => {
      return {
        ...l,
        status: "Loading..."
      };
    });

    dispatch({
      type: actionTypes.set_lists,
      payload: processedListData,
      source: "ListManager useEffect [getListsResults]"
    });

    // Now get tasks
    getAllUpcomingControlTasks();
  };

  const getAllUpcomingControlTasks = () => {
    sonityProfiles.map(sp => {
      getTasks({
        variables: {
          input: {
            sonity_profile_ids: sonityProfiles.map(sp => parseInt(sp.id)),
            type: "Control",
            task_status_id: 1
          }
        }
      });
    });
  };

  const deleteListTasks = listIds => {
    listIds.map(id => {
      let listTasks = state.listTasks.filter(t => t.listId == id);

      listTasks.map(t => {
        deleteTask({
          variables: {
            id: t.id,
          }
        });
      });
    });
  };

  function getListTasks(getTasksResult) {
    let tasks = getTasksResult.data.tasks;

    let listIds = state.lists.map(l => l.id);
    let newLists = state.lists;

    let listTasks = [];

    tasks.map(t => {
      let index = listIds.indexOf(t.payload?.list_id);

      if (index >= 0) {
        listTasks.push({
          ...t,
          listId: newLists[index].id
        });

        newLists[index] = {
          ...newLists[index],
          status: getStatus(t.task_status_id)
        };
      }
    });

    newLists = newLists.map(l => {
      return {
        ...l,
        status: l.status == "Loading..." ? "Completed" : l.status
      };
    });

    dispatch({
      type: actionTypes.set_state,
      payload: {
        lists: newLists,
        listTasks: state.listTasks.concat(listTasks)
      },
      source: "ListManager useEffect [getTasksResult]"
    });
  }

  function createListItemsFromCampaignRes(createListsItemsFromCampaignResults) {
    let updatedList =
      createListsItemsFromCampaignResults.data.create_list_items_from_campaign;
    updatedList.status = "Completed";

    let currentLists = state.lists;
    let index = currentLists.map(c => c.id).indexOf(updatedList.id);
    currentLists[index] = updatedList;

    dispatch({
      type: actionTypes.set_lists,
      payload: currentLists,
      source: "ListManager useEffect [createListsItemsFromCampaignResults]"
    });
  }

  function createListItemsFromFCs(createListItemsResults) {
    let updatedList = createListItemsResults.data.create_list_items;
    updatedList.status = "Completed";

    let currentLists = state.lists;
    let index = currentLists.map(c => c.id).indexOf(updatedList.id);
    currentLists[index] = updatedList;

    dispatch({
      type: actionTypes.set_lists,
      payload: currentLists,
      source: "ListManager useEffect [createListItemsResults]"
    });

    incrementNewListStep()
  }

  function createListItemsFromSearch(createListsItemsFromSearchResults) {
    let updatedList =
      createListsItemsFromSearchResults.data.create_list_items_from_search;
    updatedList.status = "Completed";

    let currentLists = state.lists;
    let index = currentLists.map(c => c.id).indexOf(updatedList.id);
    currentLists[index] = updatedList;

    dispatch({
      type: actionTypes.set_lists,
      payload: currentLists,
      source: "ListManager useEffect [createListsItemsFromSearchResults]"
    });
  }

  function incrementNewListStep() {
    dispatch({
      type: actionTypes.increment_new_list_step,
      payload: null,
      source: "onNextStep"
    });
  }

  function decrementNewListStep() {
    dispatch({
      type: actionTypes.decrement_new_list_step,
      payload: null,
      source: "onPreviousStep"
    });
  }

  function handleUpdateListResults(newLists, updatedList) {
    dispatch({
      type: actionTypes.set_state,
      payload: {
        lists: newLists,
        currentList: updatedList
      }
    });
  }

  function handleCreateListResults(newList) {
    dispatch({
      type: actionTypes.set_state,
      payload: {
        lists: [...state.lists, newList],
        currentList: newList
      },
      source: "newList useEffect"
    });
  }

  function setVisitMax(visitMax) {
    dispatch({
      type: actionTypes.set_state,
      payload: {
        visitMax: visitMax
      },
      source: ""
    });
  }

  function setListManagerState(newState) {
    dispatch({
      type: actionTypes.set_state,
      payload: {
        ...state,
        ...newState
      }
    });
  }

  function selectSonityProfiles(selectedProfiles) {
    dispatch({
      type: actionTypes.select_sonity_profiles,
      payload: selectedProfiles,
      source: "handleProfileChange"
    });
  }

  function triggerNextStep() {
    dispatch({
      type: actionTypes.trigger_next_step
    });
  }

  function toggleMoveToListModal() {
    dispatch({
      type: actionTypes.toggle_move_to_list_modal,
      payload: null,
      source: "handleClose"
    });
  }

  function resetNextStep() {
    dispatch({
      type: actionTypes.reset_next_step
    });
  }

  return {
    // export all functions
    handleEditList,
    handleNewList,
    handleExportList,
    handleDeleteLists,
    handleDeleteList,
    closeListDetailModal,
    handleUpdateList,
    handleViewListDetail,
    handleDeleteContacts,
    closeNewListModal,
    cancelNewListModal,
    getAllLists,
    processAndDisplayLists,
    getAllUpcomingControlTasks,
    deleteListTasks,
    getListTasks,
    createListItemsFromCampaignRes,
    createListItemsFromFCs,
    setNewListSearchUrl,
    setVisitMax,
    createListItemsFromSearch,
    incrementNewListStep,
    decrementNewListStep,
    resetNextStep,
    handleUpdateListResults,
    handleCreateListResults,
    setListManagerState,
    selectSonityProfiles,
    triggerNextStep,
    toggleMoveToListModal
  };
};
