import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer
} from "react";
import StepsTabReducer from "./stepsTabReducer";
import StepsTabState from "./stepsTabInitialState";
import StateTabStateFunctions from "./stepsTabStateFunctions";

import { useMainLayoutContext } from "@/contexts/mainLayoutState/MainLayoutProvider";

let StepsTabContext = createContext();

/**
 * @name StepsTabProvider
 * @type component
 * @desc This provide state, hooks and state functions for managing campaign steps on the "Steps Tab"
 * @prop {[Component]} children - A list of children component that will inherit the context
 * @prop {*} campaign_seed_data - Campaing data to seed 
 * @returns Provider
 */
export const StepsTabProvider = ({
  children,
  // selectedCampaignDefinition,
  // selectedSonityProfile,
  campaign_seed_data
}) => {
  const mainLayoutCtx = useMainLayoutContext();
  const {
    selectedCampaignDefinition,
    selectedSonityProfile
  } = mainLayoutCtx.state;

  const [state, dispatch] = useReducer(StepsTabReducer, {
    steps: [],
    prevSteps: [],
    selectedCampaignDefinition: null,
    selectedSonityProfile: null,
    campaign_seed_data: null,
    showCommitBar: false,
    commitStatus: "No Changes",
    textfieldArea: []
  });
  const stepsTabStateFuncs = StateTabStateFunctions(state, dispatch);

  useEffect(() => {
    if (selectedCampaignDefinition && selectedCampaignDefinition.id) {
      stepsTabStateFuncs.setCampaignDefinition(selectedCampaignDefinition);
    }
  }, [selectedCampaignDefinition]);

  useEffect(() => {
    if (selectedSonityProfile) {
      stepsTabStateFuncs.setSonityProfile(selectedSonityProfile);
    }
  }, [selectedSonityProfile]);

  useEffect(() => {
    if (campaign_seed_data) {
      stepsTabStateFuncs.setCampaignSeedData(campaign_seed_data);
    }
  }, [campaign_seed_data]);

  return (
    <StepsTabContext.Provider value={{ state, dispatch, stepsTabStateFuncs }}>
      {children}
    </StepsTabContext.Provider>
  );
};

/**
 * @name useStepsTabContext
 * @type function
 * @desc Returns StepsTaBContext value whoch is an object
 * @returns A context
 */
export const useStepsTabContext = () => {
  return useContext(StepsTabContext);
};
