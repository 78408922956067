import { useCallback, useEffect, useState } from "react"


const useWebworker = (workerFunction, inputData, libraryUrls = []) => {
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const memoizedWorkerFunction = useCallback(workerFunction, []);


  useEffect(() => {
    try {
      //${libraryUrls.map(url => `importScripts("${url}");`).join("\n")}
      const code = `
        (${workerFunction.toString()})();
      `;
      const blob = new Blob([code], { type: "application/javascript" });
      const workerScriptUrl = URL.createObjectURL(blob);
      const worker = new Worker(workerScriptUrl);

      setLoading(true);
      setError(null);

      worker.postMessage(inputData);

      worker.onmessage = (e) => {
        console.log("[useWebworker]onmessage", e);
        setResult(e.data);
        setLoading(false);
      };
      worker.onerror = (e) => {
        setError(e.message);
        setLoading(false);
      };

      return () => {
        worker.terminate();
        URL.revokeObjectURL(workerScriptUrl);
      };
    } catch (e) {
      console.error(e);
    }
  }, [inputData, memoizedWorkerFunction, libraryUrls]);

  return { result, loading, error }
}

export default useWebworker;
