import { SonityLocalStore, contextsList } from "../../utils/sonityLocalStore";
import actionTypes from "./ReportsActionTypes";


const sonityLocalStore = new SonityLocalStore();


const reportsReducer = (state, action) => {

  console.log("[Reports  Reducer]: ", {
    state,
    action
  });

  let newState = state;


  switch (action.type) {
    case actionTypes.set_slider_start_date:
      newState = {
        ...state,
        sliderStartDate: action.payload
      };
      break
    case actionTypes.set_time_window:
      newState = {
        ...state,
        timeWindow: [...action.payload]
      };
      break
    case actionTypes.set_selected_sonity_profiles:
      newState = {
        ...state,
        selected_sonity_profiles: action.payload
      };
      break
    case actionTypes.set_selected_campaign_definitions:
      newState = {
        ...state,
        selected_campaign_definitions: action.payload
      };
      break
    case actionTypes.set_cube_query_objects:
      newState = {
        ...state,
        cubeQueryObjects: action.payload
      };
      break
    case actionTypes.set_pivot_configs:
      newState = {
        ...state,
        pivotConfigs: action.payload
      };
      break
    case actionTypes.set_time_dimensions:
      newState = {
        ...state,
        timeDimensions: action.payload
      };
      break
    case actionTypes.set_loading:
      newState = {
        ...state,
        loading: action.payload
      };
      break
    case actionTypes.set_error:
      newState = {
        ...state,
        error: action.payload
      };
      break

    default:
      newState = state;
      break;
  }

  sonityLocalStore.storeContextState(contextsList.reports, newState);
  return newState;
};

export default reportsReducer;
