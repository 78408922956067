import Push from "push.js";

export const handleBrowserNotifications = (eventCreated, sonityProfiles) => {
  let type = eventCreated.type;

  let targetData = JSON.parse(eventCreated.payload);
  let data;
  if (Array.isArray(targetData)) { data = targetData[0]; }
  else { data = targetData }


  let sps = sonityProfiles;

  console.log("[handleBrowserNotifications]", { type, targetData, data })
  if (data && sps && sps.length > 0) {
    let sonityProfile = sps.find(sp => sp.id == data.sonity_profile_id);

    if (sonityProfile) {
      let notification = decodeNotification(type, data, sonityProfile);
      if (!notification) return;

      // Get preferences
      let profilePreferences = sonityProfile?.preferences?.preferences
      let preferences = profilePreferences === undefined ? {} : JSON.parse(profilePreferences);

      if (
        Object.keys(preferences).includes(notification.type) &&
        preferences[notification.type]
      ) {
        // TODO: We should add icon to make iot more intresting
        console.log("Encode base64 link to JSON obj: ", data);

        return Push.create(notification.message, {
          //link: `data:text/plain;base64,${atob(JSON.stringify(data))}`,
          timeout: 2500
        }).then(function (notification) {
          notification.close();
        }).catch(console.log);
      }



      return 0;
    }
  }
};

const decodeNotification = (type, data, sonityProfile) => {
  // decodes the notification type and gets the message

  try {
    let profileName = sonityProfile.contact?.first_name;

    console.log("browser notification prodfile Name: ", profileName)

    if (profileName == undefined) {
      profileName = "Your"
    } else {
      profileName = `${profileName}'s`
    }

    if (type == "PIN_VERIFICATION") {
      return {
        type: "verificationNotifications",
        message: `User action is required for verification`,
        data: data
      };
    }

    if (type == "VERIFICATION_PASSED") {
      return {
        type: "verificationNotifications",
        message: `Your profile was verified successfully`,
        data: data
      };
    }

    if (type.startsWith("DRIVER")) {
      if (data.status == "STARTING_UP") {
        return {
          type: "signInNotifications",
          message: `${profileName}'s driver is starting up`,
          data: data
        };
      }

      if (data.status == "BUSY") {
        let taskType = data?.current_task?.type;

        if (taskType == "extract-first-connections") {
          return {
            type: "extractFirstNotifications",
            message: `${profileName} driver is extracting first connections`,
            data: data
          };
        }

        if (taskType == "Synchronize Threads") {
          return {
            type: "syncThreadsNotifications",
            message: `${profileName} driver is syncing threads`,
            data: data
          };
        }
      }

      if (data.status == "ERROR") {
        return {
          type: "driverErrorNotifications",
          message: `${profileName} driver has failed`,
          data: data
        };
      }
    }
    if (type.startsWith("TASK")) {
      console.log("Parse task event:", data);
      const task = data.task ? data.task : data;
      const { payload, target, task_status_id, } = task;
      const { message_type, } = payload;
      const { l_id } = target;
      const to = l_id;
      //Note: status_flag/status is the new status of the TCP and not the task_status_id
      if (task_status_id !== 3) {
        console.log("Sending task completion notification...");
        return {
          type: "completedTaskNotifications",
          message: `${profileName}' "${message_type}" directed to ${to} has been completed`,
          data: data
        };
      }
      if (task_status_id == 3) {
        console.log("Sending task fail notification...");
        return {
          type: "taskErrorNotifications",
          message: `${profileName} failed trying to perform "${message_type} at ${to}`,
          data: data
        };
      }
    }

    return {
      type: "unknownNotification",
      message: "The notification is unknown",
      data: data
    };
  } catch (e) {
    console.log("Account not verified yet", e);
    return null;
  }
};
