import gql from "graphql-tag";

export const CREATE_PROXY = gql`
  mutation CreateProxy($input: ProxiesInput!) {
    create_proxy(input: $input) {
      id
      type
      name
      description
      host
      port
      username
      password
      created_at
      updated_at
      sonity_profile_id
    }
  }
`;

export const UPDATE_PROXY = gql`
  mutation UpdateProxy($input: ProxiesInput!) {
    update_proxy(input: $input) {
      id
      type
      name
      description
      host
      port
      username
      password
      created_at
      updated_at
      sonity_profile_id
    }
  }
`;

export const DELETE_PROXY = gql`
  mutation DeleteProxy($id: ID!) {
    delete_proxy(id: $id) {
      num_modified
      delete_proxy_id
    }
  }
`;
