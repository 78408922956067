// NOTE: We dont want to litter dispatch this or dispatch that everywhere
// Lets keep it in one place

import {
  getAppropriateObject,
  showCommitBar
} from "@/utils/campaignMessages";
import actionTypes from "./stepsTabActionTypes";

/**
 * @name StepsTabStateHelperFuncs
 * @type function
 * @param {Object} state - Reports state  
 * @param {Function} dispatch - A function for creating actions that trigger state change
 * @returns State functions
 */
const StepsTabStateHelperFuncs = (state, dispatch) => {
  const setState = payload => {
    dispatch({
      type: actionTypes.set_state,
      payload
    });
  };
  const setSteps = steps => {
    //steps coming in are new steps
    //state.steps are "old" steps

    dispatch({
      type: actionTypes.set_state,
      payload: {
        steps: [...steps],
        //prevSteps: steps,
        showCommitBar: showCommitBar(steps, state.prevSteps)
      }
    });
  };

  const setCampaignDefinition = cd => {
    dispatch({
      type: actionTypes.set_state,
      payload: {
        selectedCampaignDefinition: cd,
        steps: getAppropriateObject(cd),
        prevSteps: getAppropriateObject(cd)
      }
    });
  };

  const setSonityProfile = sp => {
    dispatch({
      type: actionTypes.set_state,
      payload: {
        selectedSonityProfile: sp
      }
    });
  };

  const discardChanges = () => {
    dispatch({
      type: actionTypes.set_state,
      payload: {
        commitStatus: "No Changes",
        showCommitBar: false,
        steps: state.prevSteps
      }
    });
  };

  const setCampaignSeedData = cs => {
    dispatch({
      type: actionTypes.set_state,
      payload: {
        campaign_seed_data: cs
      }
    });
  };

  const changeCommitBarStatus = (status, message = "No Changes") => {
    dispatch({
      type: actionTypes.set_state,
      payload: {
        showCommitBar: status,
        commitStatus: message
      }
    });
  };

  const updateTextFieldArea = payload => {
    const index = state.textfieldArea.findIndex(
      tf =>
        parseInt(tf.campaign_definition_id) ===
          parseInt(payload.campaign_definition_id) &&
        parseInt(tf.step) === parseInt(payload.step)
    );
    if (index > -1) {
      dispatch({
        type: actionTypes.set_state,
        payload: {
          textfieldArea: [
            ...state.textfieldArea.slice(0, index),
            { ...payload },
            ...state.textfieldArea.slice(index + 1)
          ]
        }
      });
    }
  };

  const setPrevSteps = steps => {
    dispatch({
      type: actionTypes.set_prev_steps,
      payload: steps
    });
  };

  const changeCommitStatus = message => {
    dispatch({
      type: actionTypes.change_commit_status,
      payload: message
    });
  };

  const handleSaveSteps = steps => {
    dispatch({
      type: actionTypes.set_state,
      payload: {
        commitStatus: "No Changes",
        showCommitBar: false
      }
    });
  };

  return {
    setState,
    setSteps,
    setPrevSteps,
    setCampaignDefinition,
    setSonityProfile,
    discardChanges,
    setCampaignSeedData,
    changeCommitStatus,
    changeCommitBarStatus,
    handleSaveSteps,
    updateTextFieldArea
  };
};

export default StepsTabStateHelperFuncs;
