import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  primary: {
    backgroundColor: theme.palette.primary.main
  },
  white: {
    backgroundColor: theme.palette.common.white
  },
  grey: {
    backgroundColor: theme.palette.grey[300]
  }
}));
const LoadingBars = ({ color, style }) => {
  const classes = useStyles();
  return (
    <>
      <div className={`loading-bar ${classes[color]}`} style={style}></div>
      <div className={`loading-bar ${classes[color]}`} style={style}></div>
      <div className={`loading-bar ${classes[color]}`} style={style}></div>
      <div className={`loading-bar ${classes[color]}`} style={style}></div>
    </>
  );
};

LoadingBars.defaultProps = {
  color: "primary",
  style: {}
};

LoadingBars.propTypes = {
  color: PropTypes.oneOf(["primary", "white", "grey"])
};
export default LoadingBars;
