import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from "@apollo/client/utilities";
import {
  concat,
  ApolloClient,
  ApolloLink,
  gql,
  split,
  InMemoryCache,
  HttpLink
} from "@apollo/client";


import { createClient } from 'graphql-ws';
import jwt_decode from "jwt-decode";


const rest = require("@feathersjs/rest-client");
const feathers = require("@feathersjs/client");
const auth = require("@feathersjs/authentication-client");
const socketio = require("@feathersjs/socketio-client");
const io = require("socket.io-client");

export const socket = io(`${process.env.REACT_APP_ENDPOINT}`, {
  transports: ["websocket"]
});

export const feathersClient = new feathers()
  .configure(socketio(socket))
  //.configure(rest().fetch(window.fetch))
  .configure(auth({ storage: window.localStorage }));

/* Setup client headers for general requests */
export const myFetch = async (url, body) => {
  const user = feathersClient.get("user");
  const method = body ? "POST" : "GET";

  try {
    //no options, default fetch
    if (!body) {
      const res = await fetch(url, {
        method,
        headers: {
          "content-type": "application/json",
          authorization:
            feathersClient.authentication.storage.storage["feathers-jwt"] || null,
          "X-Sonity-User-Id": user ? user.id : null
        }
      });
      return res
    }
    const res = await fetch(url, {
      method,
      headers: {
        "content-type": "application/json",
        authorization:
          feathersClient.authentication.storage.storage["feathers-jwt"] || null,
        "X-Sonity-User-Id": user ? user.id : null,
      },
      body: JSON.stringify(body)
    });
    return res;
  } catch (e) {
    console.error(e);
  }
};



export async function signoutUserLocal(user) {
  try {
    await feathersClient.logout();
    await gqlClient.clearStore();

  } catch (e) {
    console.error(e);
  }
}

// GRAPHQL SUBSCRIPTIONS
const authMiddleware = new ApolloLink(async (operation, forward) => {

  const tokenObj = jwt_decode(feathersClient.authentication.storage.storage["feathers-jwt"]);
  operation.setContext({
    headers: {
      authorization:
        feathersClient.authentication.storage.storage["feathers-jwt"] || null,
      "X-Sonity-User-Id": parseInt(tokenObj.sub) || null
    }
  });

  return forward(operation);
});

export const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_GRAPHQL_ENDPOINT}`,
  headers: async () => {
    const tokenObj = jwt_decode(feathersClient.authentication.storage.storage["feathers-jwt"]);
    return {
      authorization:
        feathersClient.authentication.storage.storage["feathers-jwt"] || null,
      "X-Sonity-User-Id": parseInt(tokenObj.sub) || null
    };
  }
});


export const wsLink = new GraphQLWsLink(createClient({
  url: `${process.env.REACT_APP_SUBSCRIPTIONS_ENDPOINT}`,
  connectionParams: async () => {
    const tokenObj = jwt_decode(feathersClient.authentication.storage.storage["feathers-jwt"]);
    return {
      authorization:
        feathersClient.authentication.storage.storage["feathers-jwt"] || null,
      "X-Sonity-User-Id": parseInt(tokenObj.sub) || null
    };
  },
  reconnect: true
}));

const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    //console.debug({ query: query, kind: kind, operation: operation });
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  httpLink
);

/* TODO: remove typeDefs/resolvers and cache as only link and middleware used */
const typeDefs = gql`
  extend type Query {
    lostReasonOptions: [LostReasonOption]
    cardDetailsFormValid: Boolean!
    currentUser: User
  }

  type LostReasonOption {
    value: String!
    label: String!
  }

  type Message {
    isShown: Boolean!
    content: String!
    variant: String!
  }

  type User {
    created_at: String!
    email: String!
    id: Number!
    sonity_account_id: Number!
    sonity_profile_id: Number
    updated_at: String!
    user_role_id: Number!
    user_status_id: Number!
  }

  type SeedData {
    created_at: String
    extensions: String
    id: Number
    name: String
    set_active: Boolean
    sonity_profile: String
    sonity_profile_id: String
    start_date: String
    updated_at: String
  }
`;

const resolvers = {
  /* Query: {
    currentUser: async () => {
      const user = await feathersClient.get('user')
      return { __typename: 'User', ...user }
    }
  }, 
  Mutation: {
    updateMessage: (_, { updatedMessage }, { cache }) => {
      cache.writeQuery({
        query: GET_MESSAGE,
        data: { message: { __typename: "Message", ...updatedMessage } }
      });
    },
    setCampaignSeedData: (_, { campaignSeedData: seedData }, { cache }) => {
      console.log("SET SEED DATA", seedData);
      cache.writeQuery({
        query: CAMPAIGN_SEED_DATA,
        data: {
          campaignSeedData: {
            ...seedData,
            __typename: "SeedData"
          }
        }
      });
    }
  }*/
};

const cache = new InMemoryCache();



export let gqlClient = new ApolloClient({
  link: concat(authMiddleware, link),
  cache,
  typeDefs,
  resolvers,
  connectToDevTools: false
});

export function refreshGqlClient() {
  gqlClient = new ApolloClient({
    link: concat(authMiddleware, link),
    cache,
    typeDefs,
    resolvers,
    connectToDevTools: false
  });
}

export const getGqlClient = () => { return gqlClient; }