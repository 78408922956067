import actionTypes from "./ReportsActionTypes";

/**
 * @name stateFunctions
 * @type function
 * @desc This function wraps state functions that can change the Reports state change
 * @param {Object} state - Reports state
 * @param {Function} dispatch - This function creates an action that can trigger state changes
 * @returns - functions that can change the ReportsContext State
 */
const stateFunctions = (state, dispatch) => {
  const setState = newState => {
    dispatch({
      type: actionTypes.set_state,
      payload: newState
    });
  };
  const setSliderStartDate = sd => {
    dispatch({
      type: actionTypes.set_slider_start_date,
      payload: sd
    });
  };
  const setTimeWindow = w => {
    dispatch({
      type: actionTypes.set_time_window,
      payload: w
    });
  };

  const setSelectedSonityProfiles = sps => {
    dispatch({
      type: actionTypes.set_selected_sonity_profiles,
      payload: sps
    });
  };

  const setSelectedCampaignDefinitions = cds => {
    dispatch({
      type: actionTypes.set_selected_campaign_definitions,
      payload: cds
    });
  };

  const setLoading = mode => {
    dispatch({
      type: actionTypes.set_loading,
      payload: mode
    });
  };

  const setError = mode => {
    dispatch({
      type: actionTypes.set_error,
      payload: mode
    });
  };

  const setCubeQueryObjects = objs => {
    dispatch({
      type: actionTypes.set_cube_query_objects,
      payload: objs
    });
  };
  const setPivotConfigs = configs => {
    dispatch({
      type: actionTypes.set_pivot_configs,
      payload: configs
    });
  };
  const setTimeDimensions = dims => {
    dispatch({
      type: actionTypes.set_time_dimensions,
      payload: dims
    });
  };
  return {
    setState,
    setSliderStartDate,
    setTimeWindow,
    setSelectedSonityProfiles,
    setSelectedCampaignDefinitions,
    setCubeQueryObjects,
    setPivotConfigs,
    setTimeDimensions,
    setLoading,
    setError
  };
};

export default stateFunctions;
