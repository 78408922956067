import React from "react";
import makeStyles from '@mui/styles/makeStyles';

import { Avatar, Button } from "@mui/material";

import defaultAvatar from "./default_photo.png";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  pseudoBorder: {
    borderRadius: "2rem"
  },
  avatarRoot: {
    border: `0px solid pink`,
    transition: `border 1s linear`
  },
  avatarRootDriverOffline: {
    border: `2px dashed red`,
    transition: `border 1s linear`
  },
  avatarRootDriverBusy: {
    border: `2px solid green`,
    transition: `border 1s linear`
  },
  avatarRootDriverAvailable: {
    border: `2px solid green`,
    transition: `border 1s linear`
  },
  avatarRootDriverRefractory: {
    border: `2px solid yellow`,
    transition: `border 1s linear`
  },
  avatarRootDriverError: {
    border: `2px solid red`,
    transition: `border 1s linear`
  },
  avatarImg: {
    transition: `none`
  }
}));

const AvatarWithBorder = props => {
  const classes = useStyles();

  const { driver, sonity_profile } = props;
  const {
    id,
    contact_id,
    email,
    name,
    //preferences,
    password,
    profile_image,
    status,
    proxy
  } = sonity_profile;

  function genStatusStyle() {
    if (driver) {
      switch (driver.status) {
        case "AVAILABLE":
          return classes.avatarRootDriverAvailable;
        case "STARTING_UP":
        case "SIGNING_IN":
        case "ATTACHING_MESSENGER":
        case "BUSY":
          return classes.avatarRootDriverBusy;
        case "REFRACTORY":
          return classes.avatarRootDriverRefractory;
        case "ERROR":
          return classes.avatarRootDriverError;
        default:
          return classes.avatarRoot;
      }
    }
    return classes.avatarRoot;
  }

  function tagAvatarClassName() {
    if (typeof driver === "undefined") return `user_avatar default`;
    if (driver && driver.status) {
      switch (driver.status) {
        case "AVAILABLE":
          return `user_avatar available`;

        case "EXTRACT_FIRST_CONNECTIONS":
        case "STARTING_UP":
        case "SYNC_THREADS":
        case "SYNC_INMAIL_THREADS":
        case "SIGNING_IN":
        case "ATTACHING_MESSENGER":
        case "BUSY":
          return `user_avatar blinking`;

        case "ERROR":
          return `user_avatar error`;

        case "REFRACTORY":
          return `user_avatar refractory`;
        default:
          return `user_avatar default`;
      }
    }
    return `user_avatar`;
  }

  return (
    <span
      className={`
      ${tagAvatarClassName()}`}
    >
      <Avatar
        alt={email}
        src={
          profile_image && profile_image.thumb
            ? profile_image.thumb
            : defaultAvatar
        }
        classes={{
          root: genStatusStyle(),
          img: classes.avatarImg
        }}
      />
    </span>
  );
};
export default AvatarWithBorder;
