const actionTypes = {
  set_lists: "SET_LISTS",
  add_list: "ADD_LIST",
  set_current_list: "SET_CURRENT_LIST",
  change_current_list_name: "CHANGE_CURRENT_LIST_NAME",
  toggle_edit_list: "TOGGLE_EDIT_LIST",
  toggle_new_list_modal: "TOGGLE_NEW_LIST_MODAL",
  toggle_list_detail_modal: "TOGGLE_LIST_DETAIL_MODAL",
  toggle_move_to_list_modal: "TOGGLE_MOVE_TO_LIST_MODAL",
  set_move_contacts: "SET_MOVE_CONTACTS",
  set_state: "SET_STATE",
  increment_new_list_step: "INCREMENT_NEW_LIST_STEP",
  decrement_new_list_step: "DECREMENT_NEW_LIST_STEP",
  reset_new_list_step: "RESET_NEW_LIST_STEP",
  set_import_method: "SET_IMPORT_METHOD",
  set_sonity_profiles: "SET_SONITY_PROFILES",
  select_sonity_profiles: "SELECT_SONITY_PROFILES",
  trigger_next_step: "TRIGGER_NEXT_STEP",
  reset_next_step: "RESET_NEXT_STEP"
};

export default actionTypes;
