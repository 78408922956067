import React from "react";
import { useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { UPSERT_PLACEHOLDER, BULK_CREATE_PLACEHOLDERS } from "../gql/mutations/Placeholders";
import { GET_PLACEHOLDERS } from "../gql/queries/Placeholders";


const usePlaceholders = (currentTask, updateMessage) => {

  const [placeholders, setPlaceholders] = React.useState(null);

  const [getPlaceholders, getPlaceholdersResult] = useLazyQuery(
    GET_PLACEHOLDERS,
    {
      fetchPolicy: "network-only",
      onCompleted: data => {

        if (data.placeholders.length > 0) {
          setPlaceholders(data.placeholders[0]);
        }
      }
    }
  );

  const [upsertPlaceholder, upsertPlaceholderResult] = useMutation(
    UPSERT_PLACEHOLDER,
    {
      onCompleted: data => {
        if (data.upsert_placeholder) {
          setPlaceholders(data.upsert_placeholder);

          updateMessage({
            isShown: true,
            variant: "success",
            content: "Placeholders updated successfully."
          });
        }
      },
      onError: data => {
        updateMessage({
          isShown: true,
          variant: "error",
          content: "Error updating placeholders."
        });
      }
    }
  );


  const [bulkCreatePlaceholders, bulkCreatePlaceholdersResult] = useMutation(
    BULK_CREATE_PLACEHOLDERS,
    {
      onCompleted: data => {
        if (data.bulk_create_placeholders) {
          console.log("[bulkCreatePlaceholders]", data.bulk_create_placeholders)
          updateMessage({
            isShown: true,
            variant: "success",
            content: "Placeholders updated successfully."
          });
        }
      },
      onError: data => {
        updateMessage({
          isShown: true,
          variant: "error",
          content: "Error updating placeholders."
        });
      }
    }
  );


  // useEffect(() => {
  //   if (currentTask) loadPlaceholders(currentTask);
  // }, [currentTask]);

  return [
    {
      getPlaceholdersResult,
      upsertPlaceholderResult,
      bulkCreatePlaceholdersResult,
      setPlaceholders
    },
    {
      placeholders,
      getPlaceholders,
      bulkCreatePlaceholders,
      upsertPlaceholder
    }
  ];
};




export { usePlaceholders };
