import React from "react";

import {
  Checkbox,
  TextField,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Grid,
  Paper,
  FormControlLabel
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export const DesiredEvents = ({ allWebhookEvents, onChange }) => {
  const handleChange = (event, index) => {
    let newEvents = allWebhookEvents;
    newEvents[index].enabled = event.target.checked;
    onChange(newEvents);
  };

  return (
    <div>
      {allWebhookEvents.map((webhookEvent, index) => (
        <FormControlLabel
          key={index}
          control={
            <Checkbox
              checked={webhookEvent.enabled}
              onChange={e => handleChange(e, index)}
              name={webhookEvent.event_name}
              color="primary"
            />
          }
          label={webhookEvent.event_description}
        />
      ))}
    </div>
  );
};

export const WebhookTable = ({
  webhooks,
  webhookEvents,
  onRemoveEndpoint,
  onEditEndpoint,
  onDeleteWebhook,
  classes,
  disabled
}) => {
  const [processedWebhooks, setProcessedWebhooks] = React.useState([]);

  function createData(id, path, endpoints) {
    return { id, path, endpoints };
  }

  const pathIsEnabled = path => {
    let enabled = false;

    let filteredEvents = webhookEvents.filter(
      event => path.includes(event.event_name) && event.enabled
    );

    if (filteredEvents.length > 0) {
      enabled = true;
    }

    return enabled;
  };

  const deleteWebhook = webhook => {
    onDeleteWebhook(webhook);
  };
  const deleteWebhookEndpoint = webhook => {
    onRemoveEndpoint(webhook);
  };

  const editWebhookEndpoint = webhook => {
    onEditEndpoint(webhook);
  };

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: "bold" }}>Path</TableCell>
            <TableCell align="left" style={{ fontWeight: "bold" }}>
              Endpoint
            </TableCell>

            <TableCell
              align="left"
              style={{ fontWeight: "bold", minWidth: 20 }}
            >
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {webhooks.map((row, index) =>
            pathIsEnabled(row.path) ? (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {row.path}
                </TableCell>
                <TableCell align="left">{row.endpoints.join(",\n")}</TableCell>
                <TableCell align="left">
                  <Grid container>
                    <Grid item>
                      <IconButton
                        style={{ padding: 0 }}
                        onClick={() => editWebhookEndpoint(row)}
                        aria-label="edit"
                        size="large">
                        <EditIcon />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton
                        style={{ padding: 0, marginLeft: 4 }}
                        onClick={() => deleteWebhook(row)}
                        aria-label="delete"
                        color="secondary"
                        size="large">
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ) : null
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const AddWebhook = ({
  classes,
  disabled,
  user,
  onAddWebhook,
  webhookEvents
}) => {
  const [webhookPath, setWebhookPath] = React.useState(
    webhookEvents[0].event_name
  );
  const [endpoint, setEndpoint] = React.useState("");

  const handlePathChange = event => {
    setWebhookPath(event.target.value);
  };

  const handleEndpointChange = event => {
    setEndpoint(event.target.value);
  };

  const handleSubmit = () => {
    let webhook = {
      path: `/${user.id}/${webhookPath}`,
      endpoint: endpoint
    };

    onAddWebhook(webhook);
    setEndpoint("");
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
          Webhook
        </InputLabel>
        <Select
          labelId="demo-simple-select-placeholder-label-label"
          id="demo-simple-select-placeholder-label"
          value={webhookPath}
          onChange={handlePathChange}
          displayEmpty
          className={classes.selectEmpty}
          disabled={disabled}
        >
          {webhookEvents.map((webhookEvent, index) =>
            webhookEvent.enabled ? (
              <MenuItem key={index} value={webhookEvent.event_name}>
                {webhookEvent.event_description}
              </MenuItem>
            ) : null
          )}
        </Select>
        {/* <FormHelperText>Webhook name</FormHelperText> */}
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel shrink>Endpoint URL</InputLabel>
        <TextField
          disabled={disabled}
          value={endpoint}
          onChange={handleEndpointChange}
          required
          id="standard-required"
          label="Endpoint URL"
        />
        {/* <FormHelperText>URL that will be called</FormHelperText> */}
      </FormControl>
      <FormControl className={classes.formControl}>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={disabled}
        >
          Add
        </Button>
      </FormControl>
    </div>
  );
};

export const EditWebhook = ({
  classes,
  disabled,
  user,
  selectedPath,
  editWebhook,
  editWebhookIndex,
  onUpdateWebhook,
  onEditCancel,
  webhookEvents
}) => {
  const [webhookEvent, setWebhookEvent] = React.useState(selectedPath);
  const [webhook, setWebhook] = React.useState(editWebhook);

  const handleChange = event => {
    setWebhookEvent(event.target.value);
  };

  const handleUpdateClick = () => {
    console.log("handleupdateclick", {
      webhook,
      editWebhookIndex
    });
    onUpdateWebhook(webhook, editWebhookIndex);
  };

  const updateWebhookEndpoint = (index, endpoint) => {
    console.log("endpoint ", {
      index,
      webhook,
      endpoint
    });
    let oldWebhook = webhook;
    oldWebhook.endpoints[index] = endpoint;
    setWebhook({ ...oldWebhook });
  };

  const deleteWebhookEndpoint = index => {
    let oldWebhook = webhook;
    console.log("delete ", {
      index,
      webhook
    });
    oldWebhook.endpoints.splice(index, 1);
    setWebhook({ ...oldWebhook });
  };

  const addNewWebhookEndpoint = event => {
    let oldWebhook = webhook;
    oldWebhook.endpoints.push("");
    setWebhook({ ...oldWebhook });
  };

  React.useEffect(() => {
    setWebhookEvent(selectedPath);
  }, [selectedPath]);

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
          Edit Webhook
        </InputLabel>
        <Select
          labelId="demo-simple-select-placeholder-label-label"
          id="demo-simple-select-placeholder-label"
          disabled={disabled}
          value={webhookEvent}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
        >
          {webhookEvents.map((webhookEvent, index) =>
            webhookEvent.enabled ? (
              <MenuItem key={index} value={webhookEvent.event_name}>
                {webhookEvent.event_description}
              </MenuItem>
            ) : null
          )}
        </Select>
        {/* <FormHelperText>Webhook name</FormHelperText> */}
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel shrink>Endpoint URLs</InputLabel>
        {webhook.endpoints.map((endpoint, index) => (
          <Grid container key={index}>
            <Grid item md={10} sm={10}>
              <TextField
                disabled={disabled}
                value={endpoint}
                onChange={event =>
                  updateWebhookEndpoint(index, event.target.value)
                }
                onKeyPress={event => {
                  if (event.key === "Enter") {
                    addNewWebhookEndpoint(event);
                  }
                }}
                required
                style={{
                  width: "100%"
                }}
                id="standard-required"
                label="Endpoint URL"
              />
            </Grid>
            <Grid item md={2} sm={2}>
              <IconButton
                disabled={disabled}
                style={{ position: "relative", top: "1rem", right: "0.2rem" }}
                onClick={() => deleteWebhookEndpoint(index)}
                aria-label="delete"
                color="secondary"
                size="large">
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </FormControl>
      <FormControl className={classes.formControl}>
        <Button
          className={classes.actionButton}
          onClick={handleUpdateClick}
          variant="contained"
          color="primary"
          disabled={disabled}
        >
          Update
        </Button>
        <Button
          className={classes.actionButton}
          onClick={onEditCancel}
          variant="contained"
          color="secondary"
          disabled={disabled}
        >
          Cancel
        </Button>
      </FormControl>
    </div>
  );
};
