import React from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from "@mui/material";

import {
  feathersClient
  //feathersWebhooksClient
} from "@/attach-client";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useStyles from "./styles/webhooksPanelStyles";

import {
  WebhookTable,
  AddWebhook,
  EditWebhook
} from "./components/webhookPanelComponents";
import WebhookPanelEvents from "./utils/webhookPanelEvents";

const defaultWebhookEvents = [
  {
    id: 0,
    event_name: "requests",
    event_description: "Request Sent",
    enabled: true
  },
  {
    id: 1,
    event_name: "connected",
    event_description: "Connection Established",
    enabled: true
  },
  {
    id: 2,
    event_name: "welcome_messages",
    event_description: "Welcome Message Sent",
    enabled: true
  },
  {
    id: 3,
    event_name: "disconnected",
    event_description: "Disconnected",
    enabled: true
  },
  {
    id: 4,
    event_name: "inmail_request",
    event_description: "InMail Request Sent",
    enabled: true
  },
  {
    id: 5,
    event_name: "inmail_accepted",
    event_description: "InMail Accepted",
    enabled: true
  },
  {
    id: 6,
    event_name: "inmail_denied",
    event_description: "InMail Denied",
    enabled: true
  },
  {
    id: 7,
    event_name: "replied_to_invite",
    event_description: "Replied to Invitation",
    enabled: true
  },
  {
    id: 8,
    event_name: "replied_to_other",
    event_description: "Replied to Other Campaign Message",
    enabled: true
  },
  {
    id: 9,
    event_name: "replied_to_inmail",
    event_description: "Replied to InMail Message",
    enabled: true
  },
  {
    id: 10,
    event_name: "chat_message_received",
    event_description: "Chat message received",
    enabled: true
  },
  {
    id: 11,
    event_name: "chat_message_sent",
    event_description: "Chat message sent",
    enabled: true
  },
  {
    id: 12,
    event_name: "not_suitable",
    event_description: "Not Suitable",
    enabled: true
  },
  {
    id: 13,
    event_name: "follow_up_later",
    event_description: "Follow Up Later",
    enabled: true
  },
  {
    id: 14,
    event_name: "do_not_contact",
    event_description: "Do Not Contact",
    enabled: true
  },
  {
    id: 15,
    event_name: "talking",
    event_description: "Talking",
    enabled: true
  },
  {
    id: 16,
    event_name: "closed",
    event_description: "Closed",
    enabled: true
  },
  {
    id: 17,
    event_name: "search",
    event_description: "Search",
    enabled: true
  },
  {
    id: 18,
    event_name: "campaign_messages",
    event_description: "Campaign Message",
    enabled: true
  },
  {
    id: 19,
    event_name: "not_responding",
    event_description: "Not Responding",
    enabled: true
  }
];

const WebhooksPanel = ({ handleExpand, expanded }) => {
  const classes = useStyles();
  let user = feathersClient.get("user");

  const [webhookEvents, setWebhookEvents] = React.useState(
    defaultWebhookEvents
  );
  const [userWebhooks, setUserWebhooks] = React.useState([]);
  const [editMode, setEditMode] = React.useState(false);
  const [editWebhook, setEditWebhook] = React.useState({});
  const [editWebhookIndex, setEditWebhookIndex] = React.useState(0);
  const [editEndpointIndex, setEditEndpointIndex] = React.useState(0);

  function handleEditCancel(e) {
    setEditMode(false);
    setEditWebhook(0);
    setEditEndpointIndex(0);
  }
  React.useEffect(() => {
    console.log("Webhook settings mount:");
    //for developing with new custom webhooks service
    feathersClient
      .service("sonity-account-webhooks")
      .find({
        query: { sonity_account_id: user.sonity_account_id, $limit: 20 }
      })
      .then(userWebhooks => {
        console.log("User Webhooks Before Filter: ", userWebhooks);
        let allWebhooks = userWebhooks.data.filter(
          webhook => webhook.sonity_account_id === user.sonity_account_id
        );
        setUserWebhooks(allWebhooks);

        console.log("All User Webhook", allWebhooks);
      });

    return () => {
      console.log("Webhook settings dismount");
    };
  }, []);

  // React.useEffect(() => {
  //   console.log("webhooks service client: ", feathersWebhooksClient);
  //   feathersWebhooksClient
  //     .service("sonity-account-webhooks")
  //     .find({ query: { user_id: user.id, $limit: 20 } })
  //     .then(userWebhooks => {
  //       console.log("User Webhooks Before Filter: ", userWebhooks);
  //       let allWebhooks = userWebhooks.data.filter(
  //         webhook => webhook.user_id === user.id
  //       );
  //       //setUserWebhooks(allWebhooks);

  //       console.log("feathersWebhooksClient : All User Webhooks", allWebhooks);
  //     });
  // }, [feathersWebhooksClient]);

  const {
    onDesiredEventsChange,
    deleteWebhook,
    deleteEndpoint,
    addNewWebhook,
    editWebhookEndpoint,
    updateWebhook
  } = WebhookPanelEvents(
    user,
    setWebhookEvents,
    userWebhooks,
    setUserWebhooks,
    editWebhookIndex,
    editEndpointIndex,
    setEditWebhookIndex,
    setEditEndpointIndex,
    setEditMode,
    setEditWebhook,
    feathersClient
  );

  return (
    <div className={classes.root}>
      <Accordion
        onChange={handleExpand.bind(null, "webhooks")}
        expanded={expanded}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className={classes.column}>
            <Typography className={classes.heading}>
              Webhooks Settings
            </Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>
              Add, remove or modify webhooks for Sonity Events
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          {/* <div className={classes.webhookSettingsContainer}>
            <Typography>Desired Events</Typography>
            <DesiredEvents
              allWebhookEvents={webhookEvents}
              onChange={onDesiredEventsChange}
            />
          </div> */}

          <div className={classes.webhookSettingsContainer}>
            <Typography>User Webhooks</Typography>
            <WebhookTable
              classes={classes}
              webhooks={userWebhooks}
              webhookEvents={webhookEvents}
              onAddWebhook={() => {}}
              onRemoveEndpoint={deleteEndpoint}
              onEditEndpoint={editWebhookEndpoint}
              onDeleteWebhook={deleteWebhook}
            />
          </div>

          {editMode && (
            <div className={classes.webhookSettingsContainer}>
              <Typography>Edit Endpoint</Typography>
              <EditWebhook
                classes={classes}
                user={user}
                selectedPath={
                  editWebhook.path.split("/").filter(i => i !== "")[1]
                }
                webhookEvents={webhookEvents}
                onUpdateWebhook={updateWebhook}
                onEditCancel={handleEditCancel}
                editWebhook={editWebhook}
                editWebhookIndex={editWebhookIndex}
              />
            </div>
          )}

          {!editMode && (
            <div className={classes.webhookSettingsContainer}>
              <Typography>Add Endpoint</Typography>
              <AddWebhook
                classes={classes}
                user={user}
                webhookEvents={webhookEvents}
                onAddWebhook={addNewWebhook}
              />
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
// DriverPanel.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

export default WebhooksPanel;
