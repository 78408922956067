import gql from "graphql-tag";

export const BULK_SEARCH_RESULT_CREATE = gql`
  mutation bulkSearchResultCreate(
    $campaign_definition_id: ID!
    $sonity_profile_id: ID!
    $values: [BulkSearchResult!]!
  ) {
    bulk_search_result_create(
      campaign_definition_id: $campaign_definition_id
      sonity_profile_id: $sonity_profile_id
      values: $values
    ) {
      id
      l_id
      sonity_profile_id
      campaign_definition_id
      campaign_status_id
      step
      contact {
        id
        l_id
        email
        websites
        profile_image {
          id
          thumb
        }
      }
      prospect {
        id
        contact_l_id
        profile_name
        profile_description
        profile_location
      }
    }
  }
`;

export const DELETE_TRACKING_CAMPAIGN_PROSPECT = gql`
  mutation DeleteTrackingCampaignProspect($tracking_campaign_prospect_id: ID!, $sonity_profile_id: ID) {
    delete_tracking_campaign_prospect(
      tracking_campaign_prospect_id: $tracking_campaign_prospect_id
      sonity_profile_id: $sonity_profile_id
    ) {
      num_modified
    }
  }
`;

export const DELETE_TCP_FROM_CAMPAIGNS = gql`
  mutation DeleteTCPFromCampaings($l_id: String!, $campaigns: [String!]!) {
    delete_tcps_from_campaigns(l_id: $l_id, campaigns: $campaigns)
  }
`;

export const DELETE_TRACKING_CAMPAIGN_PROSPECTS = gql`
  mutation DeleteTrackingCampaignProspects($values: [ID!]!) {
    delete_tracking_campaign_prospects(values: $values) {
      num_modified
    }
  }
`;

export const REVERT_TRACKING_CAMPAIGN_PROSPECTS_TABLE_STATUS = gql`
  mutation revertTrackingCampaignProspectsTableStatus(
    $campaign_definition_id: ID!
    $sonity_profile_id: ID!
  ) {
    revert_tracking_campaign_prospects_table_status(
      campaign_definition_id: $campaign_definition_id
      sonity_profile_id: $sonity_profile_id
    ) {
      id
      campaign_status_id
    }
  }
`;

export const CREATE_TRACKING_CAMPAIGN_PROSPECTS = gql`
  mutation bulkTrackingCampaignProspectsCreate(
    $values: [TrackingCampaignProspectsInput!]!
  ) {
    bulk_create_tracking_campaign_prospects(values: $values) {
      id
      l_id
      step
      contact {
        l_id
        email
        websites
        profile_image {
          id
          thumb
        }
        prospect {
          id
          contact_l_id
          profile_name
          profile_description
          profile_location
        }
      }

      first_connection {
        id
        initiator_l_id
        acceptor_l_id
        thread_id
      }
    }
  }
`;

export const UPDATE_TRACKING_CAMPAIGN_PROSPECTS_TABLE_STATUS = gql`
  mutation updateTrackingCampaignProspectsTableStatus(
    $sonity_account_id: ID
    $sonity_profile_id: ID
    $new_status_id: ID!
    $values: [ID!]
  ) {
    bulk_update_tracking_campaign_prospect_table_status(
      sonity_profile_id: $sonity_profile_id
      sonity_account_id: $sonity_account_id
      new_status_id: $new_status_id
      values: $values
    ) {
      id
      campaign_status_id
    }
  }
`;

export const UPDATE_TRACKING_CAMPAIGN_PROSPECT_TABLE_STATUS = gql`
  mutation updateTrackingCampaignProspectTableStatus(
    $id: ID!
    $new_status_id: ID!
    $sonity_account_id: ID
    $sonity_profile_id: ID
  ) {
    update_tracking_campaign_prospect_table_status(
      id: $id
      new_status_id: $new_status_id
      sonity_profile_id: $sonity_profile_id
      sonity_account_id: $sonity_account_id
    ) {
      id
      campaign_status_id
    }
  }
`;

export const UPDATE_TRACKING_CAMPAIGN_PROSPECTS = gql`
  mutation updateTrackingCampaignProspects(
    $values: [TrackingCampaignProspectsInput!]!
  ) {
    update_tracking_campaign_prospects(values: $values) {
      id
      step
      l_id
      campaign_status_id
      campaign_definition_id
      contact_id
      prospect_id
      updated_at
      created_at
    }
  }
`;

export const BULK_CLONE_TO_CAMPAIGN = gql`
  mutation bulkCloneToCampaign(
    $campaign_definition_id: ID!
    $tcps: [TrackingCampaignProspectsInput!]!
  ) {
    bulk_clone_to_campaign(
      campaign_definition_id: $campaign_definition_id
      tcps: $tcps
    ) {
      id
      step
      l_id
      campaign_status_id
      sonity_profile_id
      campaign_definition_id
      contact_id
      prospect_id
      updated_at
      created_at
    }
  }
`;

export const UPDATE_TRACKING_CAMPAIGN_PROSPECT = gql`
  mutation updateTrackingCampaignProspect(
    $input: TrackingCampaignProspectsInput!
  ) {
    update_tracking_campaign_prospect(input: $input) {
      id
      l_id
      step
      campaign_definition_id
      campaign_status_id
      contact_id
      prospect_id
      updated_at
      created_at
    }
  }
`;

export const REQUEUE_WITHDRAWN = gql`
  mutation updateTrackingCampaignProspect(
     $campaign_definition_id: ID!
  ) {
    requeue_withdrawn(campaign_definition_id: $campaign_definition_id) {
      id
      l_id
      step
      campaign_definition_id
      campaign_status_id
      contact_id
      prospect_id
      updated_at
      created_at
    }
  }
`;



