import React from "react";
import { Link, useHistory } from "react-router-dom";

import { signoutUserLocal, feathersClient } from "../../../attach-client";
import makeStyles from '@mui/styles/makeStyles';
import {
  AppBar,
  Button,
  Hidden,
  IconButton,
  Toolbar,
  Grid,
  Tooltip,
  useMediaQuery
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ReportsIcon from "@mui/icons-material/Equalizer";
import LogoutIcon from "@mui/icons-material/ExitToApp";
import ProfileTab from "./ProfileTab";
import AccountMenu from "./AccountMenu";

import GenericCampaignDropdownMenu from "./GenericCampaignDropdownMenu";
import { useMainLayoutContext } from "../../../contexts/mainLayoutState/MainLayoutProvider";
import AccountSettingsDialog from "../AccountSettings/AccountSettingsDialog";


import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { SonityLocalStore } from "../../../utils/sonityLocalStore";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.default
  },
  divider: {
    flexGrow: 1
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    position: 'relative'
  },
  mainToolbar: {
    width: "100%",
    [theme.breakpoints.down('xl')]: {
      display: "none"
    },
    [theme.breakpoints.up("md")]: {
      display: "block"
    }
  },
  hiddenToolbar: {
    [theme.breakpoints.down('xl')]: {
      display: "block"
    },
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  }
}));


export default ({ openNavigation, hideAppBar }) => {
  const classes = useStyles();
  const { push } = useHistory();

  let mainLayoutCtx = useMainLayoutContext();

  const {
    accountSettingsModalOpen,
    faqModalOpen,
    campaignDefinitions,
    selectedCampaignDefinition,
    selectedSonityProfile,
    sonityProfiles,
    drivers,
    user
  } = mainLayoutCtx.state;
  const {
    toggleFaqModal,
    toggleAccountSettingsModal,
  } = mainLayoutCtx.stateFunctions;

  let isMediumScreen = !useMediaQuery("(min-width:960px)");


  async function handleSignout() {
    try {
      await signoutUserLocal();
      
      push("/login");


      
      mainLayoutCtx.stateFunctions.clearState()
      let sonityLocalContexs = new SonityLocalStore()
      sonityLocalContexs.clearContexts()
      //feathersClient.set("selenium_endpoint", null)
      
    } catch (e) {
      console.log(e);
    }
  }

  // React.useEffect(() => {
  //   if (selectedSonityProfile) {
  //     console.log("new profile selected, fetching fresh CDS");
  //     getCampaignDefinitionsList({
  //       variables: {
  //         sonity_profile_id: [selectedSonityProfile.id]
  //       }
  //     });
  //   } else {
  //     console.log("no driver selected clearing CDS");
  //   }
  // }, [selectedSonityProfile, getCampaignDefinitionsList]);

  

  return (
    <AppBar
      // position={isMediumScreen ? "fixed" : "relative"}
      className={classes.appBar}
    >
      <Toolbar
      // className={classes.mainToolbar}
      >
        <div className={classes.hiddenToolbar}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={openNavigation}
            size="large">
            <MenuIcon />
          </IconButton>
        </div>

        <div className={classes.mainToolbar}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <Button
                    disabled={
                      !selectedSonityProfile 
                      // || !selectedSonityProfile.contact 
                      // || !selectedSonityProfile.contact.l_id
                    }
                    color="inherit"
                    component={Link}
                    to="/home"
                  >
                    <Tooltip title="Home">
                      <HomeIcon />
                    </Tooltip>
                  </Button>
                </Grid>
                <Grid item>
                  <Button color="inherit" component={Link} to="/profiles">
                    <Tooltip title="Profiles">
                      <i className="material-icons">assignment_ind</i>
                    </Tooltip>
                  </Button>
                </Grid>
                <Grid item>
                  {sonityProfiles && sonityProfiles.length > 0 ? (
                    <Button color="inherit" component={Link} to="/reports">
                      <Tooltip title="Reports">
                        <ReportsIcon />
                      </Tooltip>
                    </Button>
                  ) : (
                    <Button
                      color="inherit"
                      disabled
                      component={Link}
                      to="/reports"
                    >
                      <Tooltip title="Reports">
                        <ReportsIcon />
                      </Tooltip>
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  <Button
                    disabled={!sonityProfiles || sonityProfiles.length == 0}
                    color="inherit"
                    component={Link}
                    to="/list-manager"
                  >
                    <Tooltip title="List Manager">
                      <ListAltIcon />
                    </Tooltip>
                  </Button>
                </Grid>
                <Grid item>
                  <ProfileTab
                    selectedSonityProfile={selectedSonityProfile}
                    user={user}
                    drivers={drivers}
                  />
                </Grid>
                <Grid item>
                  <div className={classes.divider} />
                </Grid>

                <Grid item>
                  
                  <Button
                    disabled={!selectedSonityProfile}
                    color="inherit"
                    component={Link}
                    to="/campaigns-chats"
                  >
                    Chats
                  </Button>
                </Grid>

                <Grid item>
                  <GenericCampaignDropdownMenu />
                </Grid>

 
              </Grid>
            </Grid>

            <Grid item>
              <Grid container alignItems="center">
                
                {/* <Grid item>
                  <IconButton color="inherit" aria-label="Account Settings" onClick={()=> setAccountSettingsOpened(true)}>
                  <Tooltip title="Account Settings">
                    <ManageAccountsIcon />
                  </Tooltip>
                  </IconButton>                  
                </Grid> */}
                
                <Grid item>
                  <AccountMenu handleSignout={handleSignout}/>
                  {/* <Button color="inherit" onClick={handleSignout}>
                     Signout
                  </Button> */}
                </Grid>

                

                <Grid item>
                  <Hidden smUp>
                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      onClick={handleSignout}
                      size="large">
                      <LogoutIcon />
                    </IconButton>
                  </Hidden>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        {/*<Button
          disabled={!selectedSonityProfile}
          component={Link}
          to="/drive-extraction"
          color="inherit"
        >
          <Tooltip title="Extracted linkedin contacts">
            <ExtractContactsIcon />
          </Tooltip>
        </Button>*/}
      </Toolbar>

      {/*DIALOGS */}

      <AccountSettingsDialog  
        open={accountSettingsModalOpen}
        handleClose={toggleAccountSettingsModal}
        handleClick={toggleAccountSettingsModal}
      />

    
    </AppBar>
  );
};
