import { CircularProgress, TextField } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Typography
} from "@mui/material";
import { ContactMail } from "@mui/icons-material";
import React from "react";
import { feathersClient } from "../../../attach-client";
import { useMainLayoutContext } from "../../../contexts/mainLayoutState/MainLayoutProvider";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  button: {
    marginRight: theme.spacing(1)
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  completed: {
    display: "inline-block"
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const PinVerificationModal = ({ user, payload, open, handleClose }) => {
  const classes = useStyles();
  const [pin, setPin] = React.useState();
  const [sending, setSending] = React.useState(false);

  const mainLayoutCtx = useMainLayoutContext();

  const handleChange = event => {
    console.log("Event: ", event);
    setPin(event.target.value);
  };

  const closeModal = () => {
    handleClose(false);
    setPin("");
  };

  const sendPinToDriver = async () => {
    console.log("the pin: ", pin);

    setSending(true);
    await fetch(
      `${mainLayoutCtx.state.selectedSonityAccount.selenium_endpoint}/enter-pin-code`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-sonity-account-id": mainLayoutCtx.state.selectedSonityAccount.id,
          "x-sonity-user-id": mainLayoutCtx.user.id,
          authorization:
            feathersClient.authentication.options.storage["feathers-jwt"]
        },
        body: JSON.stringify({
          pin_code: pin,
          sonity_profile_id: payload.sonity_profile_id
        })
      }
    );

    setSending(false);
  };

  const getFullName = () => {
    try {
      if (payload && payload.sonity_profile) {
        let sonityProfile = payload.sonity_profile;
        let fullName =
          sonityProfile.contact.first_name +
          " " +
          sonityProfile.contact.last_name;
        return fullName;
      } else {
        return "";
      }
    } catch (e) {
      return "";
    }
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={closeModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>Pin Verification {`for ${getFullName()}`}</DialogTitle>
        <DialogContent dividers>
          <div>
            <Typography>Enter pin you recieved</Typography>

            <TextField
              id="filled-name"
              label="Pin"
              placeholder="Pin Here"
              type="number"
              value={pin}
              onChange={handleChange}
              variant="filled"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between">
            <Grid item>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={sendPinToDriver}
                  className={classes.button}
                >
                  {sending ? "Sending..." : "Send"}
                </Button>
              </div>
            </Grid>
            <Grid item>
              <Button
                autoFocus
                onClick={closeModal}
                variant="contained"
                color="primary"
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PinVerificationModal;
