import { SonityLocalStore, contextsList } from "@/utils/sonityLocalStore";
import actionTypes from "./ActionTypes";


const sonityLocalStore = new SonityLocalStore();



export const reducer = (state, action) => {
  const { type, payload } = action;

  console.log("[List Manager  Reducer]: ", {
    state,
    action
  });


   let newState = state;

  switch (type) {
    case actionTypes.set_lists:
      for (let i = 0; i < payload.length; i++) {
        payload[i].contacts = payload[i].contacts?.filter(c => c.id != null);
      }

      newState = { ...state, lists: payload };
      break
    case actionTypes.add_list:
      newState = { ...state, lists: [...state.lists, payload] };
      break
    case actionTypes.set_current_list:
      newState = { ...state, currentList: payload };
      break
    case actionTypes.change_current_list_name:
      newState = {
        ...state,
        currentList: {
          ...state.currentList,
          name: payload
        }
      };
      break
    case actionTypes.toggle_edit_list:
      newState = { ...state, editList: !state.editList };
      break
    case actionTypes.toggle_new_list_modal:
      newState = { ...state, openNewListModal: !state.openNewListModal };
      break
    case actionTypes.toggle_move_to_list_modal:
      newState = { ...state, openMoveToListModal: !state.openMoveToListModal };
      break
    case actionTypes.toggle_list_detail_modal:
      newState = { ...state, openListDetailModal: !state.openListDetailModal };
      break
    case actionTypes.increment_new_list_step:
      newState = { ...state, newListStep: (state.newListStep += 1) };
      break
    case actionTypes.decrement_new_list_step:
      newState = { ...state, newListStep: (state.newListStep -= 1) };
      break
    case actionTypes.set_move_contacts:
      newState = { ...state, moveContacts: payload };
      break
    case actionTypes.reset_new_list_step:
      newState = { ...state, newListStep: 0 };
      break
    case actionTypes.set_import_method:
      newState = { ...state, importMethod: payload };
      break
    case actionTypes.set_sonity_profiles:
      newState = { ...state, sonityProfiles: payload };
      break
    case actionTypes.select_sonity_profiles:
      newState = { ...state, selectedSonityProfiles: payload };
      break
    case actionTypes.trigger_next_step:
      newState = { ...state, stepperNext: true };
      break
    case actionTypes.reset_next_step:
      newState = { ...state, stepperNext: false };
      break
    case actionTypes.set_state:
      newState = {
        ...state,
        ...payload
      };
      break
    default:
      newState = state;
      break 
  }



  sonityLocalStore.storeContextState(contextsList.listManager, newState);
  return newState;
};
