import gql from "graphql-tag";
export const GET_TRACKING_CAMPAIGN_PROSPECT = gql`
  query GetTrackingCampaignProspect($l_id: String!) {
    tracking_campaign_prospect(input: { l_id: $l_id }) {
      id
      l_id
      sales_nav_l_id
      prospect_id
      prospect {
        id
        first_name
        last_name
        profile_current_position
        profile_company
        profile_location
        profile_description
        profile_name
        age
        gender
        gender_prob
        org_size
      }
      contact_id
      contact {
        id
        l_id
        title
        first_name
        middle_name
        last_name
        email
        phone
        contact_birthday
        websites
      }
      model_results {
        id
        model_definition_id
        outcome
        created_at
        updated_at
      }
      thumb
      name
      description
      email
      phone
      websites
      company
      position
      location
      step
      status
      title
      thread_id
      connection_date
      campaign_status_id
      first_connection_id
      first_connection {
        id
        connection_date
        thread_id
        salesnav_thread_id
        initiator_l_id
        acceptor_l_id
      }
      is_open
      updated_at
      created_at
    }
  }
`;

export const GET_TRACKING_CAMPAIGN_PROSPECTS_2 = gql`
  query GetTrackingCampaignProspect($input: TrackingCampaignProspectsInput) {
    tracking_campaign_prospects(input: $input) {
      id
      l_id
      sales_nav_l_id
      prospect_id
      campaign_definition_id
      contact_id
      contact {
        id
        l_id
        title
        first_name
        middle_name
        last_name
        email
        phone
        contact_birthday
        websites
      }
      thumb
      campaign_status_id
      first_connection_id
      first_connection {
        id
        connection_date
        thread_id
        salesnav_thread_id
        initiator_l_id
        acceptor_l_id
      }
      is_open
      updated_at
      created_at
    }
  }
`;


export const GET_TRACKING_CAMPAIGN_PROSPECTS = gql`
  query GetTrackingCampaignProspectsPaginated(
    $sonity_profile_id: ID
    $campaign_definition_id: ID!
    $limit: Int
    $offset: Int
    $campaign_status_id: ID
    $campaign_status_ids: [ID]
    $text_searches: [String]
    $age_gender_filter: AgeGenderFilter
    $campaign_filter: String
  ) {
    tracking_campaign_prospects_table(
      input: {
        limit: $limit
        offset: $offset
        text_searches: $text_searches
        campaign_status_id: $campaign_status_id
        campaign_status_ids: $campaign_status_ids
        campaign_definition_id: $campaign_definition_id
        age_gender_filter: $age_gender_filter
        campaign_filter: $campaign_filter
        sonity_profile_id: $sonity_profile_id
      }
    ) {
      count
      rows {
        id
        l_id
        sales_nav_l_id
        prospect_id
        campaign_definitions {
          id
          name
          set_active
        }
        prospect {
          id
          first_name
          last_name
          profile_current_position
          profile_company
          profile_location
          profile_description
          profile_name
          age
          gender
          gender_prob
          org_size
        }
        contact_id
        contact {
          id
          l_id
          title
          first_name
          middle_name
          last_name
          email
          phone
          contact_birthday
          websites
        }
        model_results {
          id
          model_definition_id
          outcome
          created_at
          updated_at
        }
        thumb
        name
        description
        email
        phone
        websites
        company
        position
        location
        step
        status
        title
        thread_id
        connection_date
        campaign_status_id
        first_connection_id
        first_connection {
          id
          connection_date
          thread_id
          salesnav_thread_id
          initiator_l_id
          acceptor_l_id
        }
        is_open
        updated_at
        created_at
      }
    }
  }
`;

export const GET_TCPS_BY_ACCOUNT = gql`
  query GetTcpsByAccount(
    $sonity_account_id: ID!
    $campaign_definition_id: ID
    $campaign_status_ids: [ID]
    $limit: Int
    $text_searches: [String]
    $offset: Int
  ) {
    tracking_campaign_prospects_table(
      input: {
        limit: $limit
        offset: $offset
        sonity_account_id: $sonity_account_id
        campaign_definition_id: $campaign_definition_id
        campaign_status_ids: $campaign_status_ids
        text_searches: $text_searches
      }
    ) {
      count
      rows {
        id
        l_id
        sales_nav_l_id
        prospect_id
        prospect {
          id
          first_name
          last_name
          profile_current_position
          profile_location
          profile_description
          profile_name
          age
          gender
          gender_prob
          org_size
        }
        contact_id
        contact {
          id
          l_id
          title
          first_name
          middle_name
          last_name
          email
          websites
        }
        model_results {
          id
          model_definition_id
          outcome
          created_at
          updated_at
        }
        thumb
        name
        description
        email
        phone
        websites
        company
        position
        location
        step
        status
        title
        thread_id
        connection_date
        campaign_status_id
        first_connection_id
        is_open
        updated_at
        created_at
      }
    }
  }
`;
