import React, { createContext, useContext, useReducer } from "react";

import { useMainLayoutContext } from "../mainLayoutState/MainLayoutProvider";

import { useReports } from "../../hooks/useReports";

import initialState from "./ReportsInitialState";
import ReportsReducer from "./ReportsReducer.js";
import stateFunctions from "./ReportsStateFunctions";

import { feathersClient } from "../../attach-client";

// Context
const ReportsContext = createContext();


/**
 * @name ReportsContextProvider
 * @desc Provide reports state , hooks and state functions to all intrested components(the charts)
 * @prop {[Component]} children - A list of children components 
 * @returns Provider
 */
export function ReportsContextProvider({ children }) {
  const mainLayoutCtx = useMainLayoutContext();

  const {
    selectedSonityProfile,
    selectedCampaignDefinition,
    sonityProfiles
  } = mainLayoutCtx.state;

  console.log("Apparently this breaks: ", sonityProfiles);
  const campaignDefinitions = sonityProfiles
    .map(sp => {
      if(sp.campaign_definitions && typeof sp.campaign_definitions.length === 'number') return sp.campaign_definitions;
      return []
    }, [])
    .reduce((a, b) => [...a, ...b], []);

  console.log("[ReportsContext]CDS:", campaignDefinitions);
  const [state, dispatch] = useReducer(
    ReportsReducer,
    initialState(sonityProfiles, campaignDefinitions)
  );
  const stateFuncs = stateFunctions(state, dispatch);

  console.log("Aggregate CDS:", campaignDefinitions);

  // Create value and pass it to provider
  const value = {
    state,
    dispatch,
    cubejsApi: () => feathersClient.get("cubejsApi"),
    sonityProfiles,
    campaignDefinitions,
    stateFunctions: stateFuncs,
    useReportsHook: useReports()
  };

  return (
    <ReportsContext.Provider value={value}>{children}</ReportsContext.Provider>
  );
}

/**
 * @name useReportsContext
 * @desc Return Reports context value which is an object 
 * @returns A context
 */
export function useReportsContext() {
  return useContext(ReportsContext);
}
