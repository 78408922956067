import React from "react";
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import LoadingBars from "../general/LoadingBars";

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.background.paper
  },
  containerFilter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    height: "100%"
  },
  column: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "space-around",
    marginTop: "3rem"
  },
  notchedOutline: {
    borderRadius: "2rem"
  },
  hookContainer: {
    margin: "4rem 0 2rem 0"
  },
  comingSoonContainer: {
    margin: "0rem 0"
  },
  titleContainer: {
    margin: "4rem 0"
  },
  title: {
    fontFamily: "Comfortaa",
    letterSpacing: "0.25rem",
    fontWeight: 500,
    fontSize: "96px"
  },
  coming: {
    fontFamily: "Comfortaa",
    color: "white",
    fontWeight: 800,
    letterSpacing: "0.05rem",
    fontSize: 20
  },
  hook: {
    fontFamily: "Comfortaa",
    color: "white",
    fontWeight: 800,
    letterSpacing: 0
  },
  register: {
    display: "flex",
    flexDirection: "row",
    fontFamily: `'Open Sans', sans-serif`,
    margin: "1rem 0",
    color: "white",
    fontWeight: 500,
    letterSpacing: 0,
    cursor: "pointer"
  },
  linkButton: {
    margin: "0 0.5rem"
  }
});

const LoadingCredentials = props => {
  const { classes } = props;

  return (
    <div id="splash-root" className={classes.root}>
      <div id="container-filter" className={classes.containerFilter}>
        <div className={classes.column}>
          {/*<div className={classes.titleContainer}>
                <Typography variant="h2" color="primary" classes={{root:classes.title}}>stockwick</Typography>
              </div>*/}
          <div className={classes.hookContainer}>
            <LoadingBars />
          </div>
          <div className={classes.comingSoonContainer}>
            <Typography
              variant="button"
              classes={{ root: classes.coming }}
              color="primary"
            >
              Loading your credentials...
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(LoadingCredentials);
